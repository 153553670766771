import { useQuery } from 'react-query'
import { useToastContext } from '@foundation/components'
import { makeRequest } from 'utils'
import { BASE_URL } from '../../constants/endpoints'
import { GET_PODS_QUERY_KEY } from '../../constants/query-keys'
import { LSM, Pod } from '../../types'

export const usePods = () => {
  const toast = useToastContext()

  return useQuery<Pod>(
    [GET_PODS_QUERY_KEY],
    async () => {
      const { data } = await makeRequest(BASE_URL)

      const lsms = []
      const special_partners = []

      data.data.forEach((item: LSM) => {
        if (item.attributes.is_special_pod) {
          special_partners.push(item)
        } else {
          lsms.push(item)
        }
      })

      return {
        data: { lsms, special_partners },
        meta: data.meta
      }
    },
    {
      onError: error => {
        console.error(error)
        toast({
          title: 'Ops! Something went wrong.',
          status: 'error'
        })
      }
    }
  )
}
