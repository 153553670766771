import { getBulkEquityUnlockCalculationUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

type TPayload = {
  inputFileId: string
  name: string
  partnerId: Potential<string>
}

export function postBulkEquityUnlockCalculation(payload: TPayload) {
  return postRequest(getBulkEquityUnlockCalculationUrl(), snakeCaseKeys(payload))
}
