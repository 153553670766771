// @ts-strict
import { useMutation, useQueryClient } from 'hooks'
import { issueProviderAgreement, voidProviderAgreement } from 'services'

type VoidProviderAgreementPayload = {
  agreementId: string
}

type IssueProviderAgreementPayload = {
  agreementId: string
}

export const useProviderAgreementsController = (agentId: string) => {
  const queryCache = useQueryClient()

  const providerAgreementVoid = useMutation(
    (payload: VoidProviderAgreementPayload) => voidProviderAgreement(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  const providerAgreementIssue = useMutation(
    (payload: IssueProviderAgreementPayload) => issueProviderAgreement(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  return { providerAgreementVoid, providerAgreementIssue }
}
