// @ts-strict
import { BulkEquityUnlockCalculation } from 'models/BulkEquityUnlockCalculation'
import { getBulkEquityUnlockCalculationUrl } from 'services/getUrl'
import { camelCaseKeys, deserializeAsync, getRequest } from 'utils'

export interface IFetchBulkEquityUnlockCalculationsProps {
  pageNumber?: number
  pageSize?: number
}

interface IMeta {
  totalCount: number
  totalPages: number
}

export interface IFetchBulkEquityUnlockCalculationsResponse {
  bulkEquityUnlockCalculations: BulkEquityUnlockCalculation[]
  meta: IMeta
}

export const fetchBulkEquityUnlockCalculations = async ({
  pageNumber = 1,
  pageSize = 25
}: IFetchBulkEquityUnlockCalculationsProps): Promise<IFetchBulkEquityUnlockCalculationsResponse> => {
  const offset = (pageNumber - 1) * pageSize
  const limit = pageSize
  const searchParams = {
    offset,
    limit
  }
  const response = await getRequest(getBulkEquityUnlockCalculationUrl({ searchParams }))
  const bulkEquityUnlockCalculations = await deserializeAsync(response.data, {
    transform: BulkEquityUnlockCalculation.create
  })
  const meta = camelCaseKeys<IMeta>(response.data.meta)

  return {
    bulkEquityUnlockCalculations,
    meta
  }
}
