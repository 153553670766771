// @ts-strict
import { Model } from 'models/Model'
import { BBYSLoanResponse } from 'schema'

export class BBYSLoan extends Model {
  createdAt?: string
  documentaryAndIntangibleTaxes?: string
  id?: string
  loanFundingAmount?: string
  loanMaturityDate?: string
  loanNumber?: string
  loanType?: string
  notaryFee?: string
  recordingFee?: string
  repaperFundedAt?: string
  repaperSigningUrl?: string
  repaperNoteAt?: string
  repaperStartedAt?: string
  warehouseFacility?: string

  constructor(attributes?: Omit<BBYSLoan, keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(bbysLoanResponse: Potential<BBYSLoanResponse>): Potential<BBYSLoan> {
    if (!bbysLoanResponse) {
      return
    }
    return new BBYSLoan(bbysLoanResponse)
  }
}
