// @ts-strict
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { TColumn } from '@foundation/components'
import { BulkEquityUnlockCalculation } from 'models/BulkEquityUnlockCalculation'
import { formatDateTime } from 'utils'

const BlockLink = styled(Link)`
  display: block;
`

type BulkEquityUnlockCalculationExtended = BulkEquityUnlockCalculation & {
  partnerSlug?: string
}

export const EquityUnlockCalculatorDataToolColumns: TColumn<BulkEquityUnlockCalculationExtended>[] =
  [
    {
      label: 'File name',
      display: true,
      frozen: true,
      minSize: '200px',
      flexGrow: true,
      render: row => row.originalInputFileName
    },
    {
      label: 'User Email',
      display: true,
      minSize: '200px',
      render: row => row.userEmail
    },
    {
      label: 'Partner Slug',
      display: true,
      minSize: '200px',
      render: row => row.partnerSlug
    },
    {
      label: 'Date received',
      display: true,
      render: row => formatDateTime(row.createdAt)
    },
    {
      label: 'Current Status',
      display: true,
      render: row => row.result
    },
    {
      label: 'Download files',
      display: true,
      minSize: '200px',
      render: row => (
        <div>
          {row.inputFileDownloadUrl && (
            <BlockLink to={row.inputFileDownloadUrl} target="_blank">
              Download Input File
            </BlockLink>
          )}
          {row.resultFileDownloadUrl && (
            <BlockLink to={row.resultFileDownloadUrl} target="_blank">
              Download Result File
            </BlockLink>
          )}
        </div>
      )
    }
  ]
