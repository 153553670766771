// @ts-strict

import { useState } from 'react'
import { Button } from '@foundation/components'
import { Block, ConfirmationPopup, Icon, Section, SimplePagination } from 'components'
import { AttachLeadPopup } from 'content/Order'
import { useLeadController, useOrderController } from 'controllers'
import { useOrderLeadsFeedController } from 'controllers/Leads/useOrderLeadsFeedController'
import { useFlexUi } from 'hooks'
import { Lead, Order } from 'models'
import { formatOrDash, titleize } from 'utils'
import styles from './OrderLeadNavigation.module.scss'
import { OrderLeadNavigationItem, OrderLeadNavigationLink, OrderLeadNavigationProperty } from './'

type TProps = {
  orderId: string
  selectedLead: Lead
}

export const OrderLeadNavigation = ({ orderId, selectedLead }: TProps) => {
  const [showAttachLead, setShowAttachLead] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const { detachLeadFromOrder } = useLeadController('', 'seller', false)
  const { similarLeadsQuery } = useOrderController(orderId)
  const { isFlexUi } = useFlexUi()
  const {
    orders,
    totalPages,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage
  } = useOrderLeadsFeedController({ searchParams: { orderId } })

  if (!orders || !orders.length) {
    return null
  }

  const order = orders[currentPage] as Order
  const totalCount = similarLeadsQuery?.data?.total ?? 0
  const similarLeadsCounter = totalCount > 5 ? '5+' : totalCount
  const leadsLength = order?.leads ? order.leads.length : 0
  const disabledPrev = currentPage <= 0 || isFetchingNextPage || isFetchingPreviousPage
  const disabledNext = !hasNextPage || isFetchingNextPage || isFetchingPreviousPage

  const toggleAttachLeadPopup = () => setShowAttachLead(state => !state)

  const attachLeadsText: string = `Attach Leads ${
    !!similarLeadsCounter ? `(${similarLeadsCounter})` : `(${similarLeadsCounter})`
  }`

  const actions = (
    <Button variant="ghost" color="primary" size="xs" onClick={toggleAttachLeadPopup}>
      {attachLeadsText}
    </Button>
  )

  const onNext = async () => {
    // If the page already exists, use it. Avoid eager loading next pages
    if (currentPage + 1 >= orders.length) {
      await fetchNextPage()
    }

    setCurrentPage(currentPage + 1)
  }

  const onPrevious = async () => {
    await fetchPreviousPage()
    setCurrentPage(currentPage - 1)
  }

  return (
    <>
      <Section title="Order" actions={actions}>
        {!isFlexUi && (
          <OrderLeadNavigationLink to={order.pathname}>
            <Block
              data-testid={`leadNavigationItem${orderId}`}
              title={order.title}
              subtitle={!isFlexUi && formatOrDash(order.stage, titleize)}
              className={styles.orders}
            />
          </OrderLeadNavigationLink>
        )}
        <div className={styles.order}>
          {order.properties?.map(property => (
            <OrderLeadNavigationProperty key={property.name} property={property}>
              <div className={styles.ordersWrapper}>
                {property?.leads?.map((leadItem: Lead) => (
                  <div key={leadItem.id} className={styles.relative}>
                    <OrderLeadNavigationItem lead={leadItem} />
                    <ConfirmationPopup
                      buttonText={<Icon.Close />}
                      title="Detach lead"
                      message="Are you sure you want to detach the lead from this order?"
                      buttonComponent={props => (
                        <button {...props} className={styles.detachButton} />
                      )}
                      disabled={leadsLength <= 1}
                      mutation={detachLeadFromOrder}
                      initialPayload={{ orderId: orderId, leadId: leadItem.id }}
                    />
                  </div>
                ))}
              </div>
            </OrderLeadNavigationProperty>
          ))}
        </div>
      </Section>

      {hasNextPage && (
        <SimplePagination
          disabledNext={disabledNext}
          disabledPrev={disabledPrev}
          onNext={onNext}
          onPrevious={onPrevious}
          totalPages={totalPages}
        />
      )}

      {showAttachLead && (
        <AttachLeadPopup
          onCancel={toggleAttachLeadPopup}
          order={order}
          selectedLead={selectedLead}
        />
      )}
    </>
  )
}
