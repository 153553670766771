// @ts-strict

import { useState } from 'react'
import { ButtonForm, useButtonForm } from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useTaskController } from 'controllers'
import { Task } from 'models'
import { TaskFormFields } from '../TaskFormFields'
import { DocumentsListProvider, useDocumentsList } from '../contexts/documentsList'
import { formValidations, RawDocument, transformPayload, TTaskFormPayload } from '../utils'
import { DRContractUpload } from './form-fields'

type TProps = { onSuccessCallback?: Function; task: Task }

export const TaskEditButtonForm = ({ task }: TProps) => {
  const { templateSlug } = task

  const isApplyForEquityBoostTask = templateSlug === 'apply_for_equity_boost'
  const isUploadEquityBoostDocumentsTask = templateSlug === 'upload_equity_boost_documents'
  const isDocumentUploadTask = templateSlug === 'bbys_document_upload'
  const isSendWireConfirmation = templateSlug === 'bbys_send_wire_confirmation'
  const isIrContractUploadTask = templateSlug === 'bbys_upload_ir_contract'
  const isIrContractReviewTask = templateSlug === 'bbys_review_ir_contract'
  const isDrContractUploadTask = templateSlug === 'bbys_upload_dr_contract'
  const isDrContractReviewTask = templateSlug === 'bbys_review_dr_contract'

  // Set send notification ON by default for wire confirmation tasks
  // https://docs.google.com/document/d/1QNOVtsKB1zr1kIktWsTKjGAUG8QLr765VJROx9SwoP8/edit
  const [sendNotification, setSendNotification] = useState(
    isSendWireConfirmation || !!task?.metadata.sendNotification
  )

  const { updateTask } = useTaskController(task.id, task)
  const documentsList = useDocumentsList({ initialDocuments: task.metadata?.documents })

  const initialPayload: TTaskFormPayload = {
    assigneeId: task.assignee?.id,
    assigneeName: task.assignee?.name,
    dueAt: task.dueAt,
    note: task.note,
    taskCategoryId: task.taskCategory?.id,
    title: task.title,
    requiredForLeadStage: task?.requiredForLeadStage,
    subAttachableId: task?.subAttachable?.id,
    metadata: task?.metadata
  }

  const { fieldsProps, formProps } = useButtonForm({
    initialPayload,
    formValidations,
    mutation: updateTask,
    transformPayload: payload =>
      transformPayload({
        attachable: task.attachable,
        payload,
        documents: documentsList.documents.map(doc => {
          const rawDoc = {
            title: doc.title,
            enabled: doc.enabled
          } as RawDocument

          if (doc.notes !== '') {
            rawDoc.notes = doc.notes
          }

          return rawDoc
        }),
        sendNotification
      })
  })

  const {
    payload: { metadata }
  } = fieldsProps

  const isWireConfirmationV2FormValid =
    metadata?.imadNumber &&
    metadata?.wireAmount &&
    metadata?.borrower?.firstName &&
    metadata?.borrower?.lastName &&
    metadata?.warehouseFacility

  const isFormValid = isSendWireConfirmation
    ? !!isWireConfirmationV2FormValid
    : formProps.isFormValid

  const render = () => {
    if (isDrContractUploadTask) {
      return <DRContractUpload {...fieldsProps} attachable={task.attachable} />
    }

    return (
      <TaskFormFields
        {...fieldsProps}
        attachable={task.attachable}
        isApplyForEquityBoostTask={isApplyForEquityBoostTask}
        isUploadEquityBoostDocumentsTask={isUploadEquityBoostDocumentsTask}
        isDocumentUploadTask={isDocumentUploadTask}
        isSendWireConfirmation={isSendWireConfirmation}
        isIrContractUploadTask={isIrContractUploadTask}
        isIrContractReviewTask={isIrContractReviewTask}
        isDrContractReviewTask={isDrContractReviewTask}
        sendNotification={sendNotification}
        setSendNotification={setSendNotification}
      />
    )
  }

  return (
    <DocumentsListProvider {...documentsList}>
      <ButtonForm
        {...formProps}
        title="Edit Task"
        buttonComponent={EditIconButton}
        isFormValid={isFormValid}
      >
        {render()}
      </ButtonForm>
    </DocumentsListProvider>
  )
}
