// @ts-strict
import { FormField, If, InlineEditSelect, InlineEditTextInput, Section, Sections } from 'components'
import {
  agentMatchingCallReviewBathroomsCount,
  agentMatchingCallReviewBedroomsCount,
  agentMatchingCallReviewCondition,
  agentMatchingCallReviewGarageSize,
  agentMatchingCallReviewHowLongOwned,
  agentMatchingCallReviewInvestorIntroCount,
  agentMatchingCallReviewInvestorIntroCountDual,
  agentMatchingCallReviewLotSize,
  agentMatchingCallReviewOccupiedBy,
  agentMatchingCallReviewOwnershipStatus,
  agentMatchingCallReviewYearBuilt,
  yesOrNoOptions
} from 'lookups'
import { BuyerLead, isBuyerLead, SellerLead } from 'models'
import { dataOrDash } from 'utils'
import * as S from '../styles'
import { HomeFeaturesButtonForm } from './HomeFeaturesButtonForm'
import { HOME_FEATURES_KEYS } from './types'

type Props = {
  introType?: string
  investorIntroFieldsActive?: boolean
  isDispositionForm?: boolean
  lead: BuyerLead | SellerLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string) => void
}

export const AgentMatchingLeadInvestorScreenSection = ({
  introType,
  isDispositionForm = false,
  investorIntroFieldsActive = false,
  lead,
  requiredFields = {},
  updateCallReview
}: Props) => {
  if (isBuyerLead(lead)) {
    return null
  }

  const homeFeatures = HOME_FEATURES_KEYS.filter(feature => {
    return lead?.callReview?.[feature] && feature
  })

  return (
    <Sections title="Investor Screen">
      <Section>
        <If
          test={
            !isDispositionForm ||
            (investorIntroFieldsActive && (introType === 'Investor Intro' || introType === 'Both'))
          }
        >
          <If test={investorIntroFieldsActive && introType === 'Both'}>
            <FormField title="Investor Intro Quantity" inline required={requiredFields.yearBuilt}>
              <InlineEditSelect
                addBlank
                onChange={(value: string) => updateCallReview('investorIntroQuantity', value)}
                options={agentMatchingCallReviewInvestorIntroCountDual}
                value={dataOrDash(lead?.callReview?.investorIntroQuantity)}
                isError={
                  lead.sourcePageType === 'offers' && !lead?.callReview?.investorIntroQuantity
                }
              />
            </FormField>
          </If>
          <If test={investorIntroFieldsActive && introType !== 'Both'}>
            <FormField title="Investor Intro Quantity" inline required={requiredFields.yearBuilt}>
              <InlineEditSelect
                addBlank
                onChange={(value: string) => updateCallReview('investorIntroQuantity', value)}
                options={agentMatchingCallReviewInvestorIntroCount}
                value={dataOrDash(lead?.callReview?.investorIntroQuantity)}
                isError={
                  lead.sourcePageType === 'offers' && !lead?.callReview?.investorIntroQuantity
                }
              />
            </FormField>
          </If>
          <FormField title="When Was Home Built?" inline required={requiredFields.yearBuilt}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('yearBuilt', value)}
              options={agentMatchingCallReviewYearBuilt}
              value={dataOrDash(lead?.callReview?.yearBuilt)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.yearBuilt}
            />
          </FormField>
          <FormField title="Years Home Owned?" inline required={requiredFields.howLongOwned}>
            <InlineEditSelect
              addBlank
              onChange={(value: string) => updateCallReview('howLongOwned', value)}
              options={agentMatchingCallReviewHowLongOwned}
              value={lead?.callReview?.howLongOwned}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.howLongOwned}
            />
          </FormField>
          <FormField title="Occupied at Closing?" inline required={requiredFields.occupiedBy}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('occupiedBy', value)}
              options={agentMatchingCallReviewOccupiedBy}
              value={dataOrDash(lead?.callReview?.occupiedBy)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.occupiedBy}
            />
          </FormField>
          <FormField title="Lot Size" inline required={requiredFields.lotSize}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('lotSize', value)}
              options={agentMatchingCallReviewLotSize}
              value={dataOrDash(lead?.callReview?.lotSize)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.lotSize}
            />
          </FormField>
          <FormField title="Garage Size" inline required={requiredFields.garageSize}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('garageSize', value)}
              options={agentMatchingCallReviewGarageSize}
              value={dataOrDash(lead?.callReview?.garageSize)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.garageSize}
            />
          </FormField>
          <FormField
            title="Do Any Situations Apply?"
            inline
            required={requiredFields.ownershipStatus}
          >
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('ownershipStatus', value)}
              options={agentMatchingCallReviewOwnershipStatus}
              value={dataOrDash(lead?.callReview?.ownershipStatus)}
            />
          </FormField>
          <FormField title="Condition of Home" inline required={requiredFields.condition}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('condition', value)}
              options={agentMatchingCallReviewCondition}
              value={dataOrDash(lead?.callReview?.condition)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.condition}
            />
          </FormField>
          <FormField title="Finished Sq Ft" inline required={requiredFields.squareFeet}>
            <InlineEditTextInput
              onBlur={(val: string) => updateCallReview('squareFeet', val)}
              value={dataOrDash(lead?.callReview?.squareFeet)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.squareFeet}
            />
          </FormField>
          <FormField title="# Bedrooms" inline required={requiredFields.beddroomsCount}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('bedroomsCount', value)}
              options={agentMatchingCallReviewBedroomsCount}
              value={dataOrDash(lead?.callReview?.bedroomsCount)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.bedroomsCount}
            />
          </FormField>
          <FormField title="# Bathrooms" inline required={requiredFields.bathroomsCount}>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('bathroomsCount', value)}
              options={agentMatchingCallReviewBathroomsCount}
              value={dataOrDash(lead?.callReview?.bathroomsCount)}
              isError={lead.sourcePageType === 'offers' && !lead?.callReview?.bathroomsCount}
            />
          </FormField>
          <S.FormField title="Home Features" inline required={requiredFields.homeFeatures}>
            <HomeFeaturesButtonForm features={homeFeatures} />
          </S.FormField>
        </If>
        <If
          test={
            (isDispositionForm && !investorIntroFieldsActive) ||
            (investorIntroFieldsActive && (introType === 'Agent Intro' || introType === ''))
          }
        >
          <FormField
            title="Did you ask the investor qualifying questions?"
            required={requiredFields.askedInvestorQualifyingQuestions}
            inline
          >
            <InlineEditSelect
              onChange={(value: string) =>
                updateCallReview('askedInvestorQualifyingQuestions', value)
              }
              options={yesOrNoOptions}
              value={dataOrDash(lead?.callReview?.askedInvestorQualifyingQuestions)}
            />
          </FormField>
          <FormField
            title="Did you pitch an investor to the client?"
            required={requiredFields.pitchedInvestorToClient}
            inline
          >
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('pitchedInvestorToClient', value)}
              options={yesOrNoOptions}
              value={dataOrDash(lead?.callReview?.pitchedInvestorToClient)}
            />
          </FormField>
        </If>
      </Section>
    </Sections>
  )
}
