// @ts-strict
import Papa from 'papaparse'
import * as XLSX from 'xlsx'
import { Button, useToastContext } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { TEquityUnlockCalculatorFileUploadFormFieldsPayload } from 'content/EquityUnlockCalculator'
import { useCurrentUserController, useFileUploadsController, useQueryClient } from 'hooks'
import { BULK_EQUITY_UNLOCK_CALCULATIONS_CACHE_KEY } from 'lookups/lookupBulkEquityUnlockCalculations'
import { usePartners } from 'lookups/lookupPartners'
import { FileUpload } from 'models'
import { TFormValidation, TJsonApiResult, validatePresence } from 'utils'
import { EquityUnlockCalculatorFileUploadFormFields } from '../EquityUnlockCalculatorFileUploadFormFields'
import { SampleFileFormatDownload } from './SampleFileFormatDownload'
import { postBulkEquityUnlockCalculation } from './postBulkEquityUnlockCalculation'

type CreatedFile = FileUpload & { id: string }

const requiredSingleFieldAddressKey = 'exit_home_address'
const requiredAddressKeys = ['address', 'city', 'state', 'zip']

function standardizeKey(key: string) {
  return key.trim().toLowerCase().replaceAll(/\s+/gi, '_')
}

function validateRequiredKeys(fileKeys: string[]) {
  const errors: string[] = []
  if (!fileKeys.includes(requiredSingleFieldAddressKey)) {
    const addressIsPresent = requiredAddressKeys.every(key => fileKeys.includes(key))
    if (!addressIsPresent) {
      const requiredKeys = requiredAddressKeys.join(', ')
      errors.push(
        `The file is missing the required address fields: ${requiredKeys}. Alternatively, provide a single "${requiredSingleFieldAddressKey}" column.`
      )
    }
  }
  return errors
}

async function validateCsvFile(file: File) {
  const fileContents = await file.text()
  const parseResult = Papa.parse(fileContents.trim(), { header: true })
  const { data: parsedData, errors: parseErrors } = parseResult
  // Display up to 3 parsing errors
  const errors: string[] = parseErrors
    .slice(0, 3)
    .map(({ row, message }) => `[Row ${row}]: ${message}`)

  const [firstRow] = parsedData
  if (!firstRow) {
    errors.push('The file is empty, at least one row is required.')
    return errors
  }

  const fileKeys = Object.keys(firstRow).map(standardizeKey)
  errors.push(...validateRequiredKeys(fileKeys))

  return errors
}

async function validateXlsFile(file: File) {
  const errors: string[] = []
  try {
    const fileContents = await file.arrayBuffer()
    const workbook = XLSX.read(fileContents)
    const sheetName = workbook.SheetNames[0]
    const sheet = workbook.Sheets[sheetName]
    const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
    // One row for the headers, and at least one row with data must be present
    if (sheetData.length < 2) {
      errors.push('The file is empty, at least one row with data is required.')
      return errors
    }
    const headers = sheetData[0] as string[]
    const fileKeys = headers.map(standardizeKey)
    errors.push(...validateRequiredKeys(fileKeys))

    return errors
  } catch (error) {
    errors.push('The file is not a valid XLS file.')
    return errors
  }
}

async function validateFile(file: File) {
  const fileExtension = file.name.split('.').pop()
  switch (fileExtension) {
    case 'csv':
      return validateCsvFile(file)
    case 'xls':
    case 'xlsx':
      return validateXlsFile(file)
    default:
      return ['The file is not a CSV or Excel file.']
  }
}

export const EquityUnlockCalculatorFileUploadButtonForm = () => {
  const toast = useToastContext()
  const { currentUser } = useCurrentUserController()
  const { partners } = usePartners()
  const { uploadFile } = useFileUploadsController(
    { leadId: '' },
    {
      fileMetadata: {
        documentType: 'bulk_equity_unlock_calculation',
        fileType: 'csv',
        source: 'sales',
        uploaded_by: currentUser?.id
      }
    }
  )
  const queryClient = useQueryClient()

  const initialPayload: TEquityUnlockCalculatorFileUploadFormFieldsPayload = {
    fileType: 'csv',
    files: [],
    name: '',
    partnerId: '',
    category: 'bulk_equity_unlock_calculation',
    sharedExternally: false
  }

  const formValidations: TFormValidation = {
    files: {
      label: 'Document',
      validations: [validatePresence]
    },
    partnerId: {
      label: 'Partner Slug',
      validations: [validatePresence]
    }
  }

  const { formProps, fieldsProps } = useButtonForm<
    TEquityUnlockCalculatorFileUploadFormFieldsPayload,
    TJsonApiResult<CreatedFile[]>
  >({
    initialPayload,
    formValidations,
    mutation: uploadFile,
    callback: async (result: TJsonApiResult<CreatedFile[]>) => {
      const {
        data: { data }
      } = result
      const [file] = data
      const fileName = fieldsProps.payload.files[0].name
      const response = await postBulkEquityUnlockCalculation({
        inputFileId: file.id,
        name: fileName,
        partnerId: fieldsProps.payload.partnerId
      })
      if (response.status === 200) {
        toast({
          title:
            'File successfully uploaded. The results will be available in this page shortly, and a link to download the result file will also be emailed to you.',
          status: 'success',
          duration: 15000
        })
        queryClient.invalidateQueries([BULK_EQUITY_UNLOCK_CALCULATIONS_CACHE_KEY])
      } else {
        toast({
          title: 'File upload failed.',
          status: 'error'
        })
      }
    }
  })

  const title = 'Process New File'

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        {title}
      </Button>
      <ButtonForm
        buttonText={title}
        title={title}
        hideTriggerButton
        {...formProps}
        onSubmit={async () => {
          const [file] = fieldsProps.payload.files
          // File presence is validated elsewhere, so this should never happen, however,
          // this check tells TypeScript that file is not undefined
          if (!file) {
            return
          }
          const errors: string[] = await validateFile(file)
          if (errors.length > 0) {
            toast({
              title: 'File validation failed.',
              description:
                'Download the example file to see the required format.\n' + errors.join('\n'),
              status: 'error',
              duration: 15000
            })
            return
          }
          formProps.onSubmit()
        }}
      >
        <EquityUnlockCalculatorFileUploadFormFields {...fieldsProps} partners={partners} />
        <SampleFileFormatDownload />
      </ButtonForm>
    </>
  )
}
