// @ts-strict
import { Dialer } from 'classes'
import { CurrentUser, PhoneNumber, TransactionTeam, User } from 'models'
import { Partner } from 'models/Partner'
import { LoanOfficerResponse } from 'schema'
import { formatPhone } from 'utils'
import { Model } from '../Model'

type LoanOfficerAttributes = Omit<LoanOfficer, 'getPhone' | 'getDialer'>

export class LoanOfficer extends Model {
  id?: string
  name?: string
  email?: string
  companyName?: string
  nmlsId?: string
  phoneNumbers?: PhoneNumber[]
  user?: User
  transactionTeam?: TransactionTeam
  partnerSlug?: string
  partner?: Partner
  phoneNumber?: PhoneNumber
  lender?: {
    id: string
    name: string
  }

  constructor(attributes: LoanOfficerAttributes) {
    super()

    Object.assign(this, attributes)
  }

  static create(loanOfficerResponse: Potential<LoanOfficerResponse>): Potential<LoanOfficer> {
    if (!loanOfficerResponse) {
      return
    }

    return new LoanOfficer(loanOfficerResponse)
  }

  getPhone = () => {
    if (!this.phoneNumber) {
      return
    }
    return formatPhone(this.phoneNumber.number)
  }

  getDialer = (currentUser: CurrentUser, phoneNumber: string, phoneType: PhoneType) =>
    new Dialer({
      currentUser,
      name: this.name!,
      phoneNumber,
      phoneType,
      type: 'Loan Officer',
      id: this.id!
    })
}
