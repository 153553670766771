import { environment } from 'environment'
import { CurrentUser } from 'models'

interface DialerConnectionData {
  [key: string]: string
  caller_id_number: string
  resource_id: string
  resource_preferred_line: string
  resource_type: string
}

type DialerProps = {
  currentUser: CurrentUser
  id: string
  name: string
  phoneNumber: string
  phoneType: PhoneType
  showDebug?: boolean
  type: string
}

export class Dialer {
  currentUser: CurrentUser
  name: string
  phoneNumber: string
  phoneType: PhoneType
  type: string
  id: string
  showDebug?: boolean

  constructor({
    currentUser,
    id,
    name,
    phoneNumber,
    phoneType,
    showDebug = false,
    type
  }: DialerProps) {
    this.currentUser = currentUser
    this.name = name
    this.phoneNumber = phoneNumber
    this.phoneType = phoneType
    this.type = type
    this.id = id
    this.showDebug = showDebug
  }

  get connectionData(): { params: DialerConnectionData } {
    return {
      params: {
        resource_type: this.type,
        resource_id: this.id,
        resource_preferred_line: this.phoneType,
        caller_id_number: this.currentUser.callerId
          ? this.currentUser.callerId
          : this.getDefaultCallerId()
      }
    }
  }

  getDefaultCallerId = () => (environment.env === 'staging' ? '+15128723096' : '+14157992552')
}
