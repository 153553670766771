// @ts-strict

import {
  DateInput,
  FileInput,
  FormField,
  Section,
  Sections,
  Select,
  TextArea,
  TextInput
} from 'components'
import { TaskAttachable } from 'controllers'
import { useTaskCategories } from 'lookups'
import { isCCCashOfferLead, isCCTradeInLead } from 'models'
import { d, presence } from 'utils'
import { UsersAutocomplete } from '../../../../../modules/transaction-operators/components/users-autocomplete/users-autocomplete'
import styles from '../../TaskForm.module.scss'
import { TaskRequiredForStage } from '../../TaskFormFields/TaskRequiredForStage'
import { TTaskFormPayload } from '../../utils'

export type DRContractUploadProps = {
  attachable?: TaskAttachable
  onChangeAttribute: <K extends keyof TTaskFormPayload>(key: K, value: TTaskFormPayload[K]) => void
  payload: TTaskFormPayload
}

export const DRContractUpload = ({
  onChangeAttribute,
  payload,
  attachable
}: DRContractUploadProps) => {
  const { taskCategoryOptions } = useTaskCategories()

  const requiredForStage = (isCCCashOfferLead(attachable) || isCCTradeInLead(attachable)) && (
    <Section>
      <TaskRequiredForStage
        attachable={attachable}
        onChangeAttribute={onChangeAttribute}
        payload={payload}
      />
    </Section>
  )

  const clearUserId = () => {
    onChangeAttribute('assigneeId', '')
    onChangeAttribute('assigneeName', '')
  }

  const setUser = (user: any) => {
    if (user) {
      onChangeAttribute('assigneeId', user.id)
      onChangeAttribute('assigneeName', user.title)
    } else {
      clearUserId()
    }
  }

  const attachedTo = attachable && (
    <FormField title="Attached to">
      {attachable.name} ({d(attachable?.modelName)})
    </FormField>
  )

  return (
    <>
      <Sections>
        <Section>
          {attachedTo}
          <FormField title="Title" htmlFor="title-field" required>
            <TextInput
              id="title-field"
              data-testid="title-field"
              onChange={val => onChangeAttribute('title', val)}
              name="title"
              value={payload?.title}
              autoFocus
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Category" htmlFor="category">
            <Select
              id="category"
              data-testid="category-field"
              onChange={val => onChangeAttribute('taskCategoryId', val)}
              value={payload?.taskCategoryId}
              options={taskCategoryOptions}
              placeholder="Select a Category"
              addBlank
            />
          </FormField>
        </Section>
        {requiredForStage}
      </Sections>
      <Sections>
        <Section>
          <FormField title="Assignee" htmlFor="assignee">
            <UsersAutocomplete
              value={payload.assigneeName}
              onSelect={setUser}
              onClear={clearUserId}
              data-testid="assignee-field"
              id="assignee"
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Date Due" htmlFor="date-due">
            <DateInput
              id="date-due"
              value={presence(payload?.dueAt)}
              onChange={val => onChangeAttribute('dueAt', val)}
              includeTime
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Departing Residence Contract" required>
            <FileInput
              id="file-name"
              data-testid="fileUploadInput"
              accept=".pdf"
              value={payload.files}
              onChange={value => {
                onChangeAttribute('files', value)
              }}
              multiple={true}
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <FormField title="Note" htmlFor="note">
          <TextArea
            id="note"
            value={payload?.note}
            name="note"
            data-testid="note-field"
            onChange={val => onChangeAttribute('note', val)}
            className={styles.note}
          />
        </FormField>
      </Sections>
    </>
  )
}
