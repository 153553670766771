import { SearchParams } from '@types'
import { attachableType, TaskAttachable } from 'controllers'
import { TTaskStatusValue } from 'lookups'
import { getHapiRequestParams, TasksSchema } from 'schema'
import { presence, removeAttributesWithNullValues } from 'utils'

const sortParam = {
  Title: 'title',
  Status: 'status',
  CategoryStage: 'category_stage',
  PartnerSource: 'source_partner',
  Assignee: 'assignee',
  Assigner: 'assigner',
  Category: 'category',
  Due: 'due_at',
  Ready: 'ready_at',
  Created: 'created_at',
  Assigned: 'assigned_at',
  Priority: 'task_templates.priority',
  irCoeDate: 'bbys_leads.incoming_residenced_expected_close_escrow_date'
}

export type CompletionStatus = TTaskStatusValue[]

export type SearchParamsSettings = Partial<{
  active: TrueFalse
  assigned: string
  assigneeId: string
  assignerId: string
  attachable: TaskAttachable
  attachableIsActive: string
  attachableStatus: string
  categories: string[]
  completionStatus: CompletionStatus
  creationType: string
  incomingResidence: string
  pastDue: TrueFalse
  requiredForLeadStage: string
  templateSlug: string
  unassigned: TrueFalse
  userType: string[]
}> &
  SearchParams

export const getSearchParams = (
  {
    page,
    perPage,
    active,
    pastDue,
    unassigned,
    userType,
    searchTerm,
    assigneeId,
    assignerId,
    attachable,
    categories,
    sortBy,
    sortDir,
    assigned,
    completionStatus,
    creationType,
    attachableStatus,
    requiredForLeadStage,
    templateSlug,
    incomingResidence
  }: SearchParamsSettings,
  allLeadTasksEnabled?: boolean | undefined
) => {
  const getAssignee = () => {
    if (unassigned === 'true') {
      return 'null'
    }

    if (assigneeId) {
      return assigneeId
    }

    return undefined
  }

  const getSort = () => {
    if (sortBy) {
      const dir = sortDir === 'desc' ? '-' : ''
      return `${dir}${sortParam[sortBy]},${sortParam['Due']}`
    }

    return sortParam['Due']
  }

  const getIncomingResidence = () => {
    if (incomingResidence) {
      return {
        'filter[sub_attachable_id]': incomingResidence,
        'filter[sub_attachable_type]': 'CashOfferLead'
      }
    }

    return {}
  }

  const taskResponseShape: TasksSchema = {
    tasks: [
      'title',
      'note',
      'dueAt',
      'completedAt',
      'completionNote',
      'createdAt',
      'templateSlug',
      { 'assignee:user': ['name'] },
      { 'assigner:user': ['name'] },
      { 'attachable:agent': ['firstName', 'lastName'] },
      { 'attachable:lead': ['userType', 'stage', 'name'] }
    ]
  }

  return removeAttributesWithNullValues({
    ...getHapiRequestParams(taskResponseShape),
    sort: getSort(),
    'page[number]': presence(page),
    'page[size]': presence(perPage),
    'filter[type]': 'AgentTask,EmployeeTask,ClientTask',
    ...(!allLeadTasksEnabled && {
      'filter[attachable_is_active]': attachable ? undefined : true
    }),
    'filter[_assigned]': presence(assigned),
    'filter[_completion_status]': presence(completionStatus),
    'filter[_past_due]': pastDue === 'true' ? pastDue : undefined,
    'filter[_search]': presence(searchTerm),
    'filter[_scope]': active === 'true' ? 'active' : undefined,
    'filter[assignee_id]': getAssignee(),
    'filter[assigner_id]': presence(assignerId),
    'filter[attachable_id]': attachable?.id,
    'filter[attachable_type]': attachable ? attachableType(attachable) : undefined,
    'filter[task_category_id]': presence(categories),
    'filter[template_slug]': presence(templateSlug),
    'filter[required_for_lead_stage]': presence(requiredForLeadStage),
    'filter[leads.user_type]': presence(userType),
    'filter[creation_type]': presence(creationType),
    'filter[attachable_status]': presence(attachableStatus),

    ...getIncomingResidence()
  })
}
