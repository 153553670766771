import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex } from '@foundation/components'
import {
  Pagination,
  SearchConsole,
  SearchInputParams,
  SearchStatusPrompt,
  Table,
  TableResults,
  TableCell as TD,
  TableHeaderColumn as TH,
  TableHeaderRow as THR,
  TableRow as TR
} from 'components'
import { Contents } from 'components/Contents/index'
import { Page } from 'components/Page/index'
import {
  CreateLoanOfficerAssistantButtonForm,
  CreateLoanOfficerButtonForm,
  EditLoanOfficerAssistantButtonForm,
  EditLoanOfficerButtonForm
} from 'content/LoanOfficer'
import { UserImpersonateButton } from 'content/User/UserImpersonateButton'
import { useDocumentTitle, useQuery } from 'hooks'
import { User } from 'models'
import { getLoanOfficerAssistantsUrl, getLoanOfficersUrl } from 'services/getUrl/getUrl'
import { ImpersonateIconButton } from 'templates'
import { deserializeAsync, getRequest, queryStringToObject } from 'utils'
import styles from './LoanOfficersPage.module.scss'

export function LoanOfficersPage() {
  const { search } = useLocation()
  const { sortBy, sortDir, searchTerm } = queryStringToObject(search)
  const sort = `${sortDir === 'asc' ? '' : '-'}${
    sortBy ? sortBy.replace(' ', '_').toLowerCase() : 'id'
  }`
  const [page, setPage] = useState('1')
  const [section, setSection] = useState('Loan Officers')
  const { data, isLoading } = useQuery(
    ['loan-officers', page, search],
    async () => {
      const res = await getRequest(
        getLoanOfficersUrl({
          searchParams: {
            include: 'transaction_team',
            'page[number]': page,
            sort,
            search: searchTerm
          }
        })
      )
      return {
        loanOfficers: await deserializeAsync(res.data),
        total: res.data?.meta?.total
      }
    },
    {
      enabled: section === 'Loan Officers'
    }
  )

  const { data: loaData, isFetching: isLoanOfficerAssistantsLoading } = useQuery(
    ['loan-officer-assistants', page, search],
    async () => {
      const res = await getRequest(
        getLoanOfficerAssistantsUrl({
          searchParams: {
            include: 'phone_numbers',
            'page[number]': page,
            sort,
            search: searchTerm
          }
        })
      )
      return {
        loanOfficerAssistants: await deserializeAsync(res.data, { transform: User.create }),
        total: res.data?.meta?.total
      }
    },
    {
      enabled: section === 'Loan Officer Assistants'
    }
  )

  const pageTitle = section === 'Loan Officers' ? 'Loan Officers' : 'Loan Officer Assistants'
  useDocumentTitle(pageTitle)
  const loanOfficersSection = (
    <Contents title={pageTitle} actions={<CreateLoanOfficerButtonForm />}>
      <SearchStatusPrompt type="Loan Officer" count={data?.total} />
      <SearchConsole search={<SearchInputParams placeholder="Search by name or email" />} />
      <Table>
        <THR>
          <TH sort={[{ name: 'ID', slug: 'id' }]} style={{ width: '64px' }} />
          <TH sort={[{ name: 'Name', slug: 'name' }]} />
          <TH sort={[{ name: 'Email', slug: 'email' }]} />
          <TH sort={[{ name: 'Company Name', slug: 'Company Name' }]} />
          <TH sort={[{ name: 'NMLS Id', slug: 'nmls_id' }]} style={{ width: '96px' }} />
          <TH name="TX Team" />
          <TH name="Actions" style={{ width: '64px' }} />
        </THR>
        <TableResults type="users" colSpan={4} isLoading={isLoading}>
          {data?.loanOfficers.map(loanOfficer => (
            <TR key={loanOfficer.id}>
              <TD>{loanOfficer.id}</TD>
              <TD>{loanOfficer.name || '-'}</TD>
              <TD>{loanOfficer.email || '-'}</TD>
              <TD>{loanOfficer.companyName || '-'}</TD>
              <TD>{loanOfficer.nmlsId || '-'}</TD>
              <TD>{loanOfficer.transactionTeam?.name || '-'}</TD>
              <TD>
                <EditLoanOfficerButtonForm loanOfficer={loanOfficer} />
                <UserImpersonateButton
                  userId={loanOfficer.userId}
                  as={ImpersonateIconButton}
                  customRedirectUrl={process.env.REACT_APP_LOAN_OFFICER_PORTAL_URL}
                />
              </TD>
            </TR>
          ))}
        </TableResults>
      </Table>
      {!isLoading && section === 'Loan Officers' && (
        <Pagination
          totalPages={Math.ceil(data.total / 25)}
          currentPage={page}
          onChange={val => setPage(val)}
        />
      )}
    </Contents>
  )

  const loanOfficerAssistantsSection = (
    <Contents title={pageTitle} actions={<CreateLoanOfficerAssistantButtonForm />}>
      <SearchStatusPrompt type="Loan Officer Assistant" count={loaData?.total} />
      <SearchConsole search={<SearchInputParams placeholder="Search by name or email" />} />
      <Table>
        <THR>
          <TH sort={[{ name: 'Name', slug: 'name' }]} />
          <TH sort={[{ name: 'Email', slug: 'email' }]} />
          <TH sort={[{ name: 'Phone Number', slug: 'phone_number' }]} />
          <TH name="Actions" style={{ width: '64px' }} />
        </THR>
        <TableResults type="users" colSpan={4} isLoading={isLoanOfficerAssistantsLoading}>
          {loaData?.loanOfficerAssistants.map(loanOfficerAssistant => (
            <TR key={loanOfficerAssistant.id}>
              <TD>{loanOfficerAssistant.name || '-'}</TD>
              <TD>{loanOfficerAssistant.email || '-'}</TD>
              <TD>{loanOfficerAssistant?.phoneNumbers?.[0]?.number || '-'}</TD>
              <TD>
                <EditLoanOfficerAssistantButtonForm user={loanOfficerAssistant} />
                <UserImpersonateButton
                  userId={loanOfficerAssistant.id}
                  as={ImpersonateIconButton}
                  customRedirectUrl={process.env.REACT_APP_LOAN_OFFICER_PORTAL_URL}
                />
              </TD>
            </TR>
          ))}
        </TableResults>
      </Table>
      {!isLoanOfficerAssistantsLoading && section === 'Loan Officer Assistants' && (
        <Pagination
          totalPages={Math.ceil(loaData.total / 25)}
          currentPage={page}
          onChange={val => setPage(val)}
        />
      )}
    </Contents>
  )

  return (
    <Page>
      <div className="mb-4 px-6">
        <Flex gap="$4" justifyContent="center" alignItems="center">
          <button
            className={`${styles.loButton} ${
              section === 'Loan Officers' ? styles.loButtonActive : ''
            }`}
            onClick={() => setSection('Loan Officers')}
          >
            Loan Officers
          </button>
          <button
            className={`${styles.loButton} ${
              section === 'Loan Officer Assistants' ? styles.loButtonActive : ''
            }`}
            onClick={() => setSection('Loan Officer Assistants')}
          >
            Loan Officer Assistants
          </button>
        </Flex>
        {section === 'Loan Officers' && loanOfficersSection}
        {section === 'Loan Officer Assistants' && loanOfficerAssistantsSection}
      </div>
    </Page>
  )
}
