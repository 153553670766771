import { cx } from 'utils'
import styles from './Pagination.module.scss'
import { PaginationLink } from './PaginationLink'

interface Props {
  className?: string
  disabledNext: boolean
  disabledPrev: boolean
  onNext: () => void
  onPrevious: () => void
  totalPages?: number
}

export const SimplePagination = ({
  className,
  disabledNext,
  disabledPrev,
  onNext,
  onPrevious,
  totalPages
}: Props) => {
  if (!totalPages || totalPages <= 1) {
    return null
  }

  const handleOnNext = e => {
    e.stopPropagation()
    onNext()
  }

  const handleOnPrevious = e => {
    e.stopPropagation()
    onPrevious()
  }

  return (
    <div className={cx(styles.paginationContainer, className)} data-testid="simple-pagination">
      <PaginationLink disabled={disabledPrev} onClick={e => handleOnPrevious(e)}>
        Previous
      </PaginationLink>
      <PaginationLink disabled={disabledNext} onClick={e => handleOnNext(e)}>
        Next
      </PaginationLink>
    </div>
  )
}
