import React from 'react'
import { Button } from '../../../@foundation/components'
import { getLoanOfficerDataLendersUrl } from '../../../services/getUrl'
import { getRequest } from '../../../utils'
import { AutoComplete, AutoCompleteProps, TEmployeesAutoCompleteOption } from '../../AutoComplete'
import styles from './LenderAutocomplete.module.scss'

type TProps = {
  id?: string
  onClear?: () => void
  onCreate?: (value: string) => void
  onSelect?: AutoCompleteProps['onSelect']
  value?: string
}

export type UserAutoCompleteResponse = {
  attributes: { email: string; name: string }
  id: string
}

export const LenderAutocomplete = ({
  onSelect = () => {},
  value,
  onCreate = () => {},
  ...props
}: TProps) => {
  const formatUser = (user: UserAutoCompleteResponse): TEmployeesAutoCompleteOption => ({
    id: user.id,
    title: user.attributes.name,
    subTitle: user.attributes.email
  })
  const formatLenders = users => users.map(formatUser)

  const getLenders = async (searchTerm: string) => {
    const searchParams = {
      'filter[name]': searchTerm,
      autocomplete: true,
      'page[size]': 5
    }
    const response = await getRequest(getLoanOfficerDataLendersUrl({ searchParams }))

    return formatLenders(response.data.data).slice(0, 5)
  }

  return (
    <AutoComplete
      getData={getLenders}
      onSelect={onSelect}
      onBlur={() => {}}
      value={value}
      allowDefaultValue={true}
      className={styles.root}
      placeholder="---"
      includeClearButton={false}
      selectOnBlur={false}
      resetOnBlur={true}
      closeOnListAction={true}
      renderListAction={(value, callback) => (
        <Button
          size="xs"
          onClick={() => {
            onCreate(value)
            callback?.()
          }}
        >
          Create "{value}"
        </Button>
      )}
      {...props}
    />
  )
}
