// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useMutation, useQueryClient } from 'hooks'
import { User } from 'models'
import { putLoanOfficerAssistant } from 'services/LoanOfficers/putLoanOfficer'
import { validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils'
import { LoanOfficerAssistantFormFields } from './CreateLoanOfficerAssistantButtonForm'
import { TLoanOfficerAssistantFormPayload } from './utils'

type TProps = {
  user: User
}

export const EditLoanOfficerAssistantButtonForm = ({ user }: TProps) => {
  const queryCache = useQueryClient()
  const updateLoanOfficerAssistant = useMutation(
    (data: {}) => putLoanOfficerAssistant(user.id, data),
    {
      onSuccess: () => queryCache.invalidateQueries('loan-officer-assistants')
    }
  )

  const { formProps, fieldsProps } = useButtonForm<TLoanOfficerAssistantFormPayload, string>({
    initialPayload: {
      loanOfficerAssistantName: user.name,
      loanOfficerAssistantEmail: user.email,
      loanOfficerAssistantPhoneNumber: user?.phoneNumbers?.[0]?.number
    },
    mutation: updateLoanOfficerAssistant,
    formValidations: {
      loanOfficerAssistantName: validatePresence,
      loanOfficerAssistantEmail: validateEmailFormat,
      loanOfficerAssistantPhoneNumber: validatePhoneFormat
    }
  })

  return (
    <ButtonForm
      {...formProps}
      isFormValid={formProps.isFormValid}
      title="Edit Loan Officer Assistant"
      buttonComponent={props => <EditIconButton aria-label="edit" onClick={props.onClick} />}
    >
      <LoanOfficerAssistantFormFields {...fieldsProps} isEditing />
    </ButtonForm>
  )
}
