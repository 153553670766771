// @ts-strict
import { getAgentLeadsByAgentUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

type VoidHangUpDetectedPayload = {
  agentId: string
  agentLeadId: string
  note: string
}

export const putVoidHangUpDetectedAgentLead = (
  payload: VoidHangUpDetectedPayload
): Promise<any> => {
  const url = getAgentLeadsByAgentUrl({
    pathParams: {
      agent_id: payload.agentId,
      agent_lead_id: payload.agentLeadId
    },
    action: 'void-hangup-detected'
  })

  return putRequest(url, snakeCaseKeys({ ...payload }))
}
