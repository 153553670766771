// @ts-strict
import { Button, Popover } from '@foundation/components'
import theme from '@foundation/themes'
import { FormField, InlineEditAgent, Section } from 'components'
import { useFeatureFlags } from 'hooks'
import { getAgentFieldName, TMarketplaceProgramSlug } from 'lookups'
import { Agent } from 'models'
import { dataOrDash, formatPhone, toCamelCase } from 'utils'
import { AgentDetailsMenuCard } from '../AgentDetailsMenuCard'
import style from './AgentSection.module.scss'

type BaseProps = {
  agent: Potential<Agent>
  title?: string
}
type TOptionalProps =
  | {
      error?: string
      onBlur: (val: string) => void
      program: TMarketplaceProgramSlug
    }
  | {
      error?: never
      onBlur?: never
      program?: never
    }

export const AgentSection = ({
  agent,
  title,
  program,
  onBlur,
  error
}: BaseProps & TOptionalProps) => {
  const { salesAppAgentDetailsComponent } = useFeatureFlags(['sales-app-agent-details-component'])

  if (salesAppAgentDetailsComponent.enabled) {
    const viewMore = agent?.id && (
      <Popover.Root placement="bottom-start">
        <Popover.Trigger>
          <span>
            <Button size="xs" variant="link">
              View More
            </Button>
          </span>
        </Popover.Trigger>
        <Popover.Content zIndex={theme.zIndices.overlay - 1}>
          <AgentDetailsMenuCard agentId={agent.id} />
        </Popover.Content>
      </Popover.Root>
    )

    return (
      <FormField title={title || 'Referring Agent'} valueSubtitle={viewMore} inline error={error}>
        <InlineEditAgent
          value={agent?.name}
          onBlur={onBlur}
          searchParams={{ [toCamelCase(program)]: 'enrolled' }}
          isError={!!error}
        />
      </FormField>
    )
  }

  const nameComponent = program ? (
    <InlineEditAgent
      value={agent?.name}
      onBlur={onBlur}
      searchParams={{ [toCamelCase(program)]: 'enrolled' }}
    />
  ) : undefined

  return (
    <Section className={style.wrapper}>
      <FormField title={title || 'Referring Agent'} inline>
        {nameComponent || dataOrDash(agent?.name)}
      </FormField>

      <FormField className={style.agentSection} title={getAgentFieldName('team')} inline>
        {dataOrDash(agent?.agentTeamName)}
      </FormField>
      <FormField className={style.agentSection} title={getAgentFieldName('email1')} inline>
        {dataOrDash(agent?.email1)}
      </FormField>
      <FormField className={style.agentSection} title={getAgentFieldName('phone')} inline>
        {formatPhone(
          agent?.getPhoneMobile()?.number || formatPhone(agent?.getPhoneOffice()?.number)
        )}
      </FormField>
      <FormField className={style.agentSection} title={getAgentFieldName('licenseNumber')} inline>
        {dataOrDash(agent?.licenseNumber)}
      </FormField>
      <FormField className={style.agentSection} title={getAgentFieldName('agentBrokerage')} inline>
        {dataOrDash(agent?.officeDisplayName)}
      </FormField>
    </Section>
  )
}
