import { ButtonForm, FormField, TextArea, useButtonForm } from 'components'
import { useBBYSLeadController } from 'hooks'
import { BBYSLead } from 'models'
import { validatePresence } from 'utils'

type Props = {
  bbysLead?: BBYSLead
}

export const AddPropertyConditionsAndRepairs = ({ bbysLead }: Props) => {
  const { updateBBYSLead } = useBBYSLeadController(bbysLead.id)
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: updateBBYSLead,
    initialPayload: {
      propertyConditionsAndRepairs: bbysLead?.propertyConditionsAndRepairs || ''
    },
    formValidations: {
      propertyConditionsAndRepairs: [validatePresence]
    }
  })

  // Create a snippet of the existing property conditions text
  const propertyConditionsSnippet = bbysLead?.propertyConditionsAndRepairs
    ? bbysLead.propertyConditionsAndRepairs.length > 40
      ? `${bbysLead.propertyConditionsAndRepairs.substring(0, 40)}...`
      : bbysLead.propertyConditionsAndRepairs
    : 'Add Property Conditions and Repairs'

  return (
    <ButtonForm title={propertyConditionsSnippet} {...formProps}>
      <FormField title="Property Conditions and Repairs">
        <TextArea
          onChange={val => onChangeAttribute('propertyConditionsAndRepairs', val)}
          data-testid="propertyConditionsAndRepairs"
          value={payload.propertyConditionsAndRepairs}
        />
      </FormField>
    </ButtonForm>
  )
}
