// @ts-strict
import { Marker, Point } from 'classes'
import { Agent, AgentLeadStageUpdate, AgentProviderLead, IntroTracking } from 'models'
import { AgentLeadResponse } from 'schema'
import { formatPrice } from 'utils'

type TAgentLeadConstructor = Omit<AgentLead, 'toMarker' | 'toPoint' | 'getWorkedByTeamMembership'>

export class AgentLead {
  id!: string
  autoIntroSentAt?: string
  agent?: Agent
  algoVersion?: string
  workedByAgent?: Agent
  agentLeadStageUpdates?: AgentLeadStageUpdate[]
  countryCode?: string
  introTracking?: IntroTracking
  introduced?: boolean
  introSentAt?: string
  number?: string
  phoneType?: string
  pplReferral?: boolean
  providerLead?: AgentProviderLead
  rank?: number
  recordingUrl?: string

  constructor(agentLeadAttributes: TAgentLeadConstructor) {
    Object.assign(this, agentLeadAttributes)
  }

  toMarker(color?: string) {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Marker({
          name: `${formatPrice(this.providerLead.lead.price, 'short')} ${
            this.providerLead?.lead.fullAddress
          }`,
          description: this.providerLead.lead.name,
          type: 'lead',
          point: this.toPoint(),
          color
        })
      : undefined
  }

  toPoint() {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Point({
          latitude: this.providerLead?.lead.latitude,
          longitude: this.providerLead?.lead.longitude
        })
      : undefined
  }

  static fromAgent(agent: Agent) {
    return new AgentLead({ agent, id: '' })
  }

  static create(agentLeadResponse: Potential<AgentLeadResponse>): Potential<AgentLead> {
    if (!agentLeadResponse) {
      return
    }

    const agent = Agent.create(agentLeadResponse.agent)
    const workedByAgent = Agent.create(agentLeadResponse.workedByAgent)
    const agentLeadStageUpdates = (agentLeadResponse?.agentLeadStageUpdates?.map(
      AgentLeadStageUpdate.create
    ) || []) as AgentLeadStageUpdate[]
    const providerLead = AgentProviderLead.create(agentLeadResponse?.providerLead)
    const introTracking = IntroTracking.create(agentLeadResponse?.introTracking)

    return new AgentLead({
      ...agentLeadResponse,
      agent,
      workedByAgent,
      agentLeadStageUpdates,
      providerLead,
      introTracking
    })
  }
}
