// @ts-strict
import { useQuery } from 'hooks'
import { BulkEquityUnlockCalculation } from 'models'
import { fetchBulkEquityUnlockCalculations } from 'services/BulkEquityUnlockCalculations'

export const BULK_EQUITY_UNLOCK_CALCULATIONS_CACHE_KEY = 'bulkEquityUnlockCalculations'

export interface IUseBulkEquityUnlockCalculationsProps {
  pageNumber: number
  pageSize: number
}

export interface IUseBulkEquityUnlockCalculationsResponse {
  bulkEquityUnlockCalculations: BulkEquityUnlockCalculation[]
  isLoading: boolean
  totalCount: number
  totalPages: number
}

export const useBulkEquityUnlockCalculations = ({
  pageNumber,
  pageSize
}: IUseBulkEquityUnlockCalculationsProps) => {
  const { data: { bulkEquityUnlockCalculations = [], meta } = {}, isLoading } = useQuery(
    [BULK_EQUITY_UNLOCK_CALCULATIONS_CACHE_KEY, pageNumber, pageSize],
    () => fetchBulkEquityUnlockCalculations({ pageNumber, pageSize }),
    {
      refetchOnWindowFocus: 'always'
    }
  )

  const totalCount = meta?.totalCount ?? 0
  const totalPages = meta?.totalPages ?? 0

  return {
    data: bulkEquityUnlockCalculations,
    isLoading,
    totalCount,
    totalPages
  }
}
