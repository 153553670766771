// @ts-strict
import { Text } from '@foundation/components'
import { Option } from 'classes'
import { Section, Select, TButtonFormFieldsProps } from 'components'
import { Agent, Agreement } from 'models'
import { formatDate } from 'utils'

type Props = TButtonFormFieldsProps<any> & { agent: Agent }

export const IssueButtonFormFields = ({ agent, payload, onChangeAttribute }: Props) => {
  const lastestAgreementText = 'Latest Agreement: '
  const agreementText =
    agent.lastSignedAgreement?.agreement?.version +
    ', ' +
    formatDate(agent.lastSignedAgreement?.signedAt) +
    ', ' +
    agent.lastSignedAgreement?.agreement?.commissionSplit +
    '%'

  const eligibleAgreements =
    agent.eligibleAgreements?.filter(agreement => agreement.agreementType === 'agreement') || []

  const agreementOptions = eligibleAgreements.map((agreement: Agreement) => {
    let name
    if (agent.lastSignedAgreement?.agreement?.id! === agreement.id) {
      name = `Last signed, Commission Split: ${agreement.commissionSplit}%, Version ${agreement.version}`
    } else {
      name = `Latest valid, Commission Split: ${agreement.commissionSplit}% (default), Version ${agreement.version}`
    }

    return new Option({ name: name, value: agreement.id })
  })

  const setAgreement = (val: string) => onChangeAttribute('agreementId', val)

  return (
    <>
      <Section>
        <Text as="span" size="base" weight="semibold">
          {lastestAgreementText}
        </Text>
        <Text as="span" size="base" weight="bold">
          {agreementText}
        </Text>
      </Section>
      <Section>
        <Text as="span" weight="normal">
          Select the agreement you would like to reissue to the agent.
        </Text>
        <br />
        <Text as="span" weight="normal">
          You can either issue the latest valid agreement in the state or the existing signed
          agreement.
        </Text>
      </Section>
      <Section>
        <Text as="span" weight="normal">
          Once you issue, the agent will be blocked from claiming additional referrals until signed.
        </Text>
      </Section>
      <Section>
        <Select
          id="AgentAgreement"
          data-testid="programSelect"
          value={payload.agreementId}
          onChange={setAgreement}
          addBlank
          options={agreementOptions}
        />
      </Section>
    </>
  )
}
