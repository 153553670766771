// @ts-strict
import { AgentLeadsContextType } from 'contexts/types'
import { useQuery } from 'hooks'
import { fetchAgentLeads } from 'services'
import { getPageCount } from 'utils'

const defaultSearchSettings = { perPage: 10 }

type Props = {
  agentId: string
  enabled?: boolean
  searchParams?: Record<string, string>
}

export const useAgentLeadsController = ({
  agentId,
  searchParams,
  enabled = true
}: Props): AgentLeadsContextType => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const { data, isLoading } = useQuery(
    ['agentLeads', agentId, { searchSettings }],
    () => fetchAgentLeads(agentId, searchSettings),
    { enabled, refetchOnWindowFocus: false }
  )

  const agentLeads = data?.agentLeads ? data.agentLeads : []
  const totalCount = data?.totalCount ?? 0
  const totalUrgentCount = data?.totalUrgentCount ?? 0
  const pageCount = getPageCount(totalCount, searchSettings.perPage)

  return {
    agentLeads,
    pageCount,
    totalCount,
    totalUrgentCount,
    isLoading
  }
}
