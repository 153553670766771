import { useState } from 'react'
import styled from 'styled-components'
import { Flex } from '@foundation/components/Flex/styles'
import styles from '@foundation/components/Table/Table.module.scss'
import { Button } from 'components'
import {
  CheckBoxField,
  Column,
  Columns,
  type TEmployeesAutoCompleteOption as Employee,
  EmployeesAutoComplete,
  Icon,
  MenuSection,
  Section,
  SectionFooter
} from 'components'
import { useUpdatePod } from '../../hooks/use-update-pod'

const Container = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ItemStyles = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  color: #55585e;
  border-radius: 4px;
  background-color: #f5f6f9;
  cursor: pointer;
`

const StyledMenuSection = styled(MenuSection)`
  flex: 1;
`

const Item = ({ name, onClick }: { name: string; onClick: () => void }) => (
  <ItemStyles onClick={onClick}>
    <span>{name}</span>
    <Icon.Close width={12} height={12} />
  </ItemStyles>
)

const getListDiff = (list: LocalEmployee[], selectedItems: LocalEmployee[]) => {
  return [
    selectedItems.filter(item => !list.some(listItem => listItem.id === item.id)),
    list.filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id))
  ]
}

const employeeToLocalEmployee = (employee: Employee): LocalEmployee => ({
  id: employee.id,
  name: employee.title
})

export type LocalEmployee = {
  id: string
  name: string
}

type PodsColumnsControlProps = {
  close: () => void
  lrms: LocalEmployee[]
  lsms: LocalEmployee[]
  specialPartners: LocalEmployee[]
}

export function PodsColumnsControl({
  lrms,
  lsms,
  specialPartners,
  close
}: PodsColumnsControlProps) {
  const [selectedLrms, setSelectedLrms] = useState<LocalEmployee[]>(lrms)
  const [selectedLsms, setSelectedLsms] = useState<LocalEmployee[]>(lsms)

  const { mutate, isLoading } = useUpdatePod({
    onSuccess: () => {
      close()
    }
  })

  const addLrm = (lrm: Employee) => {
    const localLrm = employeeToLocalEmployee(lrm)
    setSelectedLrms(prev => [...prev, localLrm])
  }

  const removeLrm = (lrm: LocalEmployee) => {
    setSelectedLrms(prev => prev.filter(prevLrm => prevLrm.id !== lrm.id))
  }

  const addLsm = (lsm: Employee) => {
    const localLsm = employeeToLocalEmployee(lsm)
    setSelectedLsms(prev => [...prev, localLsm])
  }

  const removeLsm = (lsm: LocalEmployee) => {
    setSelectedLsms(prev => prev.filter(prevLsm => prevLsm.id !== lsm.id))
  }

  const [newLrms, removedLrms] = getListDiff(lrms, selectedLrms)
  const [newLsms, removedLsms] = getListDiff(lsms, selectedLsms)

  const hasChanges =
    newLrms.length > 0 || removedLrms.length > 0 || newLsms.length > 0 || removedLsms.length > 0

  const onSave = () => {
    mutate({
      new_lrms: newLrms.map(lrm => lrm.id),
      deleted_lrms: removedLrms.map(lrm => lrm.id),
      new_lsms: newLsms.map(lsm => lsm.id),
      deleted_lsms: removedLsms.map(lsm => lsm.id)
    })
  }

  return (
    <Section className={styles.section}>
      <Container>
        <Columns hasFixedHeight>
          <Column className={styles.column}>
            <MenuSection title="Lender Relationship Manager (LRM)" className={styles.menuSection}>
              <EmployeesAutoComplete value="" onSelect={addLrm} clearOnSelect />
              <Flex marginTop="$8" flexDirection="column" gap="$3">
                {selectedLrms.map(lrm => (
                  <Item key={lrm.id} name={lrm.name} onClick={() => removeLrm(lrm)} />
                ))}
              </Flex>
            </MenuSection>
          </Column>

          <Column className={styles.column}>
            <StyledMenuSection title="Special partners" className={styles.menuSection}>
              <Flex flexDirection="column" gap="0">
                {specialPartners.map(sp => (
                  <CheckBoxField key={sp.id} disabled title={sp.name} checked />
                ))}
              </Flex>
            </StyledMenuSection>
          </Column>

          <Column className={styles.column}>
            <MenuSection title="Lender Sales Manager (LSM)" className={styles.menuSection}>
              <EmployeesAutoComplete value="" onSelect={addLsm} clearOnSelect />
              <Flex marginTop="$8" flexDirection="column" gap="$3">
                {selectedLsms.map(lsm => (
                  <Item key={lsm.id} name={lsm.name} onClick={() => removeLsm(lsm)} />
                ))}
              </Flex>
            </MenuSection>
          </Column>
        </Columns>
      </Container>

      <SectionFooter className={styles.footer}>
        <Button
          as="submit"
          size="medium"
          onClick={onSave}
          submitting={isLoading}
          disabled={!hasChanges}
        >
          Save changes
        </Button>
      </SectionFooter>
    </Section>
  )
}
