// @ts-strict
import { useMutation } from 'hooks'
import { putVoidHangUpDetectedAgentLead } from 'services'

export const useAgentLeadsVoidHangUpDetectedController = () => {
  const { mutateAsync: voidHangUpDetected, isLoading } = useMutation(putVoidHangUpDetectedAgentLead)

  return {
    voidHangUpDetected,
    isLoading
  }
}
