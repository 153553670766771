// @ts-strict
import { FileInput, FormField, Select } from 'components'
import { Partner } from 'models/Partner'
import { TEquityUnlockCalculatorFileUploadFormFieldsPayload } from './types'

type TFileUploadFormFieldsProps = {
  onChangeAttribute: <K extends keyof TEquityUnlockCalculatorFileUploadFormFieldsPayload>(
    key: K,
    value: TEquityUnlockCalculatorFileUploadFormFieldsPayload[K]
  ) => void
  partners?: Partner[]
  payload: TEquityUnlockCalculatorFileUploadFormFieldsPayload
}

const acceptedFileTypes = `.csv,.xls,.xlsx`

export const EquityUnlockCalculatorFileUploadFormFields = ({
  payload,
  partners = [],
  onChangeAttribute
}: TFileUploadFormFieldsProps) => {
  const partnerOptions = partners.map(partner => ({
    label: partner.slug,
    value: partner.id
  }))
  return (
    <div>
      <FormField title="Partner Slug" htmlFor="partner" required>
        <Select
          id="partnerId"
          value={payload.partnerId}
          placeholder="Select Partner"
          options={partnerOptions}
          onChange={val => onChangeAttribute('partnerId', val)}
        />
      </FormField>
      <FormField title="File (csv, xls, xlsx)" htmlFor="file" required>
        <FileInput
          id="file-name"
          data-testid="fileUploadInput"
          accept={acceptedFileTypes}
          value={payload.files}
          onChange={value => {
            onChangeAttribute('files', value)
          }}
          multiple={false}
        />
      </FormField>
    </div>
  )
}
