// @ts-strict
import { useState } from 'react'
import { SERVICE_STATES } from '@constants/bbysServiceStates'
import { Button } from '@foundation/components'
import { ButtonForm, CheckBoxField, DateInput, FormField, Select, TextInput } from 'components'
import { useGeneratedDocumentsController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { bbysLeadWarehouseOptions } from 'lookups'
import { BBYSLoan, CCBBYSLead } from 'models'
import { capitalizeWords, formatDate } from 'utils'

type TLeadNewGeneratedDocumentButtonFormProps = {
  lead?: CCBBYSLead
}

const agreementFields = [
  'title',
  'date',
  'valid_date',
  'client_1',
  'client_2',
  'lo_company_name',
  'dr_address',
  'agent_valuation',
  'eu_amount',
  'first_lien',
  'second_lien',
  'inspection_cost',
  'program_fee',
  'property_conditions_and_repairs',
  'loan_payoff_value',
  'bbys_loan_amount'
]

const agreementOptionalFields = ['client_2', 'lo_company_name', 'property_conditions_and_repairs']

const newPayoffDemandDocumentSlugs = [
  {
    name: 'Dynamic Payoff Demand',
    value: 'dynamic-payoff-demand',
    title: 'Payoff Demand',
    fields: [
      'title',
      'date',
      'payoff_valid_through_date',
      'warehouse_facility',
      'client_1',
      'client_2',
      'dr_address_line_1',
      'dr_address_line_2',
      'loan_number',
      'dynamic_payoff_final_sale_price',
      'dynamic_payoff_equity_unlock_loan_amount',
      'dynamic_payoff_program_fee',
      'dynamic_payoff_inspection_fee',
      'dynamic_payoff_principle_balance',
      'dynamic_payoff_program_fee_adjustment',
      'dynamic_payoff_program_fee_discount',
      'dynamic_payoff_inspection_fee_adjustment',
      'closing_management_fee',
      'late_listing_fee',
      'dynamic_payoff_recording_fee',
      'dynamic_payoff_di_taxes',
      'dynamic_payoff_notary_fee',
      'total_payoff_amount',
      'closing_management_fee_waived_flag'
    ],
    optional: ['client_2', 'dr_address_line_2']
  }
]

const nonpayoffDemandDocumentSlugs = [
  {
    name: 'Dynamic Buy Before You Sell Agreement',
    value: 'conditional-document-formatting',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: [
      'title',
      'date',
      'client_1',
      'client_1_email',
      'client_2',
      'client_2_email',
      'cover_names',
      'dr_address',
      'valid_date',
      'bbys_loan_amount',
      'eu_amount',
      'equity_boost',
      'second_lien',
      'program_length',
      'program_fee_percentage',
      'inspection_cost',
      'agent_valuation',
      'loan_payoff_value',
      'property_conditions_and_repairs',
      'lo_company_name',
      'final_eligibility_flag',
      'conditional_eligibility_flag',
      'required_home_repairs_flag',
      'closing_management_flag',
      'program_fee_flag',
      'no_buy_before_you_sell_loan_flag',
      'equity_boost_election_flag',
      'texas_flag',
      'maintenance_reserve_flag',
      'prmi_borrower_consent_flag'
    ],
    optional: ['client_2', 'client_2_email', 'cover_names', 'property_conditions_and_repairs']
  },
  {
    name: 'Dynamic Buy Before You Sell Agreement (Orchard)',
    value: 'orchard-conditional-document-formatting',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: [
      'title',
      'date',
      'client_1',
      'client_1_email',
      'client_2',
      'client_2_email',
      'cover_names',
      'dr_address',
      'valid_date',
      'bbys_loan_amount',
      'eu_amount',
      'equity_boost',
      'second_lien',
      'program_length',
      'program_fee_percentage',
      'inspection_cost',
      'agent_valuation',
      'loan_payoff_value',
      'property_conditions_and_repairs',
      'lo_company_name',
      'final_eligibility_flag',
      'conditional_eligibility_flag',
      'required_home_repairs_flag',
      'closing_management_flag',
      'program_fee_flag',
      'no_buy_before_you_sell_loan_flag',
      'equity_boost_election_flag',
      'texas_flag',
      'maintenance_reserve_flag',
      'prmi_borrower_consent_flag'
    ],
    optional: ['client_2', 'client_2_email', 'cover_names', 'property_conditions_and_repairs']
  },
  {
    name: 'Conditional BBYS Agreement (Service State)',
    value: 'service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Conditional BBYS Agreement (Non-Service State)',
    value: 'non-service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Conditional BBYS Agreement (No Closing Support Fee)',
    value: 'no-closing-support-fee-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Final BBYS Agreement (Service State)',
    value: 'final-service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement (Final)',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Final BBYS Agreement (Non-Service State)',
    value: 'final-non-service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement (Final)',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Final BBYS Agreement (No Closing Support Fee)',
    value: 'final-no-closing-support-fee-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Upside Guarantee',
    value: 'upside-guarantee',
    title: 'HomeLight Upside Guarantee',
    fields: [
      'title',
      'date',
      'valid_date',
      'client_1',
      'client_1_email',
      'client_2',
      'client_2_email',
      'dr_address',
      'agent_name',
      'agent_valuation',
      'agent_fee',
      'loan_payoff_value'
    ],
    optional: ['client_2', 'client_2_email']
  }
]

type LeadGeneratedDocumentSlugs =
  | 'service-state-agreement'
  | 'non-service-state-agreement'
  | 'no-closing-support-fee-agreement'
  | 'upside-guarantee'
  | 'payoff-demand-hlhl-flagstar'
  | 'payoff-demand-hlhl-florida-capital-bank'
  | 'payoff-demand-hlhl-equity'
  | 'payoff-demand-tls-flagstar'
  | 'conditional-document-formatting'
  | 'orchard-conditional-document-formatting'
  | 'dynamic-payoff-demand'

// | 'lennar-agreement'
// | 'payoff-demand'
// | 'termination-fee-invoice'
// | 'program-fee-invoice'

const sumLoansFields = (
  loans: BBYSLoan[] | undefined,
  field: string,
  featureFladEnabled: boolean = false
): number => {
  if (!loans) {
    return 0
  }

  const sum = featureFladEnabled
    ? loans.reduce((acc, loan) => acc + parseFloat(loan[field] ?? '0'), 0)
    : parseFloat(loans[field] ?? '0')
  return sum
}
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
})
const withCentsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2
})
const formatWithCentsCurrency = (value: number) => withCentsFormatter.format(value)
const formatCurrency = (value: number) => formatter.format(value)
const formatToNumber = (value: any) => {
  value = value || '$0'
  return parseFloat(value.replace(/[^0-9.-]+/g, ''))
}
const formats = {
  administration_fee: formatCurrency,
  agent_fee: formatCurrency,
  agent_valuation: formatCurrency,
  bbys_loan_amount: formatCurrency,
  di_taxes: formatCurrency,
  equity_unlock_loan_amount: formatCurrency,
  equity_boost: formatCurrency,
  eu_amount: formatCurrency,
  first_lien: formatCurrency,
  inspection_cost: formatCurrency,
  loan_payoff_value: formatCurrency,
  notary_fee: formatCurrency,
  program_fee_adjustment: formatCurrency,
  program_fee: formatCurrency,
  recording_fee: formatCurrency,
  sale_price: formatCurrency,
  second_lien: formatCurrency,
  termination_fee: formatCurrency,
  total_due: formatCurrency,
  purchase_agreement_price: formatCurrency,
  dynamic_payoff_principle_balance: formatWithCentsCurrency,
  dynamic_payoff_program_fee: formatWithCentsCurrency,
  dynamic_payoff_program_fee_adjustment: formatWithCentsCurrency,
  dynamic_payoff_program_fee_discount: formatWithCentsCurrency,
  dynamic_payoff_inspection_fee: formatWithCentsCurrency,
  dynamic_payoff_inspection_fee_adjustment: formatWithCentsCurrency,
  closing_management_fee: formatWithCentsCurrency,
  late_listing_fee: formatWithCentsCurrency,
  total_payoff_amount: formatWithCentsCurrency,
  dynamic_payoff_recording_fee: formatWithCentsCurrency,
  closing_management_fee_waived: formatWithCentsCurrency,
  dynamic_payoff_final_sale_price: formatWithCentsCurrency,
  dynamic_payoff_di_taxes: formatWithCentsCurrency,
  dynamic_payoff_notary_fee: formatWithCentsCurrency,
  dynamic_payoff_equity_unlock_loan_amount: formatWithCentsCurrency
}

export type TLeadGeneratedDocumentPayload = {
  [key: string]: string
}

export const LeadNewGeneratedDocumentButtonForm = ({
  lead
}: TLeadNewGeneratedDocumentButtonFormProps) => {
  // @ts-ignore
  const { generateDocument } = useGeneratedDocumentsController({ bbysLeadId: lead?.bbysLead.id })
  const { repaperExtensionTracking } = useFeatureFlags(['repaper-extension-tracking'])

  const generateDocumentSlugs = nonpayoffDemandDocumentSlugs.concat(newPayoffDemandDocumentSlugs)
  const [payload, setPayload] = useState(() => {
    const isOrchardPartner = lead?.bbysLead?.sourcePartner?.slug === 'orchard'
    const defaultSlug = isOrchardPartner
      ? 'orchard-conditional-document-formatting'
      : 'conditional-document-formatting'

    // Initialize the payload
    const initialPayloadData = initialPayload(
      defaultSlug as LeadGeneratedDocumentSlugs
    ) as TLeadGeneratedDocumentPayload

    // For Orchard partners, set final_eligibility_flag to true and conditional_eligibility_flag to false
    if (isOrchardPartner) {
      initialPayloadData.final_eligibility_flag = 'true'
      initialPayloadData.conditional_eligibility_flag = 'false'
    }

    return initialPayloadData
  })
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function initialPayload(slug: LeadGeneratedDocumentSlugs): TLeadGeneratedDocumentPayload {
    const payload: TLeadGeneratedDocumentPayload = { slug }
    const config = generateDocumentSlugs.find(s => s.value === slug) || generateDocumentSlugs[0]

    const expenses = lead?.bbysProviderLead?.providerLeadExpenses

    const getExpenseValueBySlug = (slug: string) => {
      const expenseBySlug = expenses?.find(expense => {
        return expense.slug === slug
      })
      return expenseBySlug?.value
    }
    const basicFields = {
      title: (): string =>
        `${config.title}${
          config.fields.includes('conditional_eligibility_flag')
            ? ` ${!basicFields.conditional_eligibility_flag() ? 'Final' : 'Conditional'}`
            : ''
        } - ${lead?.fullAddress} - ${[
          lead?.bbysLead?.primaryClientNameOnTitle,
          lead?.bbysLead?.additionalClientNameOnTitle
        ]
          .filter(c => !!c)
          .join(' & ')}`,
      administration_fee: (): number =>
        lead?.bbysLead?.administrationFee ? parseFloat(lead?.bbysLead?.administrationFee) : 0,
      agent_name: (): string => lead?.bbysLead?.departingPropertySellerAgent?.name || '',
      agent_fee: (): number => parseFloat(lead?.bbysLead?.dpTotalAgentCommission || '0'),
      agent_valuation: (): number =>
        parseFloat(
          lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue || '0'
        ),
      bbys_loan_amount: (): number =>
        lead?.bbysLead?.buyBeforeYouSellLoanAmount
          ? parseFloat(lead?.bbysLead?.buyBeforeYouSellLoanAmount)
          : 0,
      client_1: (): string => lead?.bbysLead?.primaryClientNameOnTitle || '',
      client_1_email: (): string => lead?.email || '',
      client_2: (): string => lead?.bbysLead?.additionalClientNameOnTitle || '',
      client_2_email: (): string => lead?.emailAlt || '',
      lo_company_name: (): string => lead?.bbysLead?.loanOfficer?.companyName || '',
      clients: (): string =>
        [lead?.bbysLead?.primaryClientNameOnTitle, lead?.bbysLead?.additionalClientNameOnTitle]
          .filter(c => !!c)
          .join(' & '),
      date: (): string => new Date().toISOString(),
      di_taxes: (): number => {
        const taxes = sumLoansFields(
          lead?.bbysLead?.bbysLoans,
          'documentaryAndIntangibleTaxes',
          repaperExtensionTracking.enabled
        )

        return taxes
      },
      dr_address: (): string => lead?.fullAddress || '',
      dr_address_line_1: (): string => lead?.fullAddress?.split(', ')[0] || '',
      dr_address_line_2: (): string => {
        return lead?.fullAddress?.split(', ').slice(1).join(', ') || ''
      },
      equity_unlock_loan_amount: (): number => {
        const loan_amount = repaperExtensionTracking.enabled
          ? lead?.bbysLead?.originalBBYSLoan?.loanFundingAmount
          : lead?.bbysLead?.equityUnlockLoanFundingAmount
        return loan_amount ? parseFloat(loan_amount) : 0
      },
      eu_amount: (): number =>
        lead?.bbysLead?.dpMaxDownpaymentEquity
          ? parseFloat(lead?.bbysLead?.dpMaxDownpaymentEquity)
          : 0,
      first_lien: (): number =>
        lead?.bbysLead?.dpFirstPositionPayoffs
          ? parseFloat(lead?.bbysLead?.dpFirstPositionPayoffs)
          : 0,
      inspection_cost: (): number =>
        getExpenseValueBySlug('target_property_pest_inspections')
          ? parseFloat(getExpenseValueBySlug('target_property_pest_inspections') ?? '0')
          : 0,
      loan_number: (): string =>
        (repaperExtensionTracking.enabled
          ? lead?.bbysLead?.originalBBYSLoan?.loanNumber
          : lead?.bbysLead?.buyBeforeYouSellLoanNumber) || '',
      loan_payoff_value: (): number =>
        lead?.bbysLead?.loanPayoffValue ? parseFloat(lead?.bbysLead?.loanPayoffValue) : 0,
      cover_names: (): string => {
        const nameOfTrust = lead?.bbysLead?.nameOfTrust ?? ''
        if (lead?.bbysLead?.departingResidenceHeldInTrust) {
          return nameOfTrust
        }
        const primaryClient = lead?.bbysLead?.primaryClientNameOnTitle ?? ''
        const additionalClient = lead?.bbysLead?.additionalClientNameOnTitle ?? ''
        return [primaryClient, additionalClient].filter(Boolean).join(' & ')
      },
      name_of_trust: (): string => {
        const nameOfTrust = lead?.bbysLead?.nameOfTrust ?? ''
        return lead?.bbysLead?.departingResidenceHeldInTrust ? nameOfTrust : ''
      },
      notary_fee: (): number => {
        const fee = sumLoansFields(
          lead?.bbysLead?.bbysLoans,
          'notaryFee',
          repaperExtensionTracking.enabled
        )

        return fee
      },
      payoff_valid_through_date: (): string => {
        let coe, targetPurchaseCoe
        if (lead?.bbysLead?.departingResidenceExpectedCloseEscrowDate) {
          coe = new Date(lead?.bbysLead?.departingResidenceExpectedCloseEscrowDate)
          coe.setDate(coe.getDate() + 10)
        }
        if (lead?.bbysLead?.departingPropertyTargetPurchaseCoeDate) {
          targetPurchaseCoe = new Date(lead?.bbysLead?.departingPropertyTargetPurchaseCoeDate)
        }
        if (coe && targetPurchaseCoe) {
          return (coe > targetPurchaseCoe ? targetPurchaseCoe : coe).toISOString()
        } else if (coe) {
          return coe.toISOString()
        } else if (targetPurchaseCoe) {
          return targetPurchaseCoe.toISOString()
        }
        return ''
      },
      dynamic_payoff_equity_unlock_loan_amount: (): number =>
        basicFields.equity_unlock_loan_amount(),
      dynamic_payoff_final_sale_price: (): number => basicFields.sale_price(),
      dynamic_payoff_di_taxes: (): number => basicFields.di_taxes(),
      dynamic_payoff_notary_fee: (): number => basicFields.notary_fee(),
      dynamic_payoff_principle_balance: (): number =>
        basicFields.equity_unlock_loan_amount() +
        basicFields.dynamic_payoff_program_fee() +
        basicFields.dynamic_payoff_inspection_fee(),
      dynamic_payoff_program_fee: (): number => {
        let dynamic_program_fee
        if (lead?.bbysLead?.contingencyRemovalOnly) {
          dynamic_program_fee = Math.max(
            7000,
            (lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue
              ? parseFloat(
                  lead?.bbysLead?.agentEstimatedHomeValue ||
                    lead?.bbysLead?.lenderEstimatedHomeValue ||
                    '0'
                )
              : 0) * 0.015
          )
        } else {
          dynamic_program_fee = Math.max(
            9000,
            (lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue
              ? parseFloat(
                  lead?.bbysLead?.agentEstimatedHomeValue ||
                    lead?.bbysLead?.lenderEstimatedHomeValue ||
                    '0'
                )
              : 0) * 0.024
          )
        }
        return dynamic_program_fee
      },
      dynamic_payoff_program_fee_adjustment: (): number =>
        Math.max(
          (basicFields.program_fee_percentage() / 100) * basicFields.sale_price(),
          lead?.bbysLead?.contingencyRemovalOnly ? 7000 : 9000
        ) -
        Math.max(
          (basicFields.program_fee_percentage() / 100) *
            parseFloat(
              lead?.bbysLead?.agentEstimatedHomeValue ||
                lead?.bbysLead?.lenderEstimatedHomeValue ||
                '0'
            ),
          lead?.bbysLead?.contingencyRemovalOnly ? 7000 : 9000
        ) +
        (lead?.bbysLead?.programFeeSurcharge ? parseFloat(lead?.bbysLead?.programFeeSurcharge) : 0),
      dynamic_payoff_program_fee_discount: (): number =>
        (lead?.bbysLead?.programFeeDiscount ? parseFloat(lead?.bbysLead?.programFeeDiscount) : 0) *
        -1,
      dynamic_payoff_inspection_fee: (): number => basicFields.inspection_cost(),
      dynamic_payoff_inspection_fee_adjustment: (): number => 0,
      dynamic_payoff_recording_fee: (): number => {
        const recording_fee = sumLoansFields(
          lead?.bbysLead?.bbysLoans,
          'recordingFee',
          repaperExtensionTracking.enabled
        )

        return recording_fee * 2
      },
      closing_management_fee: (): number =>
        lead?.bbysLead?.administrationFee ? parseFloat(lead?.bbysLead?.administrationFee) : 1450,
      closing_management_fee_waived: (): number =>
        (basicFields.closing_management_fee_waived_flag() ? -1 : 0) *
        basicFields.closing_management_fee(),
      late_listing_fee: (): number =>
        lead?.bbysLead?.lateListingFee ? parseFloat(lead?.bbysLead?.lateListingFee) : 0,
      total_payoff_amount: (): number =>
        basicFields.dynamic_payoff_equity_unlock_loan_amount() +
        basicFields.dynamic_payoff_program_fee() +
        basicFields.dynamic_payoff_inspection_fee() +
        basicFields.dynamic_payoff_program_fee_adjustment() +
        basicFields.dynamic_payoff_program_fee_discount() +
        basicFields.dynamic_payoff_inspection_fee_adjustment() +
        basicFields.closing_management_fee() +
        basicFields.late_listing_fee() +
        basicFields.dynamic_payoff_recording_fee() +
        basicFields.dynamic_payoff_di_taxes() +
        basicFields.dynamic_payoff_notary_fee() +
        basicFields.closing_management_fee_waived(),
      principle_balance: (): number =>
        basicFields.bbys_loan_amount() + basicFields.program_fee() + basicFields.inspection_cost(),
      property_conditions_and_repairs: (): string =>
        lead?.bbysLead?.propertyConditionsAndRepairs || '',
      program_fee: (): number =>
        Math.max(
          9000,
          (lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue
            ? parseFloat(
                lead?.bbysLead?.agentEstimatedHomeValue ||
                  lead?.bbysLead?.lenderEstimatedHomeValue ||
                  '0'
              )
            : 0) *
            (lead?.bbysLead?.dpTargetTradeInFeeRate
              ? parseFloat(lead?.bbysLead?.dpTargetTradeInFeeRate)
              : 0)
        ),
      program_fee_adjustment: (): number =>
        (lead?.bbysLead?.programFeeSurcharge
          ? parseFloat(lead?.bbysLead?.programFeeSurcharge)
          : 0) -
        (lead?.bbysLead?.programFeeDiscount ? parseFloat(lead?.bbysLead?.programFeeDiscount) : 0),
      program_fee_percentage: (): number =>
        lead?.bbysLead?.dpTargetTradeInFeeRate
          ? parseFloat(lead?.bbysLead?.dpTargetTradeInFeeRate) * 100
          : 0,
      program_length: (): number =>
        lead?.bbysLead?.programLength ? parseFloat(lead?.bbysLead?.programLength) : 120,
      recording_fee: (): number => {
        const fee = repaperExtensionTracking.enabled
          ? lead?.bbysLead?.originalBBYSLoan?.recordingFee
          : lead?.bbysLead?.recordingFee
        return fee ? parseFloat(fee) : 0
      },
      sale_price: (): number =>
        lead?.bbysLead?.departingResidenceFinalSalePrice
          ? parseFloat(lead?.bbysLead?.departingResidenceFinalSalePrice)
          : 0,
      second_lien: (): number =>
        lead?.bbysLead?.dpNonFirstPositionBalances
          ? parseFloat(lead?.bbysLead?.dpNonFirstPositionBalances)
          : 0,
      termination_fee: (): number =>
        lead?.bbysLead?.terminationFee ? parseFloat(lead?.bbysLead?.terminationFee) : 0,
      total_due: (): number => {
        switch (payload.slug) {
          case 'payoff-demand-hlhl-flagstar':
          case 'payoff-demand-hlhl-florida-capital-bank':
          case 'payoff-demand-hlhl-equity':
          case 'payoff-demand-tls-flagstar':
            return (
              basicFields.bbys_loan_amount() +
              basicFields.program_fee_adjustment() +
              basicFields.recording_fee() +
              basicFields.notary_fee() +
              basicFields.di_taxes()
            )
          case 'termination-fee':
            return (
              basicFields.termination_fee() +
              basicFields.program_fee() +
              basicFields.inspection_cost() +
              basicFields.recording_fee() +
              basicFields.di_taxes()
            )
          case 'program-fee':
            return (
              basicFields.program_fee() +
              basicFields.inspection_cost() +
              basicFields.administration_fee() +
              basicFields.di_taxes()
            )
          default:
            return 0
        }
      },
      valid_date: (): string => {
        const now = new Date()
        now.setDate(now.getDate() + 30)
        return now.toISOString()
      },
      equity_unlock_amount_for_new_home: (): string =>
        lead?.bbysLead?.dpMaxDownpaymentEquity || '0',
      equity_boost: (): string => lead?.bbysLead?.equityBoostAmount ?? '0',
      amount_for_outstanding_balances: (): string =>
        lead?.bbysLead?.dpNonFirstPositionBalances || '0',
      program_period: (): string => lead?.bbysLead?.programLength || '0',
      purchase_agreement_price: (): string =>
        lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue || '0',
      conditional_eligibility_flag: (): boolean =>
        lead?.bbysLead?.inspectionsRequired && !lead?.bbysLead?.inspectionCompleteDate
          ? true
          : false,
      final_eligibility_flag: (): boolean => !basicFields.conditional_eligibility_flag(),
      required_home_repairs_flag: (): boolean =>
        lead?.bbysLead?.departingResidenceRequiredRepairsPreFunding ? true : false,
      closing_management_flag: (): boolean =>
        lead?.stateCode &&
        SERVICE_STATES.includes(lead.stateCode) &&
        lead?.bbysLead?.sourcePartner?.slug !== 'tls' &&
        lead?.bbysLead?.sourcePartner?.slug !== 'prmi' &&
        lead?.bbysLead?.sourcePartner?.slug !== 'fairway'
          ? true
          : false,
      prmi_borrower_consent_flag: (): boolean =>
        lead?.bbysLead?.sourcePartner?.slug === 'prmi' ? true : false,
      program_fee_flag: (): boolean => (lead?.bbysLead?.contingencyRemovalOnly ? true : false),
      no_buy_before_you_sell_loan_flag: (): boolean =>
        lead?.bbysLead?.contingencyRemovalOnly ? true : false,
      equity_boost_election_flag: (): boolean => (lead?.bbysLead?.equityBoost ? true : false),
      texas_flag: (): boolean => lead?.stateCode === 'TX',
      closing_management_fee_waived_flag: (): boolean => false,
      maintenance_reserve_flag: (): boolean => (lead?.bbysLead?.maintenanceReserve ? true : false),
      warehouse_facility: (): string =>
        (repaperExtensionTracking.enabled
          ? lead?.bbysLead?.originalBBYSLoan?.warehouseFacility
          : lead?.bbysLead?.warehouseFacility) || ''
    }

    for (const field of config.fields) {
      if (field in basicFields) {
        const value = basicFields[field]()
        payload[field] = formats[field] ? formats[field](value) : value.toString()
      }
    }

    if (payload.slug === 'dynamic-payoff-demand') {
      payload['closing_management_fee_waived'] = formats['closing_management_fee_waived'](
        basicFields['closing_management_fee_waived']()
      )
    }

    return payload
  }

  function onChangeSlug(slug: LeadGeneratedDocumentSlugs) {
    setPayload(initialPayload(slug))
  }

  function formatDateString(date: string) {
    return formatDate(date, 'extraLong')
  }

  function updateTotalPayoffAmount(payload: TLeadGeneratedDocumentPayload) {
    return formats['total_payoff_amount'](
      formatToNumber(payload['dynamic_payoff_equity_unlock_loan_amount']) +
        formatToNumber(payload['dynamic_payoff_program_fee']) +
        formatToNumber(payload['dynamic_payoff_inspection_fee']) +
        formatToNumber(payload['dynamic_payoff_program_fee_adjustment']) +
        formatToNumber(payload['dynamic_payoff_program_fee_discount']) +
        formatToNumber(payload['dynamic_payoff_inspection_fee_adjustment']) +
        formatToNumber(payload['closing_management_fee']) +
        formatToNumber(payload['late_listing_fee']) +
        formatToNumber(payload['dynamic_payoff_recording_fee']) +
        formatToNumber(payload['dynamic_payoff_di_taxes']) +
        formatToNumber(payload['dynamic_payoff_notary_fee']) +
        formatToNumber(payload['closing_management_fee_waived'])
    )
  }

  function updatePrincipleBalance(payload: TLeadGeneratedDocumentPayload) {
    return formats['dynamic_payoff_principle_balance'](
      formatToNumber(payload['dynamic_payoff_equity_unlock_loan_amount']) +
        formatToNumber(payload['dynamic_payoff_program_fee']) +
        formatToNumber(payload['dynamic_payoff_inspection_fee'])
    )
  }

  function onChangeAttribute(field: string, value: string | boolean) {
    // Prepare a new payload by copying the current state
    let updatedPayload = { ...payload }

    // If it's a boolean flag (final or conditional), cast it to a string (since payload expects string)
    if (field === 'final_eligibility_flag' && value) {
      updatedPayload = {
        ...updatedPayload,
        final_eligibility_flag: 'true',
        conditional_eligibility_flag: 'false' // Uncheck conditional if final is selected
      }
    } else if (field === 'conditional_eligibility_flag' && value) {
      updatedPayload = {
        ...updatedPayload,
        final_eligibility_flag: 'false',
        conditional_eligibility_flag: 'true' // Uncheck final if conditional is selected
      }
    } else if (field === 'closing_management_fee_waived_flag' && value) {
      updatedPayload = {
        ...updatedPayload,
        closing_management_fee_waived_flag: value.toString(),
        closing_management_fee_waived: formats['closing_management_fee_waived'](
          value.toString() === 'true'
            ? Math.abs(formatToNumber(payload['closing_management_fee'])) * -1
            : 0
        )
      }
    } else if (field === 'closing_management_fee' && value) {
      updatedPayload = {
        ...updatedPayload,
        closing_management_fee: value.toString(),
        closing_management_fee_waived: formats['closing_management_fee_waived'](
          payload['closing_management_fee_waived_flag'] === 'true' ? formatToNumber(value) * -1 : 0
        )
      }
    } else {
      // For non-boolean values, just set the field directly
      updatedPayload[field] = value.toString()
    }

    // Update title dynamically based on the eligibility flag
    const titleBase =
      updatedPayload.slug === 'dynamic-payoff-demand'
        ? 'Payoff Demand'
        : 'HomeLight Buy Before You Sell Agreement'
    let titleSuffix = ''

    if (updatedPayload.final_eligibility_flag === 'true') {
      titleSuffix = ' Final'
    } else if (updatedPayload.conditional_eligibility_flag === 'true') {
      titleSuffix = ' Conditional'
    }

    updatedPayload.title = `${titleBase}${titleSuffix} - ${lead?.fullAddress} - ${[
      lead?.bbysLead?.primaryClientNameOnTitle,
      lead?.bbysLead?.additionalClientNameOnTitle
    ]
      .filter(c => !!c)
      .join(' & ')}`

    if (
      [
        'dynamic_payoff_equity_unlock_loan_amount',
        'dynamic_payoff_program_fee',
        'dynamic_payoff_inspection_fee'
      ].includes(field) &&
      value
    ) {
      updatedPayload.dynamic_payoff_principle_balance = updatePrincipleBalance(updatedPayload)
    }
    updatedPayload.total_payoff_amount = updateTotalPayoffAmount(updatedPayload)

    // Update the payload in the state
    setPayload(updatedPayload)
  }

  async function onSubmit() {
    setIsLoading(true)
    for (var key in payload) {
      payload[key] = formats[key]
        ? formats[key](formatToNumber(payload[key]))
        : key.includes('date')
        ? formatDateString(payload[key].toString())
        : payload[key].toString()
    }
    generateDocument.mutate(payload, {
      onError: () => setIsLoading(false),
      onSuccess: () => {
        setIsLoading(false)
        setShowModal(false)
      }
    })
  }

  const config =
    generateDocumentSlugs.find(s => s.value === payload.slug) || generateDocumentSlugs[0]
  let isFormValid = true
  config.fields.forEach(field => {
    if (!config.optional.includes(field) && !payload[field]) {
      isFormValid = false
    }
  })
  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={() => setShowModal(true)}>
        Generate Document
      </Button>
      <ButtonForm
        buttonText="Generate Document"
        title="Generate Document"
        hideTriggerButton
        showForm={showModal}
        toggleShowForm={() => !isLoading && setShowModal(!showModal)}
        handleClose={() => !isLoading && setShowModal(false)}
        handleHover={() => {}}
        showError={false}
        error=""
        errorMessage=""
        errors={{}}
        isFormValid={isFormValid}
        isLoading={isLoading}
        onSubmit={onSubmit}
        resetForm={() => {}}
      >
        <FormField title="Choose a Template" htmlFor="slug" required>
          <Select
            id="slug"
            options={generateDocumentSlugs}
            value={payload.slug}
            onChange={val => onChangeSlug(val as LeadGeneratedDocumentSlugs)}
            addBlank
          />
        </FormField>
        {config.fields.map((field, i) => (
          <FormField
            key={field}
            title={capitalizeWords(field.split('_').join(' '))}
            htmlFor={`field-${field}`}
            required={!config.optional.includes(field)}
          >
            <>
              {field === 'warehouse_facility' && (
                <Select
                  value={payload[field]}
                  options={bbysLeadWarehouseOptions}
                  onChange={val => onChangeAttribute(field, val)}
                  addBlank
                />
              )}

              {field !== 'warehouse_facility' && field.includes('flag') && (
                <CheckBoxField
                  name={field}
                  checked={payload[field] === 'true'}
                  onChange={e => onChangeAttribute(field, e.target.checked.toString())}
                />
              )}

              {field !== 'warehouse_facility' && field.includes('date') && (
                <DateInput
                  id={field}
                  value={new Date(payload[field]).toISOString()}
                  onChange={val => onChangeAttribute(field, val)}
                />
              )}

              {field !== 'warehouse_facility' &&
                !field.includes('flag') &&
                !field.includes('date') && (
                  <TextInput
                    key={i}
                    id={`field-${field}`}
                    name={field}
                    value={payload[field]}
                    onChange={val => onChangeAttribute(field, val)}
                  />
                )}
            </>
          </FormField>
        ))}
      </ButtonForm>
    </>
  )
}
