// @ts-strict
import { ButtonForm } from 'components'
import { useProviderAgreementsController } from 'controllers'
import type { Agent } from 'models'
import { IssueButtonFormFields } from './IssueButtonFormFields'
import { useProviderAgreementButtonForm } from './useProviderAgreementButtonForm'

type Props = {
  agent: Agent
}

export const IssueButtonForm = ({ agent }: Props) => {
  const lastSignedAgreementId = agent.lastSignedAddendum?.agreement?.id!

  const { providerAgreementIssue } = useProviderAgreementsController(agent.id)
  const { fieldsProps, formProps } = useProviderAgreementButtonForm(
    providerAgreementIssue,
    lastSignedAgreementId
  )

  return (
    <ButtonForm buttonText="Issue" title="Issue Amendment" {...formProps}>
      <IssueButtonFormFields agent={agent} {...fieldsProps} />
    </ButtonForm>
  )
}
