import {
  ButtonForm,
  CreateButton,
  FormField,
  FormFieldSet,
  PhoneInput,
  Section,
  Sections,
  TButtonFormContentsProps,
  TButtonFormFormProps,
  TextInput
} from 'components'
import { useCreateLoanOfficerAssistant } from './hooks/useCreateLoanOfficerAssistant'
import { TLoanOfficerAssistantFormPayload, TLoanOfficerAssistantResponse } from './utils'

type Props = {
  onSuccess?: (response: TLoanOfficerAssistantResponse, params?: any) => void
} & Partial<TButtonFormFormProps & TButtonFormContentsProps>

type TProps = {
  isEditing?: boolean
  onChangeAttribute: <K extends keyof TLoanOfficerAssistantFormPayload>(
    key: K,
    value: TLoanOfficerAssistantFormPayload[K]
  ) => void
  payload: TLoanOfficerAssistantFormPayload
}

export const LoanOfficerAssistantFormFields = ({
  payload,
  onChangeAttribute,
  isEditing = false
}: TProps) => {
  return (
    <FormFieldSet>
      <Sections>
        <Section>
          {isEditing && (
            <FormField title="Email" htmlFor="loanOfficerAssistantEmail">
              {payload.loanOfficerAssistantEmail}
            </FormField>
          )}
          <FormField title="Name" htmlFor="loanOfficerAssistantName" required>
            <TextInput
              id="loanOfficerAssistantName"
              onChange={value => onChangeAttribute('loanOfficerAssistantName', value)}
              name="loanOfficerAssistantName"
              autoComplete="off"
              value={payload.loanOfficerAssistantName}
              data-testid="loan-officer-assistant-name-field"
            />
          </FormField>
          {!isEditing && (
            <FormField title="Email" htmlFor="loanOfficerAssistantEmail" required>
              <TextInput
                id="loanOfficerAssistantEmail"
                onChange={value => onChangeAttribute('loanOfficerAssistantEmail', value)}
                name="loanOfficerAssistantEmail"
                autoComplete="off"
                value={payload.loanOfficerAssistantEmail}
                data-testid="loan-officer-assistant-email-field"
              />
            </FormField>
          )}
          <FormField title="Phone Number" htmlFor="loanOfficerPhoneNumber" required>
            <PhoneInput
              id="loanOfficerAssistantPhoneNumber"
              onChange={value => onChangeAttribute('loanOfficerAssistantPhoneNumber', value)}
              name="loanOfficerAssistantPhoneNumber"
              autoComplete="off"
              value={payload.loanOfficerAssistantPhoneNumber}
              data-testid="loan-officer-assistant-phone-number-field"
            />
          </FormField>
        </Section>
      </Sections>
    </FormFieldSet>
  )
}

export const CreateLoanOfficerAssistantButtonForm = ({ onSuccess, ...props }: Props) => {
  const { formProps, fieldsProps } = useCreateLoanOfficerAssistant({ onSuccess })

  return (
    <ButtonForm
      title="Create Loan Officer Assistant"
      {...formProps}
      isFormValid={formProps.isFormValid}
      buttonComponent={CreateButton}
      {...props}
    >
      <LoanOfficerAssistantFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
