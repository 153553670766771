import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@foundation/components'
import { Block, If, List, TableCell as Td, TableRow as Tr } from 'components'
import { AgentProviderLeadServicesOpportunitiesSelector } from 'content/AgentProviderLead'
import { useCurrentUserContext } from 'contexts'
import { useQueryClient } from 'hooks'
import { AgentLead } from 'models'
import { d, formatDate, formatPrice, formatTime, structureAddress } from 'utils'
import { VoidPickupHangUpModal } from './VoidPickupHangUpModal'

type AgentLeadsTableRowProps = {
  agentId: string
  agentLead: AgentLead
}

export const AgentLeadsTableRow = ({ agentId, agentLead }: AgentLeadsTableRowProps) => {
  const [openVoidAgentHangUpModal, setOpenVoidAgentHangUpModal] = useState(false)
  const [showActionButton, setShowActionButton] = useState(true)
  const { isAgentHangupActionAdmin } = useCurrentUserContext()
  const providerLead = agentLead.providerLead
  const pickupHangupDetected = agentLead.introTracking?.pickupHangupDetected || false
  const { street, cityState } = structureAddress(providerLead.lead.fullAddress)

  const queryCache = useQueryClient()
  const clearCache = () => queryCache.invalidateQueries(['agentLeads', agentLead.agent.id])

  return (
    <>
      <VoidPickupHangUpModal
        agentId={agentId}
        agentLeadId={agentLead.id}
        setIsOpen={setOpenVoidAgentHangUpModal}
        isOpen={openVoidAgentHangUpModal}
        sideEffect={() => setShowActionButton(false)}
        recordingUrl={agentLead.recordingUrl}
      />

      <Tr data-testid={`lead-id-${providerLead.id}`}>
        <Td data-testid="lead-business-unit">
          <Block
            title={<Link to={providerLead.lead.getPathname()}>{providerLead.lead.name}</Link>}
            subtitle={providerLead.lead.userType}
          />
        </Td>
        <Td>
          <List>
            {street}
            {cityState}
          </List>
        </Td>
        <Td>{formatPrice(providerLead.lead.price, 'short')}</Td>
        <Td>
          <AgentProviderLeadServicesOpportunitiesSelector
            agentProviderLead={providerLead}
            onChange={clearCache}
          />
        </Td>
        <Td>
          <Block
            title={d(providerLead.stage)}
            subtitle={
              <List>
                {agentLead.workedByAgent !== undefined ? (
                  <Link
                    to={agentLead.workedByAgent.getPathname()}
                  >{`Worked by ${agentLead.workedByAgent.name}`}</Link>
                ) : null}
              </List>
            }
          />
        </Td>
        <Td data-testid="cellStage">
          <Block title={d(providerLead.lead.furthestStage)} />
        </Td>
        <Td>
          <Block
            title={formatDate(providerLead.lead.lastStageUpdate)}
            subtitle={formatTime(providerLead.lead.lastStageUpdate)}
          />
        </Td>
        <Td>
          <Block
            title={formatDate(providerLead.introSentAt)}
            subtitle={formatTime(providerLead.introSentAt)}
          />
        </Td>
        <Td>
          <If test={pickupHangupDetected && showActionButton && isAgentHangupActionAdmin}>
            <Button
              size="xs"
              onClick={() => setOpenVoidAgentHangUpModal(!openVoidAgentHangUpModal)}
            >
              Agent hangup
            </Button>
          </If>
        </Td>
      </Tr>
    </>
  )
}
