import { useMutation, useQueryClient } from 'react-query'
import { useButtonForm } from 'components'
import { postLoanOfficerAssistant } from 'services/LoanOfficers/postLoanOfficer'
import { deserializeAsync, validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils'
import { TLoanOfficerAssistantFormPayload, TLoanOfficerAssistantResponse } from '../utils'

type UseCreateLoanOfficerAssistantOptions = {
  onSuccess?: (response: TLoanOfficerAssistantResponse, params?: any) => void
}

export const useCreateLoanOfficerAssistant = (options?: UseCreateLoanOfficerAssistantOptions) => {
  const queryCache = useQueryClient()

  const createLoanOfficerAssistant = useMutation((data: {}) => postLoanOfficerAssistant(data), {
    onSuccess: async (response, params) => {
      queryCache.invalidateQueries('loan-officer-assistants')

      if (options?.onSuccess) {
        const deserializedResponse = await deserializeAsync<TLoanOfficerAssistantResponse>(
          response.data
        )
        options.onSuccess(deserializedResponse, params)
      }
    }
  })

  const buttonForm = useButtonForm<TLoanOfficerAssistantFormPayload, string>({
    initialPayload: {
      loanOfficerAssistantEmail: '',
      loanOfficerAssistantName: '',
      loanOfficerAssistantPhoneNumber: ''
    },
    mutation: createLoanOfficerAssistant,
    formValidations: {
      loanOfficerAssistantEmail: validateEmailFormat,
      loanOfficerAssistantName: validatePresence,
      loanOfficerAssistantPhoneNumber: validatePhoneFormat
    }
  })

  return { ...buttonForm, isLoading: createLoanOfficerAssistant.isLoading }
}
