import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { useToastContext } from '@foundation/components'
import { deleteRequest, postRequest } from 'utils'
import { BASE_URL } from '../../constants/endpoints'
import { GET_PODS_QUERY_KEY } from '../../constants/query-keys'

type UpdatePodPayload = {
  deleted_lrms: string[]
  deleted_lsms: string[]
  new_lrms: string[]
  new_lsms: string[]
}

export const useUpdatePod = (options: UseMutationOptions<void, Error, UpdatePodPayload> = {}) => {
  const toast = useToastContext()
  const queryClient = useQueryClient()

  const getCreateLRMCalls = (ids: string[]) => {
    return ids.map(id => postRequest(`${BASE_URL}/create-lrm-user`, { user_id: id }))
  }

  const getDeleteLRMCalls = (ids: string[]) => {
    return ids.map(id => deleteRequest(`${BASE_URL}/remove-lrm-user?user_id=${id}`))
  }

  const getCreateLSMCalls = (ids: string[]) => {
    return ids.map(id => postRequest(BASE_URL, { lsm_user_id: id }))
  }

  const getDeleteLSMCalls = (ids: string[]) => {
    return ids.map(id => deleteRequest(`${BASE_URL}/${id}`))
  }

  return useMutation(
    async (payload: UpdatePodPayload) => {
      const { deleted_lrms, deleted_lsms, new_lrms, new_lsms } = payload

      await Promise.all([
        ...(deleted_lrms.length > 0 ? getDeleteLRMCalls(deleted_lrms) : []),
        ...(deleted_lsms.length > 0 ? getDeleteLSMCalls(deleted_lsms) : []),
        ...(new_lrms.length > 0 ? getCreateLRMCalls(new_lrms) : []),
        ...(new_lsms.length > 0 ? getCreateLSMCalls(new_lsms) : [])
      ])
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: [GET_PODS_QUERY_KEY] })
        options?.onSuccess?.(...args)
      },
      onError: (error, ...args) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error'
        })
        options?.onError?.(error, ...args)
      }
    }
  )
}
