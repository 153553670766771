/* eslint-disable typescript-sort-keys/interface */

import styled, { css } from 'styled-components'

// ========================================================================
// Table.Root

export const TableRootStyles = styled.div`
  max-width: 100%;
`

// ========================================================================
// Table.View

export const TableViewStyles = styled.div`
  border: 1px solid #dbdfe6;
  border-radius: 8px;
`

// ========================================================================
// Table.Heading

export const TableHeadingStyles = styled.div`
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dbdfe6;

  h1 {
    margin-bottom: 4px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #72757d;
    line-height: 150%;
  }
`

// ========================================================================
// Table.Actions

export const TableActionsStyles = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dbdfe6;
`

export const AppliedFilterItem = styled.div`
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #55585e;
  border-radius: 4px;
  background-color: #f5f6f9;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

export const ClearButton = styled.button`
  font-size: 14px;
  color: #1192e5;
  border: none;
  background-color: transparent;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

// ========================================================================
// Table.Data

export const TableEmptyContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 242px;
  }

  p {
    font-weight: 700;
    color: #72757d;
  }
`

export const TableRowSkeleton = styled.div<{ width?: string }>`
  ${({ width = '100%' }) => css`
    width: ${width};
    height: 20px;
    animation: skeleton-loading 1s linear infinite alternate;

    @keyframes skeleton-loading {
      0% {
        background-color: #f5f6f9;
      }
      100% {
        background-color: #ecedf0;
      }
    }
  `}
`

export const SortArrowStyles = styled.div<{ isActive?: boolean; isDesc?: boolean }>`
  ${({ isActive, isDesc }) => css`
    ${isDesc ? 'margin-bottom: 5px;' : 'margin-top: 5px;'}
    color: ${isActive ? '#3A3C40' : '#C5C8CD'};
    transform: rotate(${isDesc ? '180deg' : '0deg'});
    transition: all 200ms ease-in-out;
  `}
`

export const TableStyles = styled.div`
  overflow-x: auto;

  table {
    position: relative;
    border-collapse: separate;
    table-layout: fixed;

    .fixed-column {
      position: sticky;
      left: 0; /* This will be overridden by JS */
      z-index: 1;
    }
  }
`

export const TH = styled.th<{
  isSortable?: boolean
  isActive?: boolean
  minSize?: string
  isLastFrozenCol?: boolean
  flexGrow?: boolean
}>`
  ${({ isSortable, isActive, minSize = '150px', isLastFrozenCol, flexGrow = false }) => css`
    min-width: ${minSize};
    padding: 12px;
    color: #55585e;
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
    background-color: ${isActive ? '#EEF0F6' : '#F8F8FB'};
    border-bottom: 1px solid #eef0f6;
    ${isLastFrozenCol && 'border-right: 1px solid #eef0f6;'}
    cursor: ${isSortable ? 'pointer' : 'default'};
    transition: background 200ms ease-in-out;
    position: relative;
    width: ${flexGrow ? '100%' : 'auto'};

    &:hover {
      background-color: #eef0f6;
    }

    &:first-child {
      padding-left: 24px;
    }
  `}
`

export const TD = styled.td<{ isActive?: boolean; isLastFrozenCol?: boolean }>`
  ${({ isActive, isLastFrozenCol }) => css`
    padding: 12px;
    color: ${isActive ? '#273653' : '#72757D'};
    vertical-align: top;
    background: white;
    border-bottom: 1px solid #eef0f6;
    position: relative;
    ${isLastFrozenCol && 'border-right: 1px solid #eef0f6;'}

    &:first-child {
      padding-left: 24px;
    }
  `}
`

// ========================================================================
// Table.Details

export const TableDetailsStyles = styled.div`
  padding: 12px 24px 16px 24px;
  font-size: 14px;
  font-weight: 600;

  span {
    color: #8e929c;
  }
`

// ========================================================================
// Table.Pagination

export const TablePaginationStyles = styled.div`
  padding: 16px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const PaginationButtonIcon = styled.button<{ disabled?: boolean; left?: boolean }>`
  ${({ disabled, left }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${left && 'transform: rotate(180deg);'}
    border: none;
    border-radius: 50%;
    background-color: white;
    color: ${disabled ? '#C5C8CD' : '#273653'};
    transition: background-color 200ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #eef0f6;
    }

    &:disabled:hover {
      background-color: white;
      cursor: default;
    }
  `}
`

export const TablePaginationButton = styled.button<{ isActive?: boolean }>`
  ${({ isActive }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    color: ${isActive ? 'white' : '#273653'};
    background-color: ${isActive ? '#46B6FF' : 'white'};
    cursor: pointer;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: ${isActive ? '#46B6FF' : '#EEF0F6'};
    }

    &:disabled:hover {
      cursor: default;
    }
  `}
`
