// @ts-strict
import { Model } from 'models/Model'
import { BulkEquityUnlockCalculationAttributes } from 'schema/BulkEquityUnlockCalculationSchema'

export class BulkEquityUnlockCalculation extends Model {
  createdAt: string
  id: string
  inputFileDownloadUrl?: string
  inputFileId: string
  originalInputFileName?: string
  partnerId: number
  result: string
  resultFileDownloadUrl?: string
  resultFileId?: string
  updatedAt: string
  userEmail: string
  userId: number

  constructor(attributes: BulkEquityUnlockCalculationAttributes) {
    super()
    this.createdAt = attributes.createdAt
    this.id = attributes.id
    this.inputFileDownloadUrl = attributes.inputFileDownloadUrl
    this.inputFileId = attributes.inputFileId
    this.originalInputFileName = attributes.originalInputFileName
    this.partnerId = attributes.partnerId
    this.result = attributes.result
    this.resultFileDownloadUrl = attributes.resultFileDownloadUrl
    this.resultFileId = attributes.resultFileId
    this.updatedAt = attributes.updatedAt
    this.userEmail = attributes.userEmail
    this.userId = attributes.userId
  }

  static create(attributes: BulkEquityUnlockCalculationAttributes): BulkEquityUnlockCalculation {
    return new BulkEquityUnlockCalculation(attributes)
  }
}
