// @ts-strict
import { AgreementResponse, PplProviderAgreementResponse } from 'schema'
import { Model } from '../Model'

export class PplProviderAgreement extends Model {
  id?: string
  agreement?: AgreementResponse
  acceptedAt?: string
  status?: string
  createdAt?: string
  updatedAt?: string
  voidedAt?: string
  storagePath?: string
  s3Path?: string
  signedAt?: string
  note?: string | null
  version?: string

  constructor(pplProviderAgreementAttributes: PplProviderAgreementResponse) {
    super()

    this.s3Path = pplProviderAgreementAttributes.storagePath
    this.signedAt = pplProviderAgreementAttributes.acceptedAt
    this.note = null

    Object.assign(this, pplProviderAgreementAttributes)
  }

  static create(
    pplProviderAgreementResponse: Potential<PplProviderAgreementResponse>
  ): Potential<PplProviderAgreementResponse> {
    if (!pplProviderAgreementResponse) {
      return
    }

    return new PplProviderAgreement(pplProviderAgreementResponse)
  }
}
