// @ts-strict

import { useState } from 'react'
import { useQuery } from 'react-query'
import { Box, Button, Dialog, Flex, Text } from '@foundation/components'
import {
  CheckBoxField,
  Table,
  TableHeaderRow,
  TableResults,
  TableCell as TD,
  TableHeaderColumn as TH,
  TableRow as TR
} from 'components'
import { CCBBYSLead, GeneratedDocument } from 'models'
import { signGeneratedDocument } from 'services'
import { getApiRoot } from 'services/getUrl'
import { formatDate, getRequest } from 'utils'

type SnapshotFieldProps = {
  label: string
  value: string | undefined
}

const SnapshotField = ({ label, value = '-' }: SnapshotFieldProps) => (
  <Box padding="$4" backgroundColor="neutral1" borderRadius="md">
    <Flex justifyContent="space-between" alignItems="flex-start" gap="$8">
      <Text size="sm" whiteSpace="nowrap">
        {label}
      </Text>
      <Flex gap="$4" alignItems="flex-start">
        <Text size="sm" textAlign="right" weight="bold">
          {value}
        </Text>
        <Button
          variant="ghost"
          iconRight="Copy"
          onClick={() => navigator.clipboard.writeText(value)}
        />
      </Flex>
    </Flex>
  </Box>
)

const TableHeader = () => {
  return (
    <TableHeaderRow>
      <TH name="Created At" />
      <TH name="Slug" />
      <TH name="Agreement Variables" />
      <TH name="Name" />
      <TH />
    </TableHeaderRow>
  )
}

type TProps = { generatedDocument: GeneratedDocument; lead: CCBBYSLead }

type SnapshotData = {
  administration_fee: string
  agent_valuation: string
  bbys_loan_amount: string
  client_1: string
  client_1_email: string
  client_2: string
  client_2_email: string
  closing_management_flag: 'true' | 'false'
  conditional_eligibility_flag: 'true' | 'false'
  cover_names: string
  date: string
  dr_address: string
  equity_boost: string
  equity_boost_election_flag: 'true' | 'false'
  eu_amount: string
  final_eligibility_flag: 'true' | 'false'
  inspection_cost: string
  lo_company_name: string
  loan_payoff_value: string
  maintenance_reserve_amount: string
  maintenance_reserve_flag: 'true' | 'false'
  no_buy_before_you_sell_loan_flag: 'true' | 'false'
  prmi_borrower_consent_flag: 'true' | 'false'
  program_fee: string
  program_fee_flag: 'true' | 'false'
  program_fee_percentage: string
  program_length: string
  property_conditions_and_repairs: string
  required_home_repairs_flag: 'true' | 'false'
  second_lien: string
  texas_flag: string
  title: string
  total_payoff_amount: string
  valid_date: string
}

const TableRow = ({ lead, generatedDocument }: TProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSnapshotDialogOpen, setIsSnapshotDialogOpen] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const {
    data: snapshot,
    isLoading,
    refetch
  } = useQuery<SnapshotData>(
    ['FETCH_SNAPSHOT', lead.bbysLead?.id, generatedDocument.id],
    async () => {
      const url = `${getApiRoot()}/lead-data-service/v2/bbys/${
        lead.bbysLead?.id
      }/generated-documents/${generatedDocument.id}/document-data`

      const res = await getRequest(url)
      return res.data
    },
    {
      onSuccess: data => {
        setIsSnapshotDialogOpen(true)
      },
      enabled: false
    }
  )

  async function onSignDocument() {
    if (!lead.bbysLead?.id || !generatedDocument.id) {
      return
    }
    setIsPreparing(true)
    try {
      const rsp = await signGeneratedDocument(lead.bbysLead?.id, generatedDocument.id)
      window.open(rsp.data.sender_view_url, '_blank')
      setPresignedUrl(rsp.data.sender_view_url)
    } catch (error: any) {
      setError(error.message)
    }
    setIsPreparing(false)
  }

  function onOpenChange(open: boolean) {
    setIsOpen(open)
    if (!open) {
      setIsPreparing(false)
      setPresignedUrl(null)
      setError(null)
    } else {
      onSignDocument()
    }
  }

  function toggleSnapshotDialog() {
    setIsSnapshotDialogOpen(prev => !prev)
  }

  const convertCamelCaseToNormalCase = (str: string) => {
    let formattedStr = str.replace(/Flag$/, '')
    formattedStr = formattedStr.replace(/([A-Z])/g, ' $1').trim()
    return formattedStr.replace(/\b\w/g, char => char.toUpperCase())
  }

  const agreementVariables =
    generatedDocument.params && typeof generatedDocument.params === 'object'
      ? Object.entries(generatedDocument.params)
          .filter(([key, value]) => key.endsWith('Flag') && value === 'true')
          .map(([key]) => convertCamelCaseToNormalCase(key))
          .join(', ')
      : ''

  return (
    <TR>
      <TD>{formatDate(generatedDocument.createdAt)}</TD>
      <TD>{generatedDocument.slug}</TD>
      <TD>{agreementVariables || ''}</TD>
      <TD>
        <a href={generatedDocument.url} target="_blank" rel="noreferrer noopener">
          {generatedDocument.name}
        </a>
      </TD>
      <TD align="right">
        <Flex flexDirection="column" gap="$2">
          <Dialog.Root isOpen={isOpen} onOpenChange={onOpenChange}>
            <Dialog.Trigger>
              <Button size="xs" variant="outline" color="primary">
                Sign Document
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <Dialog.Body>
                {isPreparing && <p>Preparing Docusign Envelope...</p>}
                {presignedUrl && (
                  <>
                    <p>Envelope is ready.</p>
                    <br />
                    <a
                      href={presignedUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => onOpenChange(false)}
                    >
                      Open in Docusign
                    </a>
                  </>
                )}
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </Dialog.Body>
            </Dialog.Content>
          </Dialog.Root>

          {generatedDocument.slug === 'conditional-document-formatting' && (
            <Dialog.Root
              isOpen={isSnapshotDialogOpen}
              onOpenChange={isOpen => {
                if (!isOpen) {
                  setIsSnapshotDialogOpen(false)
                  return
                }
                refetch()
              }}
            >
              <Dialog.Trigger>
                <Button
                  size="xs"
                  variant="outline"
                  color="neutralLight"
                  onClick={() => refetch()}
                  isLoading={isLoading}
                >
                  View Snapshot
                </Button>
              </Dialog.Trigger>
              <Dialog.Content>
                <Dialog.Body>
                  <Dialog.Header>Agreement Snapshot</Dialog.Header>

                  <Box maxWidth="600px" marginTop="$8">
                    <Flex flexDirection="column" gap="$8">
                      <Flex flexDirection="column" gap="$2">
                        <SnapshotField label="Name" value={snapshot?.title} />
                        <SnapshotField label="Date" value={snapshot?.date} />
                        <SnapshotField label="Client 1" value={snapshot?.client_1} />
                        <SnapshotField label="Client 1 Email" value={snapshot?.client_1_email} />
                        <SnapshotField label="Client 2" value={snapshot?.client_2} />
                        <SnapshotField label="Client 2 Email" value={snapshot?.client_2_email} />
                        <SnapshotField label="Cover Names" value={snapshot?.cover_names} />
                        <SnapshotField label="DR Address" value={snapshot?.dr_address} />
                        <SnapshotField label="Valid Date" value={snapshot?.valid_date} />
                        <SnapshotField
                          label="BBYS Loan Amount"
                          value={snapshot?.bbys_loan_amount}
                        />
                        <SnapshotField
                          label="Closing management fee"
                          value={snapshot?.administration_fee}
                        />
                        <SnapshotField
                          label="Maintenance reserve"
                          value={snapshot?.maintenance_reserve_amount}
                        />
                        <SnapshotField label="Program fee" value={snapshot?.program_fee} />
                        <SnapshotField label="EU Amount" value={snapshot?.eu_amount} />
                        <SnapshotField label="Equity Boost" value={snapshot?.equity_boost} />
                        <SnapshotField label="Second Lien" value={snapshot?.second_lien} />
                        <SnapshotField label="Program Length" value={snapshot?.program_length} />
                        <SnapshotField
                          label="Program Fee Percentage"
                          value={snapshot?.program_fee_percentage}
                        />
                        <SnapshotField label="Inspection Cost" value={snapshot?.inspection_cost} />
                        <SnapshotField label="Agent Valuation" value={snapshot?.agent_valuation} />
                        <SnapshotField
                          label="Loan Payoff Value"
                          value={snapshot?.loan_payoff_value}
                        />
                        <SnapshotField
                          label="Property Conditions and Repairs"
                          value={snapshot?.property_conditions_and_repairs}
                        />
                        <SnapshotField label="LO Company Name" value={snapshot?.lo_company_name} />

                        <CheckBoxField
                          title="Final Eligibility Flag"
                          checked={snapshot?.final_eligibility_flag === 'true'}
                          disabled
                        />
                        <CheckBoxField
                          title="Conditional Eligibility Flag"
                          checked={snapshot?.conditional_eligibility_flag === 'true'}
                          disabled
                        />
                        <CheckBoxField
                          title="Required Home Repairs Flag"
                          checked={snapshot?.required_home_repairs_flag === 'true'}
                          disabled
                        />
                        <CheckBoxField
                          title="Closing Management Flag"
                          checked={snapshot?.closing_management_flag === 'true'}
                          disabled
                        />
                        <CheckBoxField
                          title="Program Fee Flag"
                          checked={snapshot?.program_fee_flag === 'true'}
                          disabled
                        />
                      </Flex>

                      <Button onClick={toggleSnapshotDialog}>Close</Button>
                    </Flex>
                  </Box>
                </Dialog.Body>
              </Dialog.Content>
            </Dialog.Root>
          )}
        </Flex>
      </TD>
    </TR>
  )
}

export const GeneratedDocumentsTable = ({
  lead,
  generatedDocuments = []
}: {
  generatedDocuments?: GeneratedDocument[]
  lead: CCBBYSLead
}) => {
  return (
    <Table data-testid="generated-documents-table">
      <TableHeader />
      <TableResults type="GeneratedDocuments">
        {generatedDocuments?.map(generatedDocument => (
          <TableRow key={generatedDocument.id} lead={lead} generatedDocument={generatedDocument} />
        ))}
      </TableResults>
    </Table>
  )
}
