import { removeAttributesWithNullValues } from 'utils'

export type OrderLeadFeedParams = {
  orderId: string
  page?: number
  perPage?: number
}

export const getSearchParams = ({ page, perPage }: OrderLeadFeedParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined
  })
}
