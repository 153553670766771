// @ts-strict

import { Button, IButtonProps } from '..'
import { cx } from '../../../utils/cx/cx'
import styles from './RowButton.module.scss'

type Props = {
  inverted?: boolean
} & Pick<IButtonProps, 'onClick' | 'disabled' | 'children' | 'as'>

export const RowButton = ({ children, inverted, ...props }: Props) => {
  const type = inverted ? 'rowInvertedButton' : 'rowButton'

  return (
    <Button data-testid={type} as="action" {...props} className={cx(styles.rowBase, styles[type])}>
      {children}
    </Button>
  )
}
