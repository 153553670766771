import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { useToastContext } from '@foundation/components'
import { deleteRequest } from 'utils'
import { BASE_URL } from '../../constants/endpoints'
import { GET_PODS_QUERY_KEY } from '../../constants/query-keys'

type RemoveLRMPayload = {
  lrmId: string
  podId: string
}

export const useRemoveLRM = (options: UseMutationOptions<void, Error, RemoveLRMPayload> = {}) => {
  const toast = useToastContext()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ podId, lrmId }: RemoveLRMPayload) => {
      await deleteRequest(`${BASE_URL}/${podId}/remove-lrm?lrm_user_id=${lrmId}`)
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: [GET_PODS_QUERY_KEY] })
        options.onSuccess?.(...args)
      },
      onError: (error, ...args) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error'
        })
        options?.onError?.(error, ...args)
      },
      ...options
    }
  )
}
