import { useQuery } from 'hooks'
import { Order } from 'models'
import { OrderSchema } from 'schema'
import { fetchLeadsCount, fetchOrder } from 'services'
import { ORDER_CACHE_KEY, SIMILAR_LEADS_COUNT_CACHE_KEY } from '../index'

const orderLeadNavigationShape: OrderSchema = {
  order: [
    'ownedBusinessUnit',
    'source',
    'createdAt',
    'stage',
    {
      leads: [
        'userType',
        'price',
        'fullAddress',
        'stage',
        'furthestStage',
        'createdAt',
        'originalCreatedAt',
        'name',
        'email',
        'emailAlt',
        'phoneAlt',
        'partnerName',
        'secondaryUserType',
        {
          'referringOwner:agent': [
            'email1',
            'firstName',
            'lastName',
            'licenseNumber',
            { agentTeam: ['name'] },
            { agentTeamMemberships: [{ agentTeam: ['name'] }] },
            { phoneNumbers: ['number', 'phoneType'] }
          ]
        },
        {
          'agentProviderLeads:providerLead': [
            'stage',
            {
              'providable:agentLead': [
                {
                  agent: [
                    'firstName',
                    'lastName',
                    'pictureThumbUrl',
                    'officeDisplayName',
                    { state: ['code'] }
                  ]
                }
              ]
            }
          ]
        },
        {
          'tradeInProviderLead:providerLead': [
            {
              'providable:tradeInLead': [
                'simpleSale',
                'dpTargetNewHomePurchaseEquity',
                'dpActualNewHomePurchaseEquityHl',
                'incomingPropertyCloseOfEscrowAt',
                'departingPropertyCloseOfPurchaseEscrowAt',
                'departingPropertyCloseOfSaleEscrowAt'
              ]
            }
          ]
        },
        {
          phoneNumbers: ['number']
        }
      ]
    }
  ]
}

export const useOrderController = (orderId?: string) => {
  const orderQuery = useQuery<Order>(
    [ORDER_CACHE_KEY, orderId],
    () => fetchOrder(orderId!, orderLeadNavigationShape),
    { enabled: !!orderId, refetchOnWindowFocus: false }
  )

  const similarLeadsQuery = useQuery(
    [SIMILAR_LEADS_COUNT_CACHE_KEY, orderId],
    () =>
      fetchLeadsCount({
        similarOrder: orderId
      }),
    { enabled: !!orderId }
  )

  return {
    orderQuery,
    similarLeadsQuery
  }
}
