// @ts-strict

import {
  LEADS_CACHE_KEY,
  ORDER_CACHE_KEY,
  ORDERS_CACHE_KEY,
  useMutation,
  useQueryClient
} from 'hooks'
import { putBBYSLead } from 'services/BBYSLead/putBBYSLead'

export const useBBYSLeadController = (bbysLeadId?: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries(LEADS_CACHE_KEY)
    queryCache.invalidateQueries(ORDER_CACHE_KEY)
    queryCache.invalidateQueries(ORDERS_CACHE_KEY)
  }

  const updateBBYSLead = useMutation(
    (payload: Record<string, string>) => putBBYSLead(bbysLeadId, payload),
    { onSuccess: clearCaches }
  )

  return { updateBBYSLead, clearCaches }
}
