// @ts-strict
import { useEffect } from 'react'
import flagsmith from 'flagsmith'
import { useTokenContext } from 'contexts'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import {
  fetchCurrentUser,
  fetchCurrentUserQueue,
  fetchLeadsCount,
  putCurrentUserAvailability
} from 'services'
// import { useAssigneeTaskCountsController } from '../AssigneeTaskCounts'
import { CURRENT_USER_CACHE_KEY, CURRENT_USER_LEADS_COUNT_CACHE_KEY } from '../index'

export const useCurrentUserController = () => {
  const { token } = useTokenContext()
  const queryCache = useQueryClient()

  const { data: currentUser } = useQuery(CURRENT_USER_CACHE_KEY, fetchCurrentUser, {
    enabled: !!token,
    refetchOnWindowFocus: false
  })

  const useQueryCounterOptions = {
    enabled: !!currentUser && !!token,
    refetchOnWindowFocus: false
  }

  const { data: leadsCount } = useQuery(
    CURRENT_USER_LEADS_COUNT_CACHE_KEY,
    () =>
      fetchLeadsCount({
        needsListingReview: true,
        aeId: currentUser?.id
      }),
    useQueryCounterOptions
  )

  const { data: currentUserQueues } = useQuery(
    'current-user-queues',
    fetchCurrentUserQueue,
    useQueryCounterOptions
  )

  // const { activeTasksCount, pastDueTasksCount, readyTasksCount } = useAssigneeTaskCountsController({
  //   userId: currentUser?.id
  // })

  useEffect(() => {
    if (currentUser) {
      flagsmith.identify(currentUser.id)
    }
  }, [currentUser])

  const toggleAvailability = useMutation(
    () => putCurrentUserAvailability(!currentUser?.available),
    {
      onSuccess: () => queryCache.invalidateQueries(CURRENT_USER_CACHE_KEY)
    }
  )

  const aeId =
    currentUser && currentUser.roles?.agentAe && !currentUser.roles?.salesAppAdmin
      ? currentUser.id
      : undefined

  const roles = currentUser?.roles

  const isAgent = Boolean(roles?.agent)
  const isAgentAdmin = Boolean(roles?.agentAdmin)
  const isAgentActionAdmin = Boolean(roles?.agentActionAdmin)
  const isAgentHangupActionAdmin = Boolean(roles?.agentHangupActionAdmin)
  const isBlindIntroAdmin = roles?.blindIntroManual
  const isDev = Boolean(roles?.salesAppDev)
  const isEliteStatusAdmin = Boolean(roles?.salesEliteStatusAdmin)
  const isEscrowManager = roles?.escrowManager
  const isHandMatchingAdmin = Boolean(roles?.handMatchingAdmin)
  const isLeadCreationAdmin = Boolean(roles?.leadCreationAdmin)
  const isPplAdmin = roles?.salesPplAdmin
  const isSalesAppAdmin = roles?.salesAppAdmin
  const isSuperUser = Boolean(roles?.superUser)

  const canImpersonateUser = Boolean(roles?.salesImpersonationAdmin)
  const canEnableLeadRouting = Boolean(roles?.superUser || roles?.salesAppAdmin)
  const canEnableLeadBoost = Boolean(roles?.agentPreferencesManager)
  const canSeePartnerships = Boolean(roles?.salesAppAdmin || roles?.ccAppAdmin || roles?.superUser)
  const isLenderRelationshipManager = Boolean(
    currentUser?.teamMemberships?.some(tm => tm.role === 'lender_relationship_manager')
  )
  const isContractAdvisor = Boolean(
    currentUser?.teamMemberships?.some(tm => tm.role === 'contract_advisor')
  )
  const isProcessor = Boolean(currentUser?.teamMemberships?.some(tm => tm.role === 'processor'))
  const isTransactionSpecialist = Boolean(
    currentUser?.teamMemberships?.some(tm => tm.role === 'transaction_specialist')
  )
  const isListingOperationsSpecialist = Boolean(
    currentUser?.teamMemberships?.some(tm => tm.role === 'listing_operations_specialist')
  )

  return {
    activeTasksCount: 0,
    aeId,
    currentUser,
    currentUserQueues,
    isDev,
    isAgent,
    isAgentActionAdmin,
    isAgentAdmin,
    isAgentHangupActionAdmin,
    isEliteStatusAdmin,
    isEscrowManager,
    isHandMatchingAdmin,
    isPplAdmin,
    isBlindIntroAdmin,
    isSalesAppAdmin,
    isLeadCreationAdmin,
    isLenderRelationshipManager,
    isContractAdvisor,
    isProcessor,
    isTransactionSpecialist,
    isListingOperationsSpecialist,
    leadsCount,
    canImpersonateUser,
    pastDueTasksCount: 0,
    readyTasksCount: 0,
    toggleAvailability,
    canSeePartnerships,
    canEnableLeadRouting,
    canEnableLeadBoost,
    isSuperUser
  }
}
