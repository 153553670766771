import * as S from './styles'
import type { CommonProps } from './types'

export type TextProps = CommonProps

export const Text = ({
  children,
  as: asProp = 'p',
  color = 'primary10',
  hasEllipsis,
  hasLineThrough,
  hasUnderline,
  isItalic,
  size = 'base',
  transform,
  title,
  weight,
  'data-testid': dataTestId,
  ...props
}: TextProps) => {
  return (
    <S.Text
      data-testid={dataTestId}
      as={asProp}
      color={color}
      hasLineThrough={hasLineThrough}
      hasUnderline={hasUnderline}
      isItalic={isItalic}
      size={size}
      title={title}
      transform={transform}
      weight={weight}
      hasEllipsis={hasEllipsis}
      {...props}
    >
      {children}
    </S.Text>
  )
}
