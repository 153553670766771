// @ts-strict

import { useInfiniteQuery } from 'react-query'
import { UseFetchData } from 'controllers/types'
import { ORDER_CACHE_KEY, UseInfiniteQueryOptions } from 'hooks'
import { Order } from 'models'
import { OrderSchema } from 'schema'
import { fetchOrderLeadsFeed, TFetchItems } from 'services'
import { OrderLeadFeedParams } from 'services/Order/getSearchParams'
import { getNextPageParam } from 'utils'

type QueryOptions = UseInfiniteQueryOptions<
  TFetchItems<Order>,
  unknown,
  TFetchItems<Order>,
  TFetchItems<Order>,
  ReturnType<any>
>

const orderLeadNavigationShape: OrderSchema = {
  order: [
    'ownedBusinessUnit',
    'source',
    'createdAt',
    'stage',
    'totalLeads',
    {
      leads: [
        'userType',
        'price',
        'fullAddress',
        'stage',
        'furthestStage',
        'createdAt',
        'originalCreatedAt',
        'name',
        'email',
        'emailAlt',
        'phoneAlt',
        'partnerName',
        'secondaryUserType',
        {
          'referringOwner:agent': [
            'email1',
            'firstName',
            'lastName',
            'licenseNumber',
            { agentTeam: ['name'] },
            { agentTeamMemberships: [{ agentTeam: ['name'] }] },
            { phoneNumbers: ['number', 'phoneType'] }
          ]
        },
        {
          'agentProviderLeads:providerLead': [
            'stage',
            {
              'providable:agentLead': [
                {
                  agent: [
                    'firstName',
                    'lastName',
                    'pictureThumbUrl',
                    'officeDisplayName',
                    { state: ['code'] }
                  ]
                }
              ]
            }
          ]
        },
        {
          'tradeInProviderLead:providerLead': [
            {
              'providable:tradeInLead': [
                'simpleSale',
                'dpTargetNewHomePurchaseEquity',
                'dpActualNewHomePurchaseEquityHl',
                'incomingPropertyCloseOfEscrowAt',
                'departingPropertyCloseOfPurchaseEscrowAt',
                'departingPropertyCloseOfSaleEscrowAt'
              ]
            }
          ]
        },
        {
          phoneNumbers: ['number']
        }
      ]
    }
  ]
}

const DEFAULT_LEADS_FEED_SEARCH_PARAMS = {
  page: 1,
  perPage: 10
}

export const useOrderLeadsFeedController = ({
  searchParams
}: UseFetchData<OrderLeadFeedParams, QueryOptions>) => {
  if (!searchParams?.orderId) {
    throw new Error('orderId is required')
  }

  const orderId = searchParams.orderId!
  const orderLeadsSearchParams = {
    ...DEFAULT_LEADS_FEED_SEARCH_PARAMS,
    ...searchParams
  }

  const queryOptions = {
    getNextPageParam: getNextPageParam,
    refetchOnWindowFocus: false,
    staleTime: 300000 // 5min
  }

  const queryResult = useInfiniteQuery(
    [ORDER_CACHE_KEY, searchParams],
    ctx =>
      fetchOrderLeadsFeed(orderId, orderLeadNavigationShape, {
        ...orderLeadsSearchParams,
        page: ctx.pageParam || DEFAULT_LEADS_FEED_SEARCH_PARAMS.page
      }),
    queryOptions
  )

  return {
    ...queryResult,
    orders: queryResult?.data?.pages.flatMap(d => d.items) || [],
    totalPages: queryResult?.data?.pages[0].pageCount || 0
  }
}
