import { useState } from 'react'
import { useBrowserEvent } from '@foundation/hooks'
import { Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead'>

export const DRAgentInformationSection = ({ lead, updateBBYSLead }: Props) => {
  const [error, setError] = useState('')

  useBrowserEvent('SHOW_MISSING_DR_AGENT_ERROR', () => {
    window.scrollTo({ top: 4000, behavior: 'smooth' })
    setError('Required to request a contract')
  })

  const bbysLead = lead.bbysProviderLead.bbysLead
  const sellerAgent = bbysLead.departingPropertySellerAgent
  const buyerAgent = bbysLead.incomingPropertyBuyerAgent
  const leadType = lead.isHLSS ? 'hlss' : 'trade_in'

  return (
    <Sections title="Agent Information">
      <Section>
        <AgentSection
          title="Departing residence agent"
          agent={sellerAgent}
          program={leadType}
          error={error}
          onBlur={val => {
            return updateBBYSLead.mutate(
              {
                id: bbysLead.id,
                payload: {
                  departing_property_seller_agent_id: val
                }
              },
              {
                onSuccess: () => {
                  setError('')
                }
              }
            )
          }}
        />
      </Section>
      <Section>
        <AgentSection
          title="Incoming residence agent"
          agent={buyerAgent}
          program={leadType}
          onBlur={val =>
            updateBBYSLead.mutate({
              id: bbysLead.id,
              payload: {
                incoming_property_buyer_agent_id: val
              }
            })
          }
        />
      </Section>
    </Sections>
  )
}
