// @ts-strict
import { ButtonForm, Section } from 'components'
import { useProviderAgreementsController } from 'controllers'
import type { Agent } from 'models'
import styles from './VoidButtonForm.module.scss'
import { useProviderAgreementButtonForm } from './useProviderAgreementButtonForm'

type Props = {
  agent: Agent
}

export const VoidButtonForm = ({ agent }: Props) => {
  const lastPendingAgreementId = agent.lastPendingAddendum?.agreement?.id!

  const { providerAgreementVoid } = useProviderAgreementsController(agent.id)
  const { formProps } = useProviderAgreementButtonForm(
    providerAgreementVoid,
    lastPendingAgreementId
  )

  return (
    <ButtonForm buttonText="Void" title="Void Amendment" {...formProps}>
      <Section className={styles.section}>
        <span>
          If you proceed to void this amendment, this agent will be able to accept referrals again
        </span>
      </Section>
    </ButtonForm>
  )
}
