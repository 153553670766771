import { useState } from 'react'
import {
  ExternalLink,
  FormField,
  InlineEditAddressUnit,
  InlineEditIntegerInput,
  InlineEditSelect,
  InlineEditTextInput,
  InlineEditTypeaheadSelect,
  Section,
  Sections,
  YearSelect
} from 'components'
import { useLeadPropertyTypes, yesOrNoOptions } from 'lookups'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { AddPropertyConditionsAndRepairs } from './AddPropertyConditionsAndRepairs'
import styles from './GlobalStyles.module.scss'
import { getBBYSLeadInputProps, getLeadInputProps } from './utils'

type PropertyConditionScoreLabel =
  | 'Disrepair'
  | 'Poor'
  | 'Average'
  | 'Good'
  | 'Excellent'
  | 'Luxury'

type PropertyConditionScoreProps = {
  propertyCondition?: number
}

const PropertyConditionScore = ({ propertyCondition }: PropertyConditionScoreProps) => {
  if (!propertyCondition) {
    return <p>Property score unavailable</p>
  }

  const scoreMap: Record<number, PropertyConditionScoreLabel> = {
    6: 'Disrepair',
    5: 'Poor',
    4: 'Average',
    3: 'Good',
    2: 'Excellent',
    1: 'Luxury'
  }

  const scoreKey = Math.floor(Number(propertyCondition))
  const score = scoreMap[scoreKey]

  return (
    <p>
      {propertyCondition} ({score})
    </p>
  )
}

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const DRPropertyInformationSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const [errors, setErrors] = useState<Record<any, any>>({})

  const bbysLead = lead.bbysProviderLead.bbysLead
  const { leadPropertyTypes } = useLeadPropertyTypes()
  const { getProps, handleUpdateBBYSLead } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)
  const { getProps: getLeadProps } = getLeadInputProps(lead, updateLead)

  const updateError = (key, error) => {
    setErrors({
      ...errors,
      [key]: error
    })
  }

  return (
    <Sections title="Departing Residence">
      <Section>
        <FormField title="Property Address" inline>
          <InlineEditAddressUnit
            onBlur={val =>
              updateBBYSLead.mutate({
                id: bbysLead.id,
                payload: {
                  departing_property_full_address: val
                }
              })
            }
            value={bbysLead.departingPropertyFullAddress || lead.fullAddress}
          />
        </FormField>
        <FormField title="Property type" inline>
          <InlineEditSelect
            options={leadPropertyTypes}
            {...getLeadProps('propertyType')}
            readOnly={bbysLead?.meridianLinkSynced}
          />
        </FormField>
        <FormField title="HOA" inline>
          <InlineEditSelect
            {...getProps('departingPropertyHoaPresent')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
        <FormField title="Current Status" inline>
          <InlineEditIntegerInput {...getProps('currentDrMlsStatus')} maxLength={3} readOnly />
        </FormField>
        <FormField title="Expected Days On Market" inline>
          <InlineEditIntegerInput {...getProps('expectedDaysOnMarket')} maxLength={3} />
        </FormField>
        <FormField title="External Links" inline>
          <ExternalLink
            href={`https://www.google.com/search?q=${
              bbysLead.departingPropertyFullAddress || lead.fullAddress
            }`}
          >
            Google Address
          </ExternalLink>
          <ExternalLink
            href={`http://www.zillow.com/homes/${
              bbysLead.departingPropertyFullAddress || lead.fullAddress
            }_rb`}
          >
            Zillow Address
          </ExternalLink>
        </FormField>
      </Section>
      <Section>
        <FormField title="Solar Panels" inline>
          <InlineEditSelect
            {...getProps('departingPropertySolarPanelsPresent')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>

        <FormField title="Property condition score" inline>
          <div className={styles.row}>
            <PropertyConditionScore propertyCondition={bbysLead?.departingPropertyCondition} />
            <ExternalLink href={`${window.location.href}/documents/photos`}>
              See Photos
            </ExternalLink>
          </div>
        </FormField>
        <FormField title="Required Repairs Pre Funding" inline>
          <InlineEditSelect
            {...getProps('departingResidenceRequiredRepairsPreFunding')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
        <FormField title="Year Built" inline>
          <InlineEditTypeaheadSelect
            render={YearSelect}
            isError={!!errors.departingResidenceYearBuilt}
            value={bbysLead.departingResidenceYearBuilt}
            onBlur={(val: string) => {
              if (val.length !== 4) {
                updateError('departingResidenceYearBuilt', 'Invalid year')
              } else {
                updateError('departingResidenceYearBuilt', undefined)
                handleUpdateBBYSLead('departingResidenceYearBuilt', val)
              }
            }}
          />
        </FormField>
        <FormField title="Inspections required" inline>
          <InlineEditSelect {...getProps('inspectionsRequired')} options={yesOrNoOptions} />
        </FormField>
        <FormField title="Held in trust" inline>
          <InlineEditSelect
            {...getProps('departingResidenceHeldInTrust')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
        <FormField title="Name of trust" inline>
          <InlineEditTextInput {...getProps('nameOfTrust')} />
        </FormField>
        <FormField title="Property Conditions and Repairs" inline>
          <AddPropertyConditionsAndRepairs bbysLead={bbysLead} />
        </FormField>
      </Section>
    </Sections>
  )
}
