import { getLoanOfficerAssistantsUrl, getLoanOfficersUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'
import { requestTryCatchWrapper } from 'utils'

export const putLoanOfficer = (loanOfficerId: string | undefined, payload: {}) =>
  requestTryCatchWrapper(() =>
    putRequest(getLoanOfficersUrl({ id: loanOfficerId }), snakeCaseKeys(payload))
  )

export const putLoanOfficerAssistant = (loanOfficerAssistantId: string | undefined, payload: {}) =>
  requestTryCatchWrapper(() =>
    putRequest(getLoanOfficerAssistantsUrl({ id: loanOfficerAssistantId }), snakeCaseKeys(payload))
  )
