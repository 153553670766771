import { Link } from 'react-router-dom'
import { Popover } from '../../../../@foundation/components'
import { TColumn } from '../../../../@foundation/components/Table'
import styles from '../../../../@foundation/components/Table/Table.module.scss'
import { AgentInfo, BasicInfo } from '../../../../content/Lead'
import { Disposition } from '../../../../content/Lead/LeadDetails/CCBBYS/Disposition'
import { capitalizeWords, cx, dateDiffInDays, formatDate, formatMoney } from '../../../../utils'
import { StageTag } from '../../components/stage-tag'
import { getBBYSStage } from '../../helpers'
import { getDaysSinceFunding } from '../../helpers/get-days-since-funding'
import { BBYSLead } from '../../types'
import { Hoverable } from './styles'

export const BBYS_PIPELINE_VIEW_COLUMNS: (TColumn<BBYSLead> & {
  fields: Record<string, string | string[]>
})[] = [
  {
    label: 'Client',
    frozen: true,
    minSize: '200px',
    fields: {
      lead: ['name', 'email'],
      'lead.phone_numbers': 'number'
    },
    render: row => (
      <Popover.Root isHoverEnabled>
        <Popover.Trigger>
          <Link to={`/buy-before-you-sell/leads/${row.lead.id}`}>{row.lead.name}</Link>
        </Popover.Trigger>
        <Popover.Content>
          <BasicInfo
            title="Primary Client"
            name={row.lead?.name}
            email={row.lead?.email}
            phone={row.lead?.phoneNumbers?.[0]?.number}
          />
        </Popover.Content>
      </Popover.Root>
    )
  },
  {
    label: 'DR Address',
    minSize: '200px',
    frozen: true,
    fields: { bbys_lead_list: 'departing_property_full_address' },
    sortKey: 'full_address.keyword',
    sortType: 'text',
    render: row => capitalizeWords(row.departingPropertyFullAddress || '')
  },
  {
    label: 'DR State',
    sortKey: 'state_name.keyword',
    sortType: 'text',
    render: row => row.lead?.stateName,
    fields: { lead: 'state_name' },
    group: 'Lead Details'
  },
  {
    label: 'Date received',
    sortKey: 'created_at',
    sortType: 'date',
    render: row => formatDate(row.createdAt),
    fields: { bbys_lead_list: 'created_at' },
    group: 'Lead Details'
  },
  {
    label: 'DR Agent',
    render: row => {
      const agentLink = `/agents/${row.departingPropertySellerAgent?.id}`

      return (
        <Popover.Root isHoverEnabled>
          <Popover.Trigger>
            <Hoverable>
              {row.departingPropertySellerAgent?.firstName}{' '}
              {row.departingPropertySellerAgent?.lastName}
            </Hoverable>
          </Popover.Trigger>
          <Popover.Content>
            <AgentInfo
              title="DR Agent"
              name={row.departingPropertySellerAgent?.firstName}
              agentTeam={row.departingPropertySellerAgent?.agentTeam}
              email={row.departingPropertySellerAgent?.email1}
              phone={row.departingPropertySellerAgent?.phoneNumbers?.[0]?.number}
              license={row.departingPropertySellerAgent?.licenseNumber}
              brokerage={row.departingPropertySellerAgent?.brokerageBrandId}
              noteLink={row.departingPropertySellerAgent?.notes}
              agentLink={agentLink}
            />
          </Popover.Content>
        </Popover.Root>
      )
    },
    fields: {
      departing_property_seller_agent: [
        'id',
        'first_name',
        'last_name',
        'agent_team',
        'email1',
        'license_number',
        'brokerage_brand_id',
        'notes'
      ],
      'departing_property_seller_agent.phone_numbers': ['number']
    },
    group: 'Lead Details',
    sortKey: 'bbys.departing_property.seller_agent.full_name.keyword',
    sortType: 'text'
  },
  {
    label: 'Current stage',
    sortKey: 'stage.keyword',
    render: row => {
      const stage = row.lead?.stage
      const stageLabel = getBBYSStage(stage)

      return (
        <Disposition
          leadId={row.lead.id}
          leadStage={stage}
          customTrigger={
            <div>
              <StageTag stage={stageLabel} />
            </div>
          }
        />
      )
    },
    fields: { lead: 'stage' },
    group: 'Lead Details',
    sortType: 'stage'
  },
  {
    label: 'Furthest stage',
    sortKey: 'furthest_stage',
    render: row => getBBYSStage(row.lead?.furthestStage),
    fields: { lead: 'furthest_stage' },
    group: 'Lead Details',
    sortType: 'stage'
  },
  {
    label: 'Loan officer',
    sortKey: 'bbys.loan_officer.full_name.keyword',
    sortType: 'text',
    fields: {
      'lead.loan_officer': ['name', 'email'],
      'lead.loan_officer.phone_numbers': ['number']
    },
    render: row => (
      <Popover.Root isHoverEnabled>
        <Popover.Trigger>
          <Hoverable>{row.lead?.loanOfficer?.name}</Hoverable>
        </Popover.Trigger>
        <Popover.Content>
          <BasicInfo
            title="Loan Officer"
            name={row.lead?.loanOfficer?.name}
            email={row.lead?.loanOfficer?.email}
            phone={row.lead?.loanOfficer?.phoneNumbers[0]?.number}
          />
        </Popover.Content>
      </Popover.Root>
    ),
    group: 'Lead Details'
  },
  {
    label: 'Loan officer assistant',
    sortKey: 'loan_officer_assistant',
    sortType: 'text',
    fields: {
      'lead.lead_users': ['role'],
      'lead.lead_users.user': ['name', 'email'],
      'lead.lead_users.user.phone_numbers': ['number']
    },
    render: row => {
      const loanOfficerAssistant = row.lead?.leadUsers?.find(
        user => user.role === 'loan_officer_assistant'
      )
      return (
        loanOfficerAssistant && (
          <Popover.Root isHoverEnabled>
            <Popover.Trigger>
              <Hoverable>{loanOfficerAssistant?.user?.name}</Hoverable>
            </Popover.Trigger>
            <Popover.Content>
              <BasicInfo
                title="Loan Officer Assistant"
                name={loanOfficerAssistant?.user?.name}
                email={loanOfficerAssistant?.user?.email}
                phone={loanOfficerAssistant?.user?.phoneNumbers[0]?.number}
              />
            </Popover.Content>
          </Popover.Root>
        )
      )
    },
    group: 'Lead Details'
  },
  {
    label: 'Partner name',
    sortKey: 'bbys.partner_slug.keyword',
    sortType: 'text',
    render: row => row.sourcePartner?.slug,
    fields: { source_partner: 'slug' },
    group: 'Lead Details'
  },
  // Lead Details End
  // Departing Residence Start
  {
    label: 'Current status',
    sortKey: 'current_dr_mls_status',
    sortType: 'stage',
    render: row => row.currentDrMlsStatus,
    fields: { bbys_lead_list: 'current_dr_mls_status' },
    group: 'Departing Residence'
  },
  {
    label: 'Contingency removal only',
    sortKey: 'bbys.contingency_removal_only',
    sortType: 'text',
    render: row => (row.contingencyRemovalOnly ? 'Yes' : 'No'),
    fields: { bbys_lead_list: 'contingency_removal_only' },
    group: 'Departing Residence'
  },
  {
    label: 'Current list price',
    sortKey: 'bbys.departing_residence_current_list_price',
    sortType: 'number',
    render: row => formatMoney(row.departingResidenceCurrentListPrice),
    fields: { bbys_lead_list: 'departing_residence_current_list_price' },
    group: 'Departing Residence'
  },
  {
    label: 'Final sale price',
    sortKey: 'bbys.departing_residence_final_sale_price',
    sortType: 'number',
    render: row => formatMoney(row.departingResidenceFinalSalePrice),
    fields: { bbys_lead_list: 'departing_residence_final_sale_price' },
    group: 'Departing Residence'
  },
  {
    label: 'Held in trust',
    sortKey: 'bbys.departing_residence_held_in_trust',
    sortType: 'text',
    fields: { bbys_lead_list: 'departing_residence_held_in_trust' },
    render: row => (row.departingResidenceHeldInTrust ? 'Yes' : 'No'),
    group: 'Departing Residence'
  },
  {
    label: 'HLCS attached',
    sortKey: 'bbys.hlcs_attached',
    sortType: 'text',
    fields: { bbys_lead_list: 'hlcs_attached' },
    render: ({ hlcsAttached }) =>
      hlcsAttached === null || hlcsAttached === undefined ? '--' : hlcsAttached ? 'Yes' : 'No',
    group: 'Departing Residence'
  },
  {
    label: 'HOA',
    sortKey: 'bbys.departing_property_hoa_present',
    sortType: 'text',
    render: row => (row.departingPropertyHoaPresent ? 'Yes' : 'No'),
    fields: { bbys_lead_list: 'departing_property_hoa_present' },
    group: 'Departing Residence'
  },
  {
    label: 'Inspections required',
    sortKey: 'bbys.inspections_required',
    sortType: 'text',
    render: row => (row.inspectionsRequired ? 'Yes' : 'No'),
    fields: { bbys_lead_list: 'inspections_required' },
    group: 'Departing Residence'
  },
  {
    label: 'Original list price',
    sortKey: 'bbys.departing_residence_original_list_price',
    sortType: 'number',
    render: row => formatMoney(row.departingResidenceOriginalListPrice),
    fields: { bbys_lead_list: 'departing_residence_original_list_price' },
    group: 'Departing Residence'
  },
  {
    label: 'Solar panels',
    sortKey: 'bbys.departing_property_solar_panels_present',
    sortType: 'text',
    render: row => (row.departingPropertySolarPanelsPresent ? 'Yes' : 'No'),
    fields: { bbys_lead_list: 'departing_property_solar_panels_present' },
    group: 'Departing Residence'
  },
  {
    label: 'Target purchase COE date',
    sortKey: 'bbys.departing_property_target_purchase_coe_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'departing_property_target_purchase_coe_date' },
    render: row => formatDate(row.departingPropertyTargetPurchaseCoeDate),
    group: 'Departing Residence'
  },
  {
    label: 'DR Purchase Credit Facility',
    sortKey: 'bbys.capital_partner_name.keyword',
    sortType: 'text',
    fields: { capital_partner: 'name' },
    render: row => capitalizeWords(row.capitalPartner?.name || ''),
    group: 'Departing Residence'
  },
  // Departing Residence End
  // Equity Unlock Start
  {
    label: 'Approval type on intake',
    sortKey: 'bbys.approval_type_intake.keyword',
    sortType: 'text',
    render: row => capitalizeWords(row.approvalTypeIntake || ''),
    fields: { bbys_lead_list: 'approval_type_intake' },
    group: 'Equity Unlock'
  },
  {
    label: 'Days since EU funding',
    render: row => {
      const days = dateDiffInDays(formatDate(new Date(), 'yyyy-MM-dd'), row.loanFundingDate)

      return (
        <div
          className={cx(
            styles.daysSinceCell,
            days >= 60 && days <= 89 ? styles.yellow : '',
            days >= 90 && days <= 120 ? styles.orange : '',
            days > 120 ? styles.red : ''
          )}
        >
          {getDaysSinceFunding(row.loanFundingDate) || '-'}
        </div>
      )
    },
    fields: { bbys_lead_list: 'loan_funding_date' },
    sortKey: 'bbys.loan_funding_date',
    sortType: 'number',
    group: 'Equity Unlock'
  },
  {
    label: 'Days since IR COE',
    sortKey: 'bbys.incoming_residenced_expected_close_escrow_date',
    fields: { bbys_lead_list: 'incoming_residenced_expected_close_escrow_date' },
    render: row => {
      const days = dateDiffInDays(
        formatDate(new Date(), 'yyyy-MM-dd'),
        row.incomingResidencedExpectedCloseEscrowDate
      )

      return (
        <div
          className={cx(
            styles.daysSinceCell,
            days >= 60 && days <= 89 ? styles.yellow : '',
            days >= 90 && days <= 120 ? styles.orange : '',
            days > 120 ? styles.red : ''
          )}
        >
          {getDaysSinceFunding(row.incomingResidencedExpectedCloseEscrowDate) || '-'}
        </div>
      )
    },
    group: 'Equity Unlock',
    sortType: 'number'
  },
  {
    label: 'Equity boost',
    sortKey: 'bbys.equity_boost',
    sortType: 'text',
    fields: { bbys_lead_list: 'equity_boost' },
    render: row => (row.equityBoost ? 'Yes' : 'No'),
    group: 'Equity Unlock'
  },
  {
    label: 'Equity boost amount',
    sortKey: 'bbys.equity_boost_amount',
    sortType: 'number',
    fields: { bbys_lead_list: 'equity_boost_amount' },
    render: row => formatMoney(row.equityBoostAmount),
    group: 'Equity Unlock'
  },
  {
    label: 'Equity boost funded',
    sortKey: 'bbys.equity_boost_funded',
    sortType: 'number',
    fields: { bbys_lead_list: 'equity_boost_funded' },
    render: row => formatMoney(row.equityBoostFunded),
    group: 'Equity Unlock'
  },
  {
    label: 'Risk adjusted value',
    sortKey: 'bbys.risk_adjusted_value',
    sortType: 'number',
    fields: { bbys_lead_list: 'risk_adjusted_value' },
    render: row => formatMoney(row.riskAdjustedValue),
    group: 'Equity Unlock'
  },
  {
    label: 'Total equity unlock amount',
    sortKey: 'bbys.target_equity_unlock_amount',
    sortType: 'number',
    fields: { bbys_lead_list: 'target_equity_unlock_amount' },
    render: row => formatMoney(row.targetEquityUnlockAmount),
    group: 'Equity Unlock'
  },
  // Equity Unlock End
  // Repaper Start
  {
    label: 'Repaper started',
    sortKey: 'bbys.bbys_loans.repaper_started_at',
    fields: { bbys_loans: 'repaper_started_at' },
    render: row =>
      formatDate(
        row?.bbysLoans?.sort(
          (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
        )[0]?.repaperStartedAt
      ),
    group: 'Repaper',
    sortType: 'date'
  },
  {
    label: 'Repaper funded',
    sortKey: 'bbys.bbys_loans.repaper_funded_at',
    fields: { bbys_loans: 'repaper_funded_at' },
    render: row =>
      formatDate(
        row?.bbysLoans?.sort(
          (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
        )[0]?.repaperFundedAt
      ),
    group: 'Repaper',
    sortType: 'date'
  },
  {
    label: 'Extension sent',
    sortKey: 'bbys.bbys_extensions.sent_at',
    fields: { bbys_extensions: 'sent_at' },
    render: row =>
      formatDate(
        row?.bbysExtensions?.sort(
          (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
        )[0]?.sentAt
      ),
    group: 'Repaper',
    sortType: 'date'
  },
  {
    label: 'Extension signed',
    sortKey: 'bbys.bbys_extensions.signed_at',
    fields: { bbys_extensions: 'signed_at' },
    render: row =>
      formatDate(
        row?.bbysExtensions?.sort(
          (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
        )[0]?.signedAt
      ),
    group: 'Repaper',
    sortType: 'date'
  },
  {
    label: 'Extension fee',
    sortKey: 'bbys.bbys_extensions.fee_charged',
    fields: { bbys_extensions: 'fee_charged' },
    render: row =>
      formatMoney(
        row?.bbysExtensions?.sort(
          (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
        )[0]?.feeCharged
      ),
    group: 'Repaper',
    sortType: 'number'
  },
  {
    label: 'Extension expiration',
    sortKey: 'bbys.bbys_extensions.expires_at',
    fields: { bbys_extensions: 'expires_at' },
    render: row =>
      formatDate(
        row?.bbysExtensions?.sort(
          (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
        )[0]?.expiresAt
      ),
    group: 'Repaper',
    sortType: 'date'
  },
  {
    label: 'Days Until Extension expiration',
    sortKey: 'bbys.bbys_extensions.expires_at',
    fields: { bbys_extensions: 'expires_at' },
    render: row => {
      const expiry_date = row?.bbysExtensions?.sort(
        (a, b) => new Date(b?.createdAt || '').getTime() - new Date(a?.createdAt || '').getTime()
      )[0]?.expiresAt
      const days = dateDiffInDays(expiry_date, formatDate(new Date(), 'yyyy-MM-dd'))

      return (
        <div
          className={cx(
            styles.daysSinceCell,
            days >= 11 && days <= 30 ? styles.yellow : '',
            days <= 10 ? styles.red : ''
          )}
        >
          {days || '-'}
        </div>
      )
    },
    group: 'Repaper',
    sortType: 'number'
  },
  // Repaper End
  // Dates & Milestones Start
  {
    label: 'Agreement expires',
    sortKey: 'bbys.agreement_expires_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'agreement_expires_date' },
    render: row => formatDate(row.agreementExpiresDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Agreement signed',
    sortKey: 'bbys.final_agreement_signed_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'final_agreement_signed_date' },
    render: row => formatDate(row.finalAgreementSignedDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'IR expected COE',
    sortKey: 'bbys.departing_residence_expected_close_escrow_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'departing_residence_expected_close_escrow_date' },
    render: row => formatDate(row.departingResidenceExpectedCloseEscrowDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Inspection ordered',
    sortKey: 'bbys.inspection_ordered_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'inspection_ordered_date' },
    render: row => formatDate(row.inspectionOrderedDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Inspection scheduled',
    sortKey: 'bbys.inspection_scheduled_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'inspection_scheduled_date' },
    render: row => formatDate(row.inspectionScheduledDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Inspection complete',
    sortKey: 'bbys.inspection_complete_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'inspection_complete_date' },
    render: row => formatDate(row.inspectionCompleteDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Final agreement sent date',
    sortKey: 'bbys.final_agreement_sent_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'final_agreement_sent_date' },
    render: row => formatDate(row.finalAgreementSentDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Final agreement signed date',
    sortKey: 'bbys.final_agreement_signed_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'final_agreement_signed_date' },
    render: row => formatDate(row.finalAgreementSignedDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'DR backup contract sent',
    sortKey: 'bbys.departing_residence_backup_contract_sent_date',
    sortType: 'date',
    render: row => formatDate(row.departingResidenceBackupContractSentDate),
    fields: { bbys_lead_list: 'departing_residence_backup_contract_sent_date' },
    group: 'Dates & Milestones'
  },
  {
    label: 'DR backup contract signed',
    sortKey: 'bbys.departing_residence_backup_contract_signed_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'departing_residence_backup_contract_signed_date' },
    render: row => formatDate(row.departingResidenceBackupContractSignedDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Payoff requested',
    sortKey: 'bbys.payoff_requested_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'payoff_requested_date' },
    render: row => formatDate(row.payoffRequestedDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'Expected EU loan funded',
    fields: { bbys_lead_list: 'expected_loan_funding_date' },
    sortType: 'date',
    render: row => formatDate(row.expectedLoanFundingDate),
    sortKey: 'bbys.expected_loan_funding_date',
    group: 'Dates & Milestones'
  },
  {
    label: 'EU loan funded',
    sortKey: 'bbys.loan_funding_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'loan_funding_date' },
    render: row => formatDate(row.loanFundingDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'DR listed',
    sortKey: 'bbys.departing_residence_listed_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'departing_residence_listed_date' },
    render: row => formatDate(row.departingResidenceListedDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'DR in escrow',
    sortKey: 'bbys.departing_residence_in_escrow_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'departing_residence_in_escrow_date' },
    render: row => formatDate(row.departingResidenceInEscrowDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'DR expected COE',
    sortKey: 'bbys.departing_residence_expected_close_escrow_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'departing_residence_expected_close_escrow_date' },
    render: row => formatDate(row.departingResidenceExpectedCloseEscrowDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'HomeLight paid',
    sortKey: 'bbys.homelight_paid_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'homelight_paid_date' },
    render: row => formatDate(row.homelightPaidDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'HL purchased DR',
    sortKey: 'bbys.hl_purchased_departing_residence_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'hl_purchased_departing_residence_date' },
    render: row => formatDate(row.hlPurchasedDepartingResidenceDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'HL purchase listed',
    sortKey: 'bbys.hl_purchase_listed_date',
    sortType: 'date',
    render: row => formatDate(row.hlPurchaseListedDate),
    fields: { bbys_lead_list: 'hl_purchase_listed_date' },
    group: 'Dates & Milestones'
  },
  {
    label: 'HL sale in escrow',
    sortKey: 'bbys.hl_sale_in_escrow_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'hl_sale_in_escrow_date' },
    render: row => formatDate(row.hlSaleInEscrowDate),
    group: 'Dates & Milestones'
  },
  {
    label: 'HL sold DR',
    sortKey: 'bbys.hl_sold_departing_residence_date',
    sortType: 'date',
    fields: { bbys_lead_list: 'hl_sold_departing_residence_date' },
    render: row => formatDate(row.hlSoldDepartingResidenceDate),
    group: 'Dates & Milestones'
  }
  // Dates & Milestones End
].map(col => ({ ...col, display: true }))
