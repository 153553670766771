import { Table, TableResults } from 'components'
import { AgentLead } from 'models'
import styles from './AgentLeadsTable.module.scss'
import { AgentLeadsTableHeader } from './AgentLeadsTableHeader'
import { AgentLeadsTableRow } from './AgentLeadsTableRow'

type AgentLeadsTableProps = {
  agentLeads: AgentLead[]
  isLoading: boolean
}

export const AgentLeadsTable = ({ agentLeads, isLoading }: AgentLeadsTableProps) => {
  return (
    <Table className={styles.table}>
      <AgentLeadsTableHeader />
      <TableResults type="Referrals" colSpan={6} isLoading={isLoading}>
        {agentLeads?.map(agentLead => (
          <AgentLeadsTableRow
            key={agentLead.id}
            agentId={agentLead.agent?.id}
            agentLead={agentLead}
          />
        ))}
      </TableResults>
    </Table>
  )
}
