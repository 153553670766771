import { FormField, Section, Sections } from 'components'
import { LoanOfficerSection } from 'content/LoanOfficer/LoanOfficerSection/LoanOfficerSection'
import { dataOrDash, formatPhone } from 'utils'
import { LenderAutocomplete } from '../../../../components/InlineEdit/LenderAutocomplete/LenderAutocomplete'
import { useBBYSLeadController, useMutation, useQueryClient } from '../../../../hooks'
import { createLender } from '../../../../services/Lenders/createLender'
import { putLoanOfficer } from '../../../../services/LoanOfficers/putLoanOfficer'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>
type LoanOfficer = Props['lead']['bbysProviderLead']['bbysLead']['loanOfficer']

export const LoanOfficerInformationSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const loanOfficer = bbysLead?.loanOfficer || ({} as LoanOfficer)
  const queryCache = useQueryClient()
  const updateLoanOfficer = useMutation((data: {}) => putLoanOfficer(loanOfficer.id, data), {
    onSuccess: () => queryCache.invalidateQueries('loan-officers')
  })
  const createLenderMutation = useMutation((data: {}) => createLender(data))

  const { phoneNumber, phoneNumbers } = loanOfficer
  const phone = phoneNumber?.number || phoneNumbers?.[0]?.number
  const { clearCaches } = useBBYSLeadController()

  const handleOnCreate = async (name: string) => {
    const lender = await createLenderMutation.mutateAsync({ name: name })

    await updateLoanOfficer.mutateAsync({ loan_officer_lender_id: lender.data.data.id })

    clearCaches()
  }

  return (
    <Sections title="Loan Officer">
      <Section>
        <LoanOfficerSection
          title="Name"
          loanOfficer={loanOfficer}
          onBlur={val => updateLead({ loan_officer_id: val })}
        />
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          {dataOrDash(loanOfficer.email)}
        </FormField>

        <FormField title="Phone Number" inline>
          {dataOrDash(formatPhone(phone))}
        </FormField>
        <FormField title="Company Name" inline>
          {dataOrDash(loanOfficer.companyName)}
        </FormField>
        <FormField title="Lender Company" inline>
          <LenderAutocomplete
            onSelect={async lender => {
              await updateLoanOfficer.mutateAsync({ loan_officer_lender_id: lender.id })
              clearCaches()
            }}
            onCreate={handleOnCreate}
            value={loanOfficer?.lender?.name}
          />
        </FormField>
        <FormField title="Partner Slug" inline>
          {dataOrDash(bbysLead.sourcePartner?.slug)}
        </FormField>
      </Section>
    </Sections>
  )
}
