import { Button } from '@foundation/components'

const sampleFileContentWithSplitAddress = [
  ['address', 'city', 'state', 'zip', 'mortgage_balance'],
  ['"123 Main St"', 'Anytown', 'CA', '12345', '100000'],
  ['"456 Oak Ave"', 'Othercity', 'TX', '67890', '200000']
]

const sampleFileContentWithSingleAddressField = [
  ['exit_home_address', 'mortgage_balance'],
  ['"123 Main St, Anytown, CA 12345"', '100000'],
  ['"456 Oak Ave, Othercity, TX 67890"', '200000']
]

function downloadFile(dataSample: string[][], fileName: string) {
  const anchor = document.createElement('a')
  const data = dataSample.map(row => row.join(',')).join('\n')
  const file = new File([data], fileName, {
    type: 'text/csv'
  })
  const url = URL.createObjectURL(file)
  anchor.href = url
  anchor.download = fileName
  anchor.click()
}

export const SampleFileFormatDownload = () => {
  return (
    <div className="sample-files-container">
      <div className="sample-files-item">
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            downloadFile(
              sampleFileContentWithSingleAddressField,
              'sample-file-content-with-single-address-field.csv'
            )
          }}
        >
          Download sample file with "exit_home_address"
        </Button>
      </div>
      <div className="sample-files-item">
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            downloadFile(
              sampleFileContentWithSplitAddress,
              'sample-file-content-with-split-address.csv'
            )
          }}
        >
          Download sample file with split address fields
        </Button>
      </div>
    </div>
  )
}
