import styled from 'styled-components'
import { Coaster } from 'components/Coaster'
import { AutoCompleteListItem, AutoCompleteListItemProps } from './AutoCompleteListItem'

const ListAction = styled.div`
  padding: 8px;
  position: sticky;
  bottom: -1.5rem;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #e5e5e5;
`

type Props = {
  currentValue?: string
  focus: number | null
  items: AutoCompleteListItemProps['item'][]
  onClick: AutoCompleteListItemProps['onClick']
  onListActionClick?: (value: string) => void
  renderListAction?: (value?: string, callback?: () => void) => React.ReactNode
}

export const AutoCompleteList = ({
  items,
  onClick,
  focus,
  renderListAction,
  currentValue,
  onListActionClick = () => {}
}: Props) => {
  const listActionItem = renderListAction ? [{ id: 'listAction' }] : []
  const itemsToRender = [...items, ...listActionItem]

  if (!itemsToRender.length) {
    return null
  }
  return (
    <Coaster>
      <ul data-testid="autocomplete-list" className="autoCompleteList" style={{ width: '100%' }}>
        {itemsToRender?.map((item, index) => {
          if (item.id === 'listAction') {
            return (
              <ListAction
                onMouseDown={e => {
                  e.preventDefault()
                  onClick?.(item)
                }}
              >
                {renderListAction(currentValue, () => onListActionClick(currentValue))}
              </ListAction>
            )
          }
          return (
            <AutoCompleteListItem
              active={focus === index}
              key={item.id}
              item={item}
              onClick={onClick}
            />
          )
        })}
      </ul>
    </Coaster>
  )
}
