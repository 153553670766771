import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 0 16px;
`

export const TableStyles = styled.table`
  width: 100%;
  min-width: 600px;
  border-top: 1px solid #dbdfe6;
  border-bottom: 1px solid #dbdfe6;
  border-collapse: collapse;

  th {
    min-width: 200px;
    padding: 12px;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
  }

  td {
    padding: 12px;
    font-size: 14px;
    text-align: left;
  }

  th,
  tr:not(:last-child) td {
    border-bottom: 1px solid #eef0f6;
  }

  th.primary-orange {
    background-color: #ffecd1;
  }

  th.primary-gray {
    background-color: #eef0f6;
  }

  th.secondary-blue {
    background-color: #f2f9fe;
  }

  th.secondary-orange {
    color: #55585e;
    background-color: #fff6eb;
  }

  th.secondary-gray {
    color: #55585e;
    background-color: #f8f8fb;
  }
`

export const TableHeading = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dbdfe6;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
`

export const TableFooter = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dbdfe6;
  border-radius: 0 0 8px 8px;
  border-top: none;
`

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  border-right: 1px solid #dbdfe6;
  border-left: 1px solid #dbdfe6;
`
