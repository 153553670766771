import { useState } from 'react'
import { Button, Popover } from '@foundation/components'
import { CheckBoxField, Spinner } from 'components'
import { PodsColumnsControl } from '../../components/pods-columns-control'
import { useAddLRM } from '../../hooks/use-add-lrm'
import { usePods } from '../../hooks/use-pods'
import { useRemoveLRM } from '../../hooks/use-remove-lrm'
import { LRM, LSM } from '../../types'
import { Container, TableContainer, TableFooter, TableHeading, TableStyles } from './styles'

export const All = () => {
  const [isEditTableOpen, setIsEditTableOpen] = useState(false)
  const [pendingData, setPendingData] = useState<{ lrmId: string; podId: string } | null>(null)

  const { data: pods } = usePods()

  const { mutate: addLRM } = useAddLRM({
    onSettled: () => {
      setPendingData(null)
    }
  })

  const { mutate: removeLRM } = useRemoveLRM({
    onSettled: () => setPendingData(null)
  })

  const getIsChecked = (lrm: LRM, lsm: LSM) => {
    return lsm.attributes.lrm_membership_users
      .map(lrm => lrm.user_id)
      .join(' ')
      .includes(lrm.id)
  }

  const onCheckboxChange =
    (podId: string, lrmId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPendingData({ podId, lrmId })

      if (e.target.checked) {
        addLRM({ podId, lrmId })
      } else {
        removeLRM({ podId, lrmId })
      }
    }

  if (!pods) {
    return <></>
  }

  const { lsms, special_partners } = pods.data
  const { available_lrm_users } = pods.meta

  return (
    <Container>
      <TableHeading>
        <h3>Buy Before You Sell Lead routing</h3>
        <Popover.Root
          isOpen={isEditTableOpen}
          onOpenChange={open => setIsEditTableOpen(open)}
          placement="bottom-end"
        >
          <Popover.Trigger>
            <span>
              <Button iconLeft="Columns" variant="outline" color="neutralLight">
                Edit table
              </Button>
            </span>
          </Popover.Trigger>

          <Popover.Content>
            <PodsColumnsControl
              lrms={available_lrm_users.data.map(lrm => ({
                id: lrm.id,
                name: lrm.attributes.full_name
              }))}
              lsms={lsms.map(lsm => ({
                id: lsm.id,
                name: lsm.attributes.formatted_name
              }))}
              specialPartners={special_partners.map(sp => ({
                id: sp.id,
                name: sp.attributes.formatted_name
              }))}
              close={() => setIsEditTableOpen(false)}
            />
          </Popover.Content>
        </Popover.Root>
      </TableHeading>

      <TableContainer>
        <TableStyles>
          <thead>
            <tr>
              <th rowSpan={1}></th>
              <th className="primary-orange" colSpan={special_partners.length}>
                Special partners
              </th>
              <th className="primary-gray" colSpan={lsms.length}>
                Lender Sales Managers (LSM)
              </th>
            </tr>
            <tr>
              <th className="secondary-blue">Lender Relationship Manager (LRM)</th>
              {special_partners.map(sp => (
                <th key={sp.id} className="secondary-orange">
                  {sp.attributes.formatted_name}
                </th>
              ))}
              {lsms.map(lsm => (
                <th key={lsm.id} className="secondary-gray">
                  {lsm.attributes.formatted_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {available_lrm_users.data.map(lrm => (
              <tr key={lrm.id}>
                <td>{lrm.attributes.full_name}</td>

                {special_partners.map(sp => (
                  <td key={sp.id}>
                    {pendingData?.lrmId === lrm.id && pendingData?.podId === sp.id ? (
                      <Spinner color="#46b6ff" width={16} />
                    ) : (
                      <CheckBoxField
                        checked={getIsChecked(lrm, sp)}
                        onChange={onCheckboxChange(sp.id, lrm.id)}
                      />
                    )}
                  </td>
                ))}

                {lsms.map(lsm => (
                  <td key={lsm.id}>
                    {pendingData?.lrmId === lrm.id && pendingData?.podId === lsm.id ? (
                      <Spinner color="#46b6ff" width={16} />
                    ) : (
                      <CheckBoxField
                        checked={getIsChecked(lrm, lsm)}
                        onChange={onCheckboxChange(lsm.id, lrm.id)}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </TableStyles>
      </TableContainer>
      <TableFooter />
    </Container>
  )
}
