/* eslint-disable import/order */
/* eslint-disable sort-imports */

import {
  Callout,
  ExternalLink,
  FormField,
  IInlineEditSelectProps,
  InlineEditBoolean,
  InlineEditDateInput,
  InlineEditIntegerInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import {
  getOrderFieldName,
  bbysLeadChannelOptions,
  incomingResidenceFinanceTypeOptions,
  trueFalseOptions
} from 'lookups'
import { dataOrDash, formatDate, stringifyValue, formatDollars } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps, getBBYSLoanInputProps } from './utils'
import styles from './BBYSLeadDetailsSection.module.scss'
import { getBBYSStage } from 'modules/bbys/helpers'
import { ReactNode, useEffect, useState } from 'react'
import { Partner } from 'models/Partner'
import { CCBBYSLead } from 'models'

type TUpdateLeadStageUpdateParams = {
  bbysLeadStageUpdateId: string
  occurredAt?: string
}

type Props = {
  lead: CCBBYSLead
  partners: Partner[]
  updateBBYSLead: CCBBYSLeadDetailsProps['updateBBYSLead']
  updateBBYSLoan: CCBBYSLeadDetailsProps['updateBBYSLoan']
  updateLead: CCBBYSLeadDetailsProps['updateLead']
}

export const BBYSLeadDetailsSection = ({
  lead,
  partners,
  updateLead,
  updateBBYSLead,
  updateBBYSLoan
}: Props) => {
  const [isPartnersPropsLoading, setIsPartnersPropsLoading] = useState(true)
  const [partnerSelectProps, setPartnerSelectProps] = useState<IInlineEditSelectProps>(
    {} as IInlineEditSelectProps
  )

  const bbysLead = lead?.bbysProviderLead?.bbysLead
  const { getProps: getBbysLeadProps, genericUpdateBbysLead } = getBBYSLeadInputProps(
    bbysLead,
    updateBBYSLead
  )
  const { getOriginalLoanProps } = getBBYSLoanInputProps(bbysLead, updateBBYSLoan)
  const stage = getBBYSStage(lead.stage)
  const furthestStage = getBBYSStage(lead.furthestStage)
  const underContract = stringifyValue(lead.bbysLead.incomingResidenceUnderContractAtIntake)
  const warningChecks = {
    lowEquityCheck: 'Low Equity Check',
    ruralPropertyCheck: 'Rural Property Check',
    listedDOMCheck: 'Listed DOM Check',
    unrealisticValueCheck: 'Unrealistic Value Check'
  }
  const referringOwnerType = {
    LoanOfficer: 'Lender',
    Agent: 'Agent',
    ExternalLeadSubmitter: 'New Home Consultant'
  }

  useEffect(() => {
    if (lead && partners) {
      setPartnerSelectProps({
        options: partners?.map(({ slug, id }) => ({
          name: slug,
          value: id
        })),
        value: lead.bbysLead?.sourcePartner?.id
      })
      setIsPartnersPropsLoading(false)
    }
  }, [partners, lead])

  const updateLeadStageUpdate = (payload: TUpdateLeadStageUpdateParams) => {
    genericUpdateBbysLead(payload)
  }

  const agreementSignedLSU = lead.bbysLead?.bbysLeadStageUpdates?.find(
    lsu => lsu.newStage === 'agreement_signed'
  )

  const limitedEquityCheck = lead.bbysLead.automatedChecks
    ? Object.values(lead.bbysLead.automatedChecks).every(check => check === false)
      ? 'False'
      : 'True'
    : null

  const limitedEquityReasons =
    lead.bbysLead.automatedChecks && limitedEquityCheck === 'True'
      ? Object.keys(lead.bbysLead.automatedChecks)
          .filter(key => lead.bbysLead.automatedChecks[key] === true)
          .map(key => warningChecks[key])
          .join(', ')
      : null

  const multilineFormatter = val => {
    const lines = val.split('\n')
    const result = []

    for (const currentLine of lines) {
      let formattedLine: ReactNode

      if (currentLine.trim().startsWith('*')) {
        formattedLine = <div className={styles.multilineLine}>{currentLine.replace('*', '•')}</div>
      } else {
        formattedLine = <div>{currentLine}</div>
      }

      result.push(formattedLine)
    }

    return <div>{result}</div>
  }

  return (
    <>
      <Sections>
        <Section title="Details" className={styles.mt20}>
          <Section>
            <FormField title={getOrderFieldName('createdAt')} inline>
              {formatDate(lead.createdAt)}
            </FormField>
            <FormField title="HomeLight valuation" inline>
              <div className={styles.row}>
                {formatDollars(lead.bbysLead.departingPropertyValuation)}
                <ExternalLink href={`${window.location.href}/economic-model`}>
                  Econ Model
                </ExternalLink>
              </div>
            </FormField>
            <FormField title="Total equity unlock amount" inline>
              <div className={styles.row}>
                {formatDollars(lead.bbysLead.dpTargetNewHomePurchaseEquity)}
                <ExternalLink href={`${window.location.href}/economic-model`}>
                  Econ Model
                </ExternalLink>
              </div>
            </FormField>
            <FormField title="Team Members" inline noWrap>
              {lead.leadUsers?.map(leadUser => leadUser.prettyName).join(', ')}
            </FormField>
            <FormField title="Current stage" inline>
              {dataOrDash(stage)}
            </FormField>
            <FormField title="Furthest stage" inline>
              {dataOrDash(furthestStage)}
            </FormField>
            <FormField title="Referring Owner" inline>
              {referringOwnerType[lead.referringOwnerType]}
            </FormField>
            <FormField title="Partner name" inline>
              <InlineEditSelect
                {...getBbysLeadProps('sourcePartnerId')}
                {...partnerSelectProps}
                isLoading={isPartnersPropsLoading}
                addBlank
              />
            </FormField>
            <FormField title="Channel" inline>
              <InlineEditSelect
                onChange={(value: string) =>
                  updateBBYSLead.mutate({
                    id: bbysLead?.id,
                    payload: {
                      channel: value
                    }
                  })
                }
                options={bbysLeadChannelOptions}
                value={dataOrDash(bbysLead?.channel)}
                addBlank
              />
            </FormField>
            <FormField title="Approval type on intake" inline>
              {dataOrDash(lead.bbysLead.approvalTypeIntake)}
            </FormField>
            <FormField title="Incoming residence under contract at intake" inline>
              {underContract === 'true' ? 'Yes' : underContract === 'false' ? 'No' : '---'}
            </FormField>
            <FormField title="Contingency removal only" inline>
              <InlineEditBoolean
                options={trueFalseOptions}
                {...getBbysLeadProps('contingencyRemovalOnly')}
              />
            </FormField>
            <FormField title="Incoming residence finance type" inline>
              <InlineEditSelect
                options={incomingResidenceFinanceTypeOptions}
                {...getBbysLeadProps('incomingResidenceFinanceType')}
              />
            </FormField>
            <FormField title="Limited equity check" inline>
              {dataOrDash(limitedEquityCheck)}
            </FormField>
            <FormField title="Program length" inline>
              <InlineEditIntegerInput {...getBbysLeadProps('programLength')} maxLength={3} />
            </FormField>
            <FormField title="LO managed ALL CASH" inline>
              <InlineEditBoolean
                options={trueFalseOptions}
                {...getBbysLeadProps('loManagedAllCash')}
              />
            </FormField>
            <FormField title="Limited equity reasons" inline>
              {dataOrDash(limitedEquityCheck === 'True' ? limitedEquityReasons : null)}
            </FormField>
            <FormField title="Notes from HomeLight on intake" inline noWrap>
              {dataOrDash(lead.bbysLead.notesForHomelightOnIntake)}
            </FormField>
            <FormField title="Internal notes" inline className={styles.notesFormField}>
              <InlineEditTextInput
                className={styles.notes}
                multiline
                format={multilineFormatter}
                {...getBbysLeadProps('internalNotes')}
              />
            </FormField>
          </Section>
        </Section>

        <Section>
          <Callout>
            <Section title="Dates & Milestones">
              <FormField title="Agreement sent" inline>
                <InlineEditDateInput {...getBbysLeadProps('agreementSentDate')} />
              </FormField>
              <FormField title="Agreement expires" inline>
                <InlineEditDateInput {...getBbysLeadProps('agreementExpiresDate')} />
              </FormField>
              <FormField title="Agreement signed" inline>
                {agreementSignedLSU ? (
                  <InlineEditDateInput
                    value={agreementSignedLSU?.occurredAt}
                    onBlur={val =>
                      updateLeadStageUpdate({
                        occurredAt: val,
                        bbysLeadStageUpdateId: agreementSignedLSU.id
                      })
                    }
                  />
                ) : (
                  '---'
                )}
              </FormField>
              <FormField title="Refreshed approval" inline>
                <InlineEditDateInput {...getBbysLeadProps('refreshedApprovalDate')} />
              </FormField>
              <FormField title="Incoming residence expected close of escrow" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('incomingResidencedExpectedCloseEscrowDate')}
                />
              </FormField>
              <FormField title="Inspection ordered" inline>
                <InlineEditDateInput {...getBbysLeadProps('inspectionOrderedDate')} />
              </FormField>
              <FormField title="Inspection scheduled" inline>
                <InlineEditDateInput {...getBbysLeadProps('inspectionScheduledDate')} />
              </FormField>
              <FormField title="Inspection complete" inline>
                <InlineEditDateInput {...getBbysLeadProps('inspectionCompleteDate')} />
              </FormField>
              <FormField title="Inspection reviewed" inline>
                <InlineEditDateInput {...getBbysLeadProps('inspectionReviewedDate')} />
              </FormField>
              <FormField title="Equity Unlock disclosures sent" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('disclosuresSentDate')}
                  readOnly={bbysLead?.meridianLinkSynced}
                />
              </FormField>
              <FormField title="Equity Unlock disclosures signed" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('equityUnlockDisclosuresSignedDate')}
                  readOnly={bbysLead?.meridianLinkSynced}
                />
              </FormField>
              <FormField title="Home Owners Insurance Cleared Date" inline>
                <InlineEditDateInput {...getBbysLeadProps('homeOwnersInsuranceClearedDate')} />
              </FormField>
              <FormField title="Flood Insurance Cleared Date" inline>
                <InlineEditDateInput {...getBbysLeadProps('floodInsuranceClearedDate')} />
              </FormField>
              <FormField title="Final Agreement Sent Date" inline>
                <InlineEditDateInput {...getBbysLeadProps('finalAgreementSentDate')} />
              </FormField>
              <FormField title="Final Agreement Signed Date" inline>
                <InlineEditDateInput {...getBbysLeadProps('finalAgreementSignedDate')} />
              </FormField>
              <FormField title="Departing residence backup contract sent" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('departingResidenceBackupContractSentDate')}
                />
              </FormField>
              <FormField title="Departing residence backup contract signed" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('departingResidenceBackupContractSignedDate')}
                />
              </FormField>
              <FormField title="Payoff requested" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('payoffRequestedDate')}
                  readOnly={bbysLead?.meridianLinkSynced}
                />
              </FormField>
              <FormField title="Expected Equity Unlock loan funded" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('expectedLoanFundingDate')}
                  readOnly={bbysLead?.meridianLinkSynced}
                />
              </FormField>
              <FormField title="Equity Unlock loan funded" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('loanFundingDate')}
                  readOnly={bbysLead?.meridianLinkSynced}
                />
              </FormField>
              <FormField title="Departing residence listed" inline>
                <InlineEditDateInput {...getBbysLeadProps('departingResidenceListedDate')} />
              </FormField>
              <FormField title="Departing residence in escrow" inline>
                <InlineEditDateInput {...getBbysLeadProps('departingResidenceInEscrowDate')} />
              </FormField>
              <FormField title="Departing residence expected close of escrow" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('departingResidenceExpectedCloseEscrowDate')}
                />
              </FormField>
              <FormField title="HomeLight paid" inline>
                <InlineEditDateInput
                  {...getBbysLeadProps('homelightPaidDate')}
                  readOnly={bbysLead?.meridianLinkSynced}
                />
              </FormField>
              <FormField title="HL Purchased Departing Residence" inline>
                <InlineEditDateInput {...getBbysLeadProps('hlPurchasedDepartingResidenceDate')} />
              </FormField>
              <FormField title="HL Purchase Listed" inline>
                <InlineEditDateInput {...getBbysLeadProps('hlPurchaseListedDate')} />
              </FormField>
              <FormField title="HL Sale In Escrow" inline>
                <InlineEditDateInput {...getBbysLeadProps('hlSaleInEscrowDate')} />
              </FormField>
              <FormField title="HL Sold Departing Residence" inline>
                <InlineEditDateInput {...getBbysLeadProps('hlSoldDepartingResidenceDate')} />
              </FormField>
              <FormField title="Maturity Date" inline>
                <InlineEditDateInput {...getOriginalLoanProps('loanMaturityDate')} readOnly />
              </FormField>
            </Section>
          </Callout>
        </Section>
      </Sections>
    </>
  )
}
