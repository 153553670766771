// @ts-strict
import { useState } from 'react'
import styled from 'styled-components'
import { Table, useTable } from '@foundation/components'
import { EquityUnlockCalculatorFileUploadButtonForm } from 'content/EquityUnlockCalculator'
import { useBulkEquityUnlockCalculations } from 'lookups/lookupBulkEquityUnlockCalculations'
import { usePartners } from 'lookups/lookupPartners'
import { EquityUnlockCalculatorDataToolColumns } from './columns'

export const Container = styled.div`
  width: 100%;
  margin-top: 40px;
`

const PAGE_SIZE = 20

export const EquityUnlockCalculatorDataToolPage = () => {
  const table = useTable(EquityUnlockCalculatorDataToolColumns)
  const { columns } = table
  const [pageNumber, setPageNumber] = useState(1)
  const {
    data: bulkEquityUnlockCalculationsData = [],
    isLoading,
    totalPages
  } = useBulkEquityUnlockCalculations({ pageNumber, pageSize: PAGE_SIZE })
  const { partners } = usePartners()
  const indexedPartners = partners.reduce((acc, partner) => {
    acc[partner.id] = partner
    return acc
  }, {})

  const bulkEquityUnlockCalculationsDataExtended = bulkEquityUnlockCalculationsData.map(
    calculation => {
      const partner = indexedPartners[calculation.partnerId]
      return { ...calculation, partnerSlug: partner?.slug }
    }
  )

  return (
    <Container>
      <Table.Root {...table}>
        <Table.View>
          <Table.Heading title="Equity Unlock Calculator">
            <EquityUnlockCalculatorFileUploadButtonForm />
          </Table.Heading>
          <Table.Data
            rows={bulkEquityUnlockCalculationsDataExtended}
            columns={columns}
            isLoading={isLoading}
          />
          <Table.Pagination
            totalPages={totalPages}
            currentPage={pageNumber}
            onPageChange={setPageNumber}
          />
        </Table.View>
      </Table.Root>
    </Container>
  )
}
