import { singularize, toSnakeCase } from 'utils'
import { ExternalLinksSchema } from './ExternalLinksSchema'
import {
  ActivityFeedSchema,
  AgentAeAreasSchema,
  AgentAgreementSchema,
  AgentLeadSchema,
  AgentPartnershipSchema,
  AgentPayPerLeadReferralSchema,
  AgentSchema,
  AgentsSchema,
  BulkEquityUnlockCalculationSchema,
  CCBBYSLeadSchema,
  CCCashOfferLeadSchema,
  CCHLSimpleSaleLeadSchema,
  CCTradeInLeadSchema,
  ClientSchema,
  CurrentUserSchema,
  EmployeesSchema,
  EnvelopeSchema,
  EnvelopesSchema,
  FileUploadsSchema,
  InspectionDeficienciesSchema,
  InspectionsSchema,
  InvestorLeadsSchema,
  InvestorSchema,
  LeadSchema,
  LeadsSchema,
  LeadSummarySchema,
  LeadWarmTransferSchema,
  LenderSchema,
  LendersSchema,
  LoanOfficersAutoCompleteSchema,
  LoanOfficerSchema,
  OrderSchema,
  OrdersSchema,
  PartnerLenderSchema,
  PartnerLoanOfficerSchema,
  PhotoUploadsSchema,
  ProviderLeadSchema,
  ServiceOfficeSchema,
  SettlementAgencyTeamSchema,
  TasksSchema,
  TextMessageSchema,
  TransactionsSchema,
  TransactionTeamSchema,
  TransactionTeamsSchema,
  UserSchema
} from './'

type ValidType =
  | AgentAgreementSchema
  | AgentAeAreasSchema
  | AgentLeadSchema
  | AgentSchema
  | AgentsSchema
  | AgentPartnershipSchema
  | AgentPayPerLeadReferralSchema
  | BulkEquityUnlockCalculationSchema
  | CCBBYSLeadSchema
  | CCCashOfferLeadSchema
  | CCTradeInLeadSchema
  | ClientSchema
  | CurrentUserSchema
  | EmployeesSchema
  | EnvelopeSchema
  | EnvelopesSchema
  | ExternalLinksSchema
  | FileUploadsSchema
  | InvestorSchema
  | LeadSchema
  | LeadsSchema
  | LenderSchema
  | LendersSchema
  | OrderSchema
  | OrdersSchema
  | PhotoUploadsSchema
  | ProviderLeadSchema
  | ServiceOfficeSchema
  | CCHLSimpleSaleLeadSchema
  | TasksSchema
  | TransactionTeamSchema
  | TransactionTeamsSchema
  | SettlementAgencyTeamSchema
  | TransactionsSchema
  | UserSchema
  | TextMessageSchema
  | InspectionsSchema
  | InvestorLeadsSchema
  | LeadSummarySchema
  | LeadWarmTransferSchema
  | InspectionDeficienciesSchema
  | ActivityFeedSchema
  | PartnerLenderSchema
  | PartnerLoanOfficerSchema
  | LoanOfficerSchema
  | LoanOfficersAutoCompleteSchema

const getKey = (payload: {}): string => Object.keys(payload)[0]
const getAssociation = (str: string): string => str.split(':')[0]
const getType = (str: string): string => str.split(':')[1] ?? str

const isString = (instance: string | {}): instance is string => {
  return typeof instance === 'string'
}
const isObj = (instance: string | {}): instance is string => {
  return typeof instance === 'object'
}

const getAttributes = (items: Array<string | {}>): string[] => {
  return items.filter(isString)
}
const getAssociations = (items: Array<string | {}>) => {
  return items.filter(isObj)
}

const mergeObjects = (obj1: Record<string, string[]>, obj2: Record<string, string[]>) => {
  const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])].sort()

  return allKeys.reduce((acc, key) => {
    acc[key] = [...new Set([...(obj1[key] || []), ...(obj2[key] || [])])].sort()
    return acc
  }, {})
}

const getFields = (payload: {}): Record<string, Array<string>> => {
  let fields = {}
  const key = getKey(payload)

  // get the Server Model name for fields
  const className = toSnakeCase(singularize(getType(key)))

  // create an empty field
  fields[className] = []

  // add attributes and association keys
  fields[className].push(...getAttributes(payload[key]).map(attr => toSnakeCase(attr)))
  fields[className].push(
    ...getAssociations(payload[key]).map(assn => toSnakeCase(getAssociation(getKey(assn))))
  )

  // remove duplicates and sort
  fields[className] = [...new Set(fields[className])].sort()

  // do the same for all child associations
  const associations = getAssociations(payload[key])
  associations.forEach(assn => {
    fields = mergeObjects(getFields(assn), fields)
  })
  return fields
}

const getIncludes = (payload: {}, prefix = '') => {
  const includes: string[] = []
  const key = getKey(payload)

  // based on the ancestry and key, get the include string
  const includeString = `${prefix}${prefix.length ? '.' : ''}${toSnakeCase(getAssociation(key))}`
  includes.push(includeString)

  const associations = getAssociations(payload[key])
  associations.forEach(assn => {
    // add the include string for all child associations
    const childIncludes = getIncludes(assn, includeString)
    childIncludes.forEach(incl => includes.push(incl))
  })

  // remove duplicates and sort
  return [...new Set(includes)].sort()
}

const getIncludesFromAssociations = (payload: {}): Array<string> => {
  const includes: string[] = []
  const root = getKey(payload)
  const associations = getAssociations(payload[root])

  associations.forEach(assn => {
    const childIncludes = getIncludes(assn)
    childIncludes.forEach(incl => includes.push(incl))
  })

  // remove duplicates and sort
  return [...new Set(includes)].sort()
}

// More to do - get the url based on the root key (modelName).  Return a string
type Options = {
  meta: string[]
}

const getMeta = (options: Options): string => {
  return options.meta.map(attr => toSnakeCase(attr)).join(',')
}

type HapiRequestParams = { [field: `fields[${string}]`]: string; include: string; meta?: string }

export const getHapiRequestParams = (payload: ValidType, options?: Options): HapiRequestParams => {
  const params = {} as HapiRequestParams
  const fields = getFields(payload)
  Object.entries(fields).forEach(([key, val]) => (params[`fields[${key}]`] = val.join(',')))
  const includes = getIncludesFromAssociations(payload)
  params['include'] = includes.join(',')
  if (options?.meta) {
    params['meta'] = getMeta(options)
  }

  return params
}
