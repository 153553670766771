import { AgentLead } from 'models'
import { AgentLeadSchema, getHapiRequestParams } from 'schema'
import { getAgentLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, SearchParamsSettings } from './getSearchParams'

const agentLeadShape: AgentLeadSchema = {
  'providable:agentLead': [
    'createdAt',
    'introduced',
    'recordingUrl',
    {
      providerLead: [
        'furthestStage',
        'introSentAt',
        'stage',
        {
          lead: [
            'name',
            'fullAddress',
            'price',
            'stage',
            'furthestStage',
            'lastStageUpdate',
            'userType'
          ]
        },
        {
          servicesOpportunities: [
            'productType',
            { 'status:servicesOpportunityStatus': ['name', 'slug'] }
          ]
        }
      ]
    },
    { 'workedByAgent:agent': ['firstName', 'lastName'] },
    { introTracking: ['pickupHangupDetected'] }
  ]
}
export const fetchAgentLeads = async (agentId: string, settings: SearchParamsSettings) => {
  const url = getAgentLeadsUrl({
    id: agentId,
    action: 'agent-leads',
    searchParams: {
      ...getHapiRequestParams(agentLeadShape),
      ...getSearchParams(settings)
    }
  })

  const response = await getRequest(url)
  const agentLeads = await deserializeAsync(response.data, { transform: AgentLead.create })
  return {
    agentLeads,
    totalCount: response.data.meta.total_agent_leads as number,
    totalUrgentCount: response.data.meta.total_urgent_agent_leads as number
  }
}
