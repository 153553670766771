import { useState } from 'react'
import { Button } from '@foundation/components'
import { styled } from '@foundation/themes/styles'
import { Field, If, Section, Sections } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useAgentLeadController } from 'hooks'
import { AgentLead, AgentProviderLead } from 'models'
import { dataOrDash, formatBoolean, formatDate, formatDateTime } from 'utils'
import { VoidPickupHangUpModal } from '../../AgentLeads/AgentLeadsTable/VoidPickupHangUpModal'

type Props = {
  agentProviderLead: AgentProviderLead
}

const ALGO_VERSION_DESCRIPTION = {
  '0.1': 'Fallback Query',
  '5.1': 'Standard',
  '5.2': 'TAM'
}

export const AgentProviderLeadDetails = ({ agentProviderLead }: Props) => {
  const { agentLeadDetailsQuery } = useAgentLeadController(agentProviderLead.agentLead.id)

  if (agentLeadDetailsQuery.isError) {
    return <div>Something went wrong</div>
  }

  if (agentLeadDetailsQuery.isIdle) {
    agentLeadDetailsQuery.refetch()
  }

  if (agentLeadDetailsQuery.isLoading || agentLeadDetailsQuery.isIdle) {
    return <div>Loading referral details...</div>
  }

  return (
    <>
      <IntroDetailsSection agentLeadData={agentLeadDetailsQuery.data as AgentLead} />
    </>
  )
}

const IntroDetailsSection = ({ agentLeadData }: { agentLeadData: AgentLead }) => {
  const { isAgentHangupActionAdmin } = useCurrentUserContext()
  const [openVoidAgentHangUpModal, setOpenVoidAgentHangUpModal] = useState(false)
  const [showActionButton, setShowActionButton] = useState(true)
  const pickupHangupDetected = agentLeadData.introTracking?.pickupHangupDetected || false

  return (
    <Section title="Intro Information">
      <VoidPickupHangUpModal
        agentId={agentLeadData.agent?.id}
        agentLeadId={agentLeadData.id}
        setIsOpen={setOpenVoidAgentHangUpModal}
        isOpen={openVoidAgentHangUpModal}
        sideEffect={() => setShowActionButton(false)}
        recordingUrl={agentLeadData.recordingUrl}
      />
      <Sections>
        <Section>
          <Field title="Algo version">
            {dataOrDash(agentLeadData.algoVersion)} -{' '}
            {dataOrDash(ALGO_VERSION_DESCRIPTION[agentLeadData.algoVersion])}
          </Field>
        </Section>
        <Section>
          <Field title="Intro Sent At">
            {dataOrDash(formatDateTime(agentLeadData.introSentAt))}
          </Field>
        </Section>
        <Section>
          <Field title="Auto-intro Sent At">
            {dataOrDash(formatDateTime(agentLeadData.autoIntroSentAt))}
          </Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Intro Type">{dataOrDash(agentLeadData.introTracking?.introType)}</Field>
        </Section>
        <Section>
          <Field title="Intro Detail">{dataOrDash(agentLeadData.introTracking?.introDetail)}</Field>
        </Section>
        <Section>
          <Field title="Qualification Type">
            {dataOrDash(agentLeadData.introTracking?.qualificationType)}
          </Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Qualification Method">
            {dataOrDash(agentLeadData.introTracking?.qualificationMethod)}
          </Field>
        </Section>
        <Section>
          <Field title="Claim Method">{dataOrDash(agentLeadData.introTracking?.claimMethod)}</Field>
        </Section>
        <Section>
          <Field title="Referral Type">
            {agentLeadData.pplReferral && <span>Pay Per Lead</span>}
            {!agentLeadData.pplReferral && <span>Rev Share</span>}
          </Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Claim Restricted">
            {formatBoolean(agentLeadData.introTracking?.claimRestricted || false)}
          </Field>
        </Section>
        <Section>
          <Field title="Claim Restricted - Agreement ID">
            {dataOrDash(agentLeadData.introTracking?.providerAgreementId)}
          </Field>
        </Section>
        <Section>
          <Field title="Claim Restricted - Agreement Type">
            {dataOrDash(agentLeadData.introTracking?.providerAgreementType)}
          </Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Claimed At">{formatDate(agentLeadData.introTracking?.claimedAt)}</Field>
        </Section>
        <Section>
          <Field title="Claimed By Agent ID">
            {dataOrDash(agentLeadData.introTracking?.claimedByAgentId)}
          </Field>
        </Section>
        <Section />
      </Sections>
      <If test={pickupHangupDetected && showActionButton && isAgentHangupActionAdmin}>
        <Sections>
          <Section>
            <Field title="Agent Hangup">
              <VoidButton onClick={() => setOpenVoidAgentHangUpModal(!openVoidAgentHangUpModal)}>
                Void
              </VoidButton>
            </Field>
          </Section>
        </Sections>
      </If>
    </Section>
  )
}

const VoidButton = styled(Button)`
  min-width: 128px;
`
