import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { useToastContext } from '@foundation/components'
import { GET_PODS_QUERY_KEY } from 'modules/lrm/constants/query-keys'
import { postRequest } from 'utils'
import { BASE_URL } from '../../constants/endpoints'

type AddLRMPayload = {
  lrmId: string
  podId: string
}

export const useAddLRM = (options: UseMutationOptions<void, Error, AddLRMPayload> = {}) => {
  const toast = useToastContext()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ podId, lrmId }: AddLRMPayload) => {
      await postRequest(`${BASE_URL}/${podId}/add-lrm`, { lrm_user_id: lrmId })
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: [GET_PODS_QUERY_KEY] })
        options.onSuccess?.(...args)
      },
      onError: (error, ...args) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error'
        })
        options?.onError?.(error, ...args)
      },
      ...options
    }
  )
}
