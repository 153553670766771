// @ts-strict
import React, { useState } from 'react'
import { useToastContext } from '@foundation/components'
import { Box, Dialog, Flex } from '@foundation/components'
import { ActionBar, Button, Divider, Form, FormField, TextArea } from 'components'
import { useAgentLeadsVoidHangUpDetectedController } from 'controllers'
import styles from './VoidPickupHangUp.module.scss'

type VoidPickupHangUpModalProps = {
  agentId: string
  agentLeadId: string
  isOpen: boolean
  recordingUrl?: string
  setIsOpen: (isOpen: boolean) => void
  sideEffect?: () => void
}

export const VoidPickupHangUpModal = ({
  agentId,
  agentLeadId,
  isOpen,
  setIsOpen,
  sideEffect,
  recordingUrl
}: VoidPickupHangUpModalProps) => {
  const toast = useToastContext()
  const [note, setNote] = useState('')
  const { voidHangUpDetected, isLoading } = useAgentLeadsVoidHangUpDetectedController()

  const onSuccessSubmit = () => {
    toast({
      status: 'success',
      title: 'Agent Hangup updated.'
    })

    setIsOpen(false)
    sideEffect?.()
  }

  const onErrorSubmit = () => {
    toast({
      status: 'error',
      title: 'Unable to void agent hang up. Please contact support.'
    })

    setIsOpen(false)
  }

  const onSubmit = async () => {
    await voidHangUpDetected(
      {
        agentLeadId,
        agentId,
        note
      },
      {
        onSuccess: onSuccessSubmit,
        onError: () => onErrorSubmit()
      }
    )
  }

  const onClickViewRecording = () => {
    window.open(recordingUrl, '_blank')
  }

  return (
    <Dialog.Root isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Box width="70rem" paddingTop="$2" paddingLeft="$2" paddingRight="$2">
          <Dialog.Header color="primary10" inline>
            Void Agent Hang Up Incident
          </Dialog.Header>

          <Divider className={styles.divider} />

          <Dialog.Body>
            {recordingUrl && (
              <Box marginBottom="$6">
                <Button as="link" onClick={onClickViewRecording}>
                  Listen to call recording
                </Button>
              </Box>
            )}

            <Form onSubmit={onSubmit}>
              <FormField title="Notes" required>
                <TextArea onChange={setNote} />
              </FormField>

              <ActionBar>
                <Box marginTop="$6">
                  <Flex gap="$4" justifyContent="flex-end">
                    <Button as="cancel" onClick={() => setIsOpen(false)}>
                      Cancel
                    </Button>
                    <Button as="submit" disabled={isLoading} submitting={isLoading}>
                      Submit
                    </Button>
                  </Flex>
                </Box>
              </ActionBar>
            </Form>
          </Dialog.Body>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  )
}
