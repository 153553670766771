import { FormEventHandler } from 'react'
import { useToastContext } from '@foundation/components'
import { useBrowserEvent } from '@foundation/hooks'
import { Button } from 'components'
import { useFeatureFlags, useMutation } from 'hooks'
import { CCBBYSLead } from 'models'
import { getBBYSRequestDRContractUrl } from 'services/getUrl'
import { putRequest } from 'utils'
import styles from './RequestIRContract.module.scss'

type RequestDRContractProps = {
  lead: CCBBYSLead
}

export const RequestDRContract = ({ lead }: RequestDRContractProps) => {
  const { salesAppRequestIrContractBtn } = useFeatureFlags(['sales-app-request-ir-contract-btn'])
  const { publish } = useBrowserEvent('SHOW_MISSING_DR_AGENT_ERROR')
  const toast = useToastContext()

  const { mutate } = useMutation(
    () => {
      return putRequest(getBBYSRequestDRContractUrl(), {
        id: lead.id
      })
    },
    {
      onSuccess: () => {
        toast({
          title: 'Email / Task sent to Loan Officer!',
          status: 'success'
        })
      },
      onError: () => {
        toast({
          title: 'There was an error requesting the Departing Residence Contract.',
          status: 'error'
        })
      }
    }
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    if (!lead.bbysProviderLead.bbysLead.departingPropertySellerAgent) {
      publish()
      toast({
        title: 'Can’t request contract. Add a Departing Residence agent and try again.',
        status: 'error'
      })
      return
    }
    mutate()
  }

  return (
    <form onSubmit={onSubmit}>
      <Button
        type="submit"
        as="primary"
        className={styles.requestIRContractBtn}
        disabled={!salesAppRequestIrContractBtn.enabled}
      >
        Request DR Contract
      </Button>
    </form>
  )
}
