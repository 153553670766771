import { useEffect, useRef, useState } from 'react'
import { Call, Device } from '@twilio/voice-sdk'
import { Dialer } from 'classes'
import { useQuery } from 'hooks'
import { fetchToken } from 'services'
import { formatTalkTime } from 'utils'

// Configuration constants
const DEVICE_CONFIG = {}
const TOKEN_REFRESH_INTERVAL = 60_000 // 1 minute in milliseconds

interface UseDialerReturn {
  activeDialer: Dialer | null
  call: (dialer?: Dialer) => void
  callDuration: string
  endCall: () => void
  isExpanded: boolean
  isLoading: boolean
  isOnCall: boolean
  isVisible: boolean
  sendKeyToCall: (key: string) => void
  toggleExpanded: () => void
  toggleVisible: () => void
}

/**
 * Hook to manage Twilio voice calls
 * @returns {UseDialerReturn} Object containing call management methods and state
 */
export const useDialer = (): UseDialerReturn => {
  // State management
  const [isVisible, setIsVisible] = useState(false)
  const [isOnCall, setIsOnCall] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const [callDuration, setCallDuration] = useState('00:00')
  const [activeDialer, setActiveDialer] = useState<Dialer | null>(null)

  // Refs for managing call state
  const device = useRef<Device | null>(null)
  const activeCall = useRef<Call | null>(null)
  const isConnecting = useRef(false)
  const callStartTime = useRef<number | null>(null)

  // Token management
  const { data: token, isLoading } = useQuery(['dialer_token'], fetchToken, {
    enabled: isOnCall,
    refetchOnWindowFocus: !isOnCall,
    refetchInterval: isOnCall ? false : TOKEN_REFRESH_INTERVAL,
    refetchOnReconnect: !isOnCall
  })

  /**
   * Cleanup function to reset call state
   * @param isComplete - Whether to perform a complete cleanup including device destruction
   */
  const cleanup = (isComplete = true) => {
    if (isComplete) {
      if (device.current) {
        device.current.destroy()
        device.current = null
      }
      activeCall.current = null
      isConnecting.current = false
      callStartTime.current = null
    }
  }

  useEffect(() => {
    let mounted = true

    // Only proceed if we're starting a new call
    if (isOnCall && token && !isConnecting.current && !activeCall.current) {
      const setupCall = async () => {
        try {
          isConnecting.current = true

          // Cleanup previous device if it exists
          if (device.current) {
            device.current.destroy()
            device.current = null
          }

          // Initialize new device
          device.current = new Device(token, DEVICE_CONFIG)
          await device.current.register()

          if (!mounted) {
            cleanup(true)
            return
          }

          // Establish call connection
          const call = await device.current.connect({
            params: activeDialer?.connectionData.params
          })

          if (!mounted || !device.current) {
            cleanup(true)
            return
          }

          activeCall.current = call
          callStartTime.current = Date.now()

          // Set up call event listeners
          call.on('sample', (rtc: { timestamp: number }) => {
            if (callStartTime.current) {
              setCallDuration(formatTalkTime(rtc.timestamp - callStartTime.current))
            }
          })

          call.on('disconnect', () => {
            cleanup(true)
            setIsOnCall(false)
          })

          call.on('error', (error: Error) => {
            console.error('Call error:', error)
            cleanup(true)
            setIsOnCall(false)
          })
        } catch (error) {
          console.error('Failed to setup call:', error)
          cleanup(true)
          setIsOnCall(false)
        }
      }

      setupCall()
    }

    return () => {
      mounted = false
      cleanup(false) // Don't destroy device on re-render, only unmount
    }
  }, [token, isOnCall, activeDialer?.connectionData])

  const sendKeyToCall = (key: string): void => {
    if (activeCall.current) {
      activeCall.current.sendDigits(key)
    }
  }

  const toggleExpanded = (): void => setIsExpanded(prev => !prev)
  const toggleVisible = (): void => setIsVisible(prev => !prev)

  const call = (dialer?: Dialer): void => {
    if (!isOnCall && !isConnecting.current && !activeCall.current) {
      if (dialer) {
        setActiveDialer(dialer)
      }
      setCallDuration('00:00')
      setIsVisible(true)
      setIsOnCall(true)
    }
  }

  const endCall = (): void => {
    if (activeCall.current) {
      activeCall.current.disconnect()
    }
  }

  return {
    activeDialer,
    call,
    callDuration,
    endCall,
    isExpanded,
    isLoading,
    isOnCall,
    isVisible,
    sendKeyToCall,
    toggleExpanded,
    toggleVisible
  }
}
