import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import { Switch } from '@foundation/components'
import { ConsoleAside } from 'components'
import { Agent, AgentLead } from 'models'
import { cx } from 'utils'
import { Icon } from '../Icon'
import cardStyles from './Card.module.scss'
import hiddenStyles from './HiddenAgent.module.scss'

type Props = {
  agent: Agent
  agentLead?: AgentLead
  children?: ReactNode
  setShowHidden: Dispatch<SetStateAction<boolean>>
  showHidden: boolean
}

export const HiddenAgentWrapper = ({
  agent,
  agentLead,
  children,
  showHidden,
  setShowHidden
}: Props) => {
  const agentName = agent.investor ? agent.investor.name : agent.fullName

  return (
    <div
      className={cx(cardStyles.card, hiddenStyles.hiddenWrapper, showHidden && hiddenStyles.shown)}
    >
      <div className={hiddenStyles.hiddenAgentHeader}>
        <Icon.EyeSlash />
        <div className={hiddenStyles.hiddenAgentHeaderOption}>
          <div>Profile is hidden</div>
          <div>
            <Switch type="button" isOn={showHidden} label="Show details" onClick={setShowHidden} />
          </div>
        </div>
      </div>
      {showHidden ? (
        children
      ) : (
        <>
          <ConsoleAside className={cx(cardStyles.summary, hiddenStyles.whiteAside)}>
            {children}
          </ConsoleAside>
          <div className={hiddenStyles.profileButtonWrapper}>
            <Link to={`/agents/${agent.id}`} title={agentName}>
              <div className={hiddenStyles.profileButton}>Go to agent profile</div>
            </Link>
          </div>
        </>
      )}
    </div>
  )
}
