import { useCallback, useEffect, useState } from 'react'
import { Button } from '@foundation/components'
import { ButtonForm, DateInput, FormField, Select, TextInput } from 'components'
import { useGeneratedDocumentsController } from 'controllers'
import { agentMatchingLeadPropertyTypes } from 'lookups'
import { CCBBYSLead } from 'models'
import { formatDate } from 'utils'

type TLeadExternalResourceDocumentButtonFormProps = {
  lead?: CCBBYSLead
}

type ExternalResourceDocumentSlugs = 'valuation-model' | 'property-condition-report'

const documentTemplates = [
  {
    name: 'Valuation Model',
    value: 'valuation-model',
    title: 'Valuation Model',
    fields: [
      'date',
      'departing_residence_address',
      'property_type',
      'valuation_refresh',
      'house_canary_value',
      'agent_lender_valuation'
    ],
    optional: []
  },
  {
    name: 'Property Condition Report',
    value: 'property-condition-report',
    title: 'Property Condition Report',
    fields: [
      'date',
      'departing_residence_address',
      'required_repairs_pre_funding',
      'inspections_required',
      'departing_residence_year_built',
      'first_position_payoffs',
      'non_first_position_balances',
      'homelight_valuation',
      'risk_adjustment_percentage',
      'approved_combined_loan_to_value_ratio',
      'risk_adjusted_value',
      'total_equity_unlock_amount',
      'max_equity_for_down_payment',
      'agent_lender_valuation',
      'inspection_complete_date',
      'bbys_fee',
      'loan_payoff_value',
      'property_type',
      'inspection_cost',
      'buy_before_you_sell_loan_amount',
      'primary_client_name_on_title',
      'additional_client_name_on_title'
    ],
    optional: ['additional_client_name_on_title', 'required_repairs_pre_funding']
  }
]

const yesNoOptions = [
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' }
]

export type TExternalResourceDocumentPayload = {
  [key: string]: string
}

export const LeadExternalResourceDocumentButtonForm = ({
  lead
}: TLeadExternalResourceDocumentButtonFormProps) => {
  const { generateDocument } = useGeneratedDocumentsController({ bbysLeadId: lead?.bbysLead.id })
  const [payload, setPayload] = useState<TExternalResourceDocumentPayload>({
    slug: documentTemplates[0].value
  })
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Wrap initialPayload in useCallback to prevent it from being recreated on every render
  const initialPayload = useCallback(
    (slug: ExternalResourceDocumentSlugs): TExternalResourceDocumentPayload => {
      const payload: TExternalResourceDocumentPayload = { slug }
      const config = documentTemplates.find(s => s.value === slug) || documentTemplates[0]

      // Map property type from lead to match the options
      const getPropertyType = () => {
        const leadPropertyType = lead?.propertyType
        if (!leadPropertyType) {
          return ''
        }

        const matchingOption = agentMatchingLeadPropertyTypes.find(
          option => option.value === leadPropertyType
        )
        return matchingOption ? matchingOption.value : ''
      }

      // Basic fields for all document types
      const basicFields = {
        date: (): string => new Date().toISOString(),

        // Valuation Model fields
        departing_residence_address: (): string => lead?.fullAddress || '',
        property_type: getPropertyType,
        valuation_refresh: (): string => 'No',
        house_canary_value: (): string =>
          lead?.bbysLead?.houseCanaryAvmValueIntake?.toString() || '',
        agent_lender_valuation: (): string => {
          return (
            lead?.bbysLead?.agentEstimatedHomeValue?.toString() ||
            lead?.bbysLead?.lenderEstimatedHomeValue?.toString() ||
            ''
          )
        },

        // Property Condition Report fields
        required_repairs_pre_funding: (): string =>
          lead?.bbysLead?.departingResidenceRequiredRepairsPreFunding || '',
        inspections_required: (): string => (lead?.bbysLead?.inspectionsRequired ? 'Yes' : 'No'),
        departing_residence_year_built: (): string =>
          lead?.bbysLead?.departingResidenceYearBuilt?.toString() || '',
        first_position_payoffs: (): string =>
          lead?.bbysLead?.dpFirstPositionPayoffs?.toString() || '',
        non_first_position_balances: (): string =>
          lead?.bbysLead?.dpNonFirstPositionBalances?.toString() || '',
        homelight_valuation: (): string => lead?.bbysLead?.hlValuation?.toString() || '',
        risk_adjustment_percentage: (): string =>
          lead?.bbysLead?.riskAdjustmentPercentage?.toString() || '',
        approved_combined_loan_to_value_ratio: (): string =>
          lead?.bbysLead?.dpLoanToValueRatio?.toString() || '',
        risk_adjusted_value: (): string => lead?.bbysLead?.riskAdjustedValue?.toString() || '',
        total_equity_unlock_amount: (): string =>
          lead?.bbysLead?.dpTargetNewHomePurchaseEquity?.toString() || '',
        max_equity_for_down_payment: (): string =>
          lead?.bbysLead?.dpMaxDownpaymentEquity?.toString() || '',
        inspection_complete_date: (): string => lead?.bbysLead?.inspectionCompleteDate || '',
        bbys_fee: (): string => {
          const feeRate = lead?.bbysLead?.dpTargetTradeInFeeRate || 0
          const homeValue =
            lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue || 0
          return homeValue && feeRate
            ? Math.max(
                9000,
                parseFloat(homeValue.toString()) * parseFloat(feeRate.toString())
              ).toString()
            : ''
        },
        loan_payoff_value: (): string => lead?.bbysLead?.loanPayoffValue?.toString() || '',
        inspection_cost: (): string => {
          const expenses = lead?.bbysProviderLead?.providerLeadExpenses || []
          const inspectionExpense = expenses.find(
            expense => expense.slug === 'target_property_pest_inspections'
          )
          return inspectionExpense?.value || ''
        },
        buy_before_you_sell_loan_amount: (): string =>
          lead?.bbysLead?.buyBeforeYouSellLoanAmount?.toString() || '',
        primary_client_name_on_title: (): string => lead?.bbysLead?.primaryClientNameOnTitle || '',
        additional_client_name_on_title: (): string =>
          lead?.bbysLead?.additionalClientNameOnTitle || ''
      }

      // Set values for all fields defined in the template
      for (const field of config.fields) {
        if (field in basicFields) {
          payload[field] = basicFields[field]()
        }
      }

      return payload
    },
    [lead] // Only recreate this function when lead changes
  )

  // Initialize payload when component mounts or when lead changes
  useEffect(() => {
    setPayload(initialPayload(documentTemplates[0].value as ExternalResourceDocumentSlugs))
  }, [initialPayload]) // Now initialPayload is stable between renders

  // When modal is opened, refresh the payload to get the latest data
  useEffect(() => {
    if (showModal) {
      setPayload(initialPayload(payload.slug as ExternalResourceDocumentSlugs))
    }
  }, [showModal, initialPayload, payload.slug])

  function onChangeSlug(slug: ExternalResourceDocumentSlugs) {
    setPayload(initialPayload(slug))
  }

  function formatDateString(date: string) {
    return formatDate(date, 'extraLong')
  }

  function handleInputChange(field: string, value: string) {
    setPayload(prev => ({
      ...prev,
      [field]: value
    }))
  }

  async function onSubmit() {
    setIsLoading(true)

    // Create a copy of the payload to modify
    const submissionPayload = { ...payload }

    // Format date fields
    for (const key in submissionPayload) {
      if (key.includes('date')) {
        submissionPayload[key] = formatDateString(submissionPayload[key])
      }
    }

    generateDocument.mutate(submissionPayload, {
      onError: () => setIsLoading(false),
      onSuccess: () => {
        setIsLoading(false)
        setShowModal(false)

        // Reload the page to ensure all data is refreshed
        window.location.reload()
      }
    })
  }

  const config = documentTemplates.find(s => s.value === payload.slug) || documentTemplates[0]
  const isFormValid = true // Since we have default values for all required fields
  const isValuationModel = payload.slug === 'valuation-model'
  const isPropertyConditionReport = payload.slug === 'property-condition-report'

  // Helper function to convert snake_case to Title Case for display
  const formatFieldTitle = (field: string) => {
    return field
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={() => setShowModal(true)}>
        Generate Document
      </Button>
      <ButtonForm
        buttonText="Generate Document"
        title="Generate Document"
        hideTriggerButton
        showForm={showModal}
        toggleShowForm={() => !isLoading && setShowModal(!showModal)}
        handleClose={() => !isLoading && setShowModal(false)}
        handleHover={() => {}}
        showError={false}
        error=""
        errorMessage=""
        errors={{}}
        isFormValid={isFormValid}
        isLoading={isLoading}
        onSubmit={onSubmit}
        resetForm={() => {}}
      >
        <FormField title="Choose a Template" htmlFor="slug" required>
          <Select
            id="slug"
            options={documentTemplates}
            value={payload.slug}
            onChange={val => onChangeSlug(val as ExternalResourceDocumentSlugs)}
            addBlank
          />
        </FormField>

        {isValuationModel && (
          <>
            <FormField title="Departing Residence Address" htmlFor="departing_residence_address">
              <TextInput
                id="departing_residence_address"
                value={payload.departing_residence_address}
                onChange={val => handleInputChange('departing_residence_address', val)}
              />
            </FormField>

            <FormField title="Property Type" htmlFor="property_type">
              <Select
                id="property_type"
                options={agentMatchingLeadPropertyTypes}
                value={payload.property_type}
                onChange={val => handleInputChange('property_type', val as string)}
                addBlank
              />
            </FormField>

            <FormField title="Valuation Refresh" htmlFor="valuation_refresh">
              <Select
                id="valuation_refresh"
                options={yesNoOptions}
                value={payload.valuation_refresh}
                onChange={val => handleInputChange('valuation_refresh', val as string)}
                addBlank
              />
            </FormField>

            <FormField title="House Canary Value" htmlFor="house_canary_value">
              <TextInput
                id="house_canary_value"
                value={payload.house_canary_value}
                onChange={val => handleInputChange('house_canary_value', val)}
                type="number"
              />
            </FormField>

            <FormField title="Agent/Lender Valuation" htmlFor="agent_lender_valuation">
              <TextInput
                id="agent_lender_valuation"
                value={payload.agent_lender_valuation}
                onChange={val => handleInputChange('agent_lender_valuation', val)}
                type="number"
              />
            </FormField>
          </>
        )}

        {isPropertyConditionReport && (
          <>
            {config.fields.map(field => {
              if (field === 'date' || field === 'slug') {
                return null // Skip these fields as they're handled automatically
              }

              if (field === 'property_type') {
                return (
                  <FormField key={field} title={formatFieldTitle(field)} htmlFor={field}>
                    <Select
                      id={field}
                      options={agentMatchingLeadPropertyTypes}
                      value={payload[field]}
                      onChange={val => handleInputChange(field, val as string)}
                      addBlank
                    />
                  </FormField>
                )
              }

              if (field === 'inspections_required') {
                return (
                  <FormField key={field} title={formatFieldTitle(field)} htmlFor={field}>
                    <Select
                      id={field}
                      options={yesNoOptions}
                      value={payload[field]}
                      onChange={val => handleInputChange(field, val as string)}
                      addBlank
                    />
                  </FormField>
                )
              }

              if (field === 'inspection_complete_date') {
                return (
                  <FormField key={field} title={formatFieldTitle(field)} htmlFor={field}>
                    <DateInput
                      id={field}
                      value={payload[field]}
                      onChange={val => handleInputChange(field, val)}
                    />
                  </FormField>
                )
              }

              // Default to TextInput for all other fields
              return (
                <FormField
                  key={field}
                  title={formatFieldTitle(field)}
                  htmlFor={field}
                  required={!config.optional.includes(field)}
                >
                  <TextInput
                    id={field}
                    value={payload[field]}
                    onChange={val => handleInputChange(field, val)}
                    type={
                      field.includes('value') ||
                      field.includes('amount') ||
                      field.includes('cost') ||
                      field.includes('fee') ||
                      field.includes('payoffs') ||
                      field.includes('balances')
                        ? 'number'
                        : 'text'
                    }
                  />
                </FormField>
              )
            })}
          </>
        )}
      </ButtonForm>
    </>
  )
}
