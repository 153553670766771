import { Order } from 'models'
import { getHapiRequestParams, OrderSchema } from 'schema'
import { getOrdersUrl } from 'services/getUrl'
import { TFetchItems } from 'services/types'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, OrderLeadFeedParams } from './getSearchParams'

export const fetchOrder = async (id: string, orderResponseShape: OrderSchema): Promise<Order> => {
  const searchParams = getHapiRequestParams(orderResponseShape)
  const url = getOrdersUrl({ id, searchParams })
  const response = await getRequest(url)

  return await deserializeAsync(response.data, { transform: Order.create })
}

export const fetchOrderLeadsFeed = async <Response>(
  orderId: string,
  orderResponseShape: OrderSchema,
  settings: OrderLeadFeedParams
): Promise<TFetchItems<Response>> => {
  const searchParams = {
    ...getSearchParams(settings),
    ...getHapiRequestParams(orderResponseShape)
  }

  const url = getOrdersUrl({ id: orderId, searchParams })
  const response = await getRequest(url)
  const order = await deserializeAsync(response.data, { transform: Order.create })

  const perPage = settings.perPage!
  const totalLeads = order.totalLeads
  const pageCount = Math.ceil(totalLeads / perPage)

  return {
    items: [order],
    pageCount: pageCount,
    totalCount: totalLeads
  }
}
